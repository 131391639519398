import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent implements OnInit {
  form: FormGroup;
  headline: string;
  content: string;
  confirm: string;
  placeholder: string;
  noteData = {text: null, pinned: false, alert: false};


  constructor(public dialogRef: MatDialogRef<NotesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    console.log(this.data);

    if (this.data) {
      this.headline = this.data.headline || 'Enter a New Note';
      this.content = this.data.content || '';
      this.placeholder = this.data.placeholder || '';
      this.confirm = this.data.confirm || 'Save';
    }

  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    // { type: 0, text: 'test note', pinned: false, alert: false }
    console.log(this.noteData);
    // if(this.form.valid) {
    if (this.noteData.text) {
      this.dialogRef.close({ data: this.noteData });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
  }

}
