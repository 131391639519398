import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { MaskService } from '../../services/mask.service';
@Component({
  selector: 'slider-charts',
  templateUrl: './slider-charts.component.html',
  styleUrls: ['./slider-charts.component.scss'],
})
export class SliderChartsComponent implements OnInit, OnChanges {
  @Input() fundData;
  @Output() mixChange = new EventEmitter();
  @Output() cashFlowChange = new EventEmitter();

  sliderValue = 50;
  compareChart;
  pieChart;
  premiumChart;
  multi;
  realData;
  editedSliderValue = 50;
  tableData = { perm: [], temp: [], prem: [] };
  dollarMask = this.mask.dollarMaskSpecs();
  ogCashFlow;
  cashFlow;
  editAmount = true;
  disableSlider = false;
  cashFlowLabel;

  constructor(private mask: MaskService) {}

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
    });
    if (this.fundData?.perm && !this.fundData?.temp) {
      this.sliderValue = 100;
      this.disableSlider = true;
    } else if (!this.fundData?.perm && this.fundData?.temp) {
      this.sliderValue = 0;
      this.disableSlider = true;
    } else {
      this.sliderValue = this.fundData?.permPercent * 100;
    }

    this.multi = this.fundData?.multiplier;
    this.buildChart(this.fundData);
    this.ogCashFlow = this.fundData?.cashFlow.raw[0];
    this.cashFlow = this.fundData?.cashFlow.raw[0];
    this.cashFlowLabel = this.fundData?.cashFlowLabel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fundData?.perm && this.fundData?.temp) {
      if (changes['fundData'] && (this.fundData?.perm || this.fundData?.temp)) {
        this.sliderValue = this.fundData?.permPercent * 100;

        if (
          this.fundData?.permPercent != 1 ||
          this.fundData?.permPercent != 0
        ) {
          this.editedSliderValue = this.fundData?.permPercent;
        }

        this.updateChart({
          value: this.fundData?.permPercent * 100,
          blockEmit: true,
        });
      }
    }
  }

  buildChart(data?, updatePie = true) {
    const that = this;
    if (updatePie) {
      const pieChartOutput = new Chart({
        chart: {
          type: 'pie',
          backgroundColor: null,
          options3d: {
            enabled: true,
            alpha: 45,
          },
        },
        colors: ['#ffcb21', '#f77c00', '#89c540', '#6734ba', '#CD0000'],
        plotOptions: {
          pie: {
            // innerSize: 100,
            depth: 45,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        title: null,
        exporting: {
          enabled: false,
        },
        legend: {
          enabled: true,
          itemMarginBottom: 10,
          itemStyle: { fontSize: '14px', fontWeight: 'bold' },
          margin: 0,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return `${this.key}: <b>${Number(
              Math.round(Number(this.point.percentage + 'e2')) + 'e-2'
            )}% | $${that.formatMoney(
              +that.fundData?.amount * (+this.point.y / 100)
            )}</b>`;
          },
        },
        series: [
          {
            type: 'pie',
            name: 'Product Mix',
            data: [
              ['Permanent', this.sliderValue],
              ['Term', 100 - this.sliderValue],
            ],
          },
        ],
      });

      this.pieChart = pieChartOutput;
    }
    let compareXLabels = [];
    let premiumXLabels = [];

    if (
      data?.perm &&
      data?.perm.data &&
      (data?.perm.data[0].age || data?.perm.data[0].Age)
    ) {
      premiumXLabels = data?.perm.data.map(x => {
        return x.age || x.Age;
      });
      compareXLabels = premiumXLabels;
    } else if (
      data?.temp &&
      data?.temp.data &&
      (data?.temp.data[0].age || data?.temp.data[0].Age)
    ) {
      premiumXLabels = data?.temp.data.map(x => {
        return x.age || x.Age;
      });
      compareXLabels = premiumXLabels;
    }

    const compareChartOutput = new Chart({
      chart: {
        type: 'area',
        backgroundColor: null,
        height: '55%',
      },
      colors: ['#6734ba', '#5f8005'],
      title: {
        text: 'Cash Value &amp; Death Benefit',
      },
      exporting: {
        enabled: false,
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: 30,
        floating: true,
        borderWidth: 1,
        backgroundColor: null,
      },
      xAxis: {
        categories: compareXLabels,
      },
      yAxis: {},
      credits: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillOpacity: 1,
          tooltip: {
            pointFormat: '{series.name} {point.y:,.2f}',
            valuePrefix: '$',
          },
          marker: {
            enabled: false,
          },
        },
      },
      series: this.formatCompareChartData(this.fundData, null, 'area'),
    });

    this.compareChart = compareChartOutput;

    const premiumChartOutput = new Chart({
      chart: {
        type: 'area',
        backgroundColor: null,
        height: '55%',
      },
      colors: ['#84a9e8'],
      title: {
        text: 'Premium',
      },
      exporting: {
        enabled: false,
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: 30,
        floating: true,
        borderWidth: 1,
        backgroundColor: null,
      },
      xAxis: {
        categories: premiumXLabels,
      },
      yAxis: {},
      credits: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillOpacity: 1,
          tooltip: {
            pointFormat: '{series.name} {point.y:,.2f}',
            valuePrefix: '$',
          },
          marker: {
            enabled: false,
          },
        },
      },
      series: this.formatPremiumChartData(this.fundData, null, 'area'),
    });

    this.premiumChart = premiumChartOutput;
  }

  formatCompareChartData(data, perc, type?) {
    const multi = this.fundData?.multiplier;
    const calcedData = [
      {
        name: 'Death Benefit',
        data: [],
        format: '{series.name} {y:,.2f}',
        type: type,
      },
      {
        name: 'Cash Value',
        data: [],
        format: '{series.name} {y:,.2f}',
        type: type,
      },
    ];
    const permData = data?.perm ? data?.perm.data : null;
    const tempData = data?.temp ? data?.temp.data : null;
    let permPerc = data?.permPercent;
    let tempPerc = data?.tempPercent;

    if (perc && typeof perc != 'undefined') {
      permPerc = perc.perm;
      tempPerc = perc.temp;
    }

    this.tableData.perm = [];
    this.tableData.temp = [];

    for (let i = 0; i < permData?.length; i++) {
      let permCalc = permData ? permData[i]?.CashValue * permPerc * multi : 0;
      let tempCalc;

      // const tempDeath = tempData && tempData[i] && tempData[i].DeathBenefit ? tempData[i].DeathBenefit : 0;

      if (!permData && tempData) {
        console.warn(
          'No data available for currently selected Perm product!!!'
        );
        tempCalc = tempData[i].DeathBenefit * tempPerc * multi;
      } else if (
        permData &&
        (!tempData || !tempData[i] || !tempData[i].DeathBenefit)
      ) {
        console.warn(
          'No data available for currently selected Term product!!!'
        );
        tempCalc = permData[i].DeathBenefit * permPerc * multi;
      } else {
        tempCalc =
          permData[i].DeathBenefit * permPerc * multi +
          tempData[i].DeathBenefit * tempPerc * multi;
      }

      permCalc = isNaN(permCalc) ? 0 : permCalc;
      tempCalc = isNaN(tempCalc) ? 0 : tempCalc;

      calcedData[0].data.push(tempCalc);
      calcedData[1].data.push(permCalc);

      if (i == 0) {
        if (permCalc) {
          this.tableData.perm.push(permCalc);
        } else {
          this.tableData.perm.push(0);
        }

        if (tempCalc) {
          this.tableData.temp.push(tempCalc);
        } else {
          this.tableData.temp.push(0);
        }

        if (permData[i].Age > 65) {
          this.tableData.perm[1] = false;
          this.tableData.temp[1] = false;
        }
      } else if (
        (permData && permData[i].Age == 65) ||
        (tempData && tempData[i] && tempData[i].Age == 65) ||
        (permData && permData[i].Age == 85) ||
        (tempData && tempData[i] && tempData[i].Age == 85)
      ) {
        if (permCalc) {
          this.tableData.perm.push(permCalc);
        } else {
          this.tableData.perm.push(0);
        }

        if (tempCalc) {
          this.tableData.temp.push(tempCalc);
        } else {
          this.tableData.temp.push(0);
        }
      }
    }
    // console.log(this.tableData);
    return calcedData;
  }

  formatPremiumChartData(data, perc, type?) {
    const calcedData = [{ name: 'Premium', data: [], type: type }];
    const permData = data?.perm ? data?.perm.data : null;
    const tempData = data?.temp ? data?.temp.data : null;
    let permPerc = data?.permPercent;
    let tempPerc = data?.tempPercent;
    const multi = this.fundData?.multiplier;

    if (perc && typeof perc != 'undefined') {
      permPerc = perc.perm;
      tempPerc = perc.temp;
    }
    this.tableData.prem = [];
    for (let i = 0; i < permData?.length; i++) {
      let premiumCalc;

      if (!permData && tempData) {
        console.warn(
          'No data available for currently selected Perm product!!!'
        );
        premiumCalc = tempData[i].Premium * tempPerc * multi;
      } else if (
        permData &&
        (!tempData || !tempData[i] || !tempData[i].Premium)
      ) {
        console.warn(
          'No data available for currently selected Term product!!!'
        );
        premiumCalc = permData[i].Premium * permPerc * multi;
      } else {
        premiumCalc =
          (permData[i].Premium * permPerc + tempData[i].Premium * tempPerc) *
          multi;
      }

      calcedData[0].data.push(premiumCalc);

      if (i == 0) {
        if (premiumCalc) {
          this.tableData.prem.push(premiumCalc);
        } else {
          this.tableData.prem.push(0);
        }

        if (permData[i].Age > 65) {
          this.tableData.prem[1] = false;
        }
      } else if (
        (permData && permData[i].Age == 65) ||
        (permData && permData[i].Age == 85) ||
        !permData[i].Age
      ) {
        if (premiumCalc) {
          this.tableData.prem.push(premiumCalc);
        } else {
          this.tableData.prem.push(0);
        }
      }
    }
    return calcedData;
  }

  formatPieChartData(data, perc) {
    let permPerc = data.permPercent * 100;
    let tempPerc = data.tempPercent * 100;

    if (perc && typeof perc != 'undefined') {
      permPerc = perc.perm;
      tempPerc = perc.temp;
    }

    const calcedData = [
      {
        type: 'pie',
        name: 'Product Mix',
        data: [
          ['Permanent', permPerc],
          ['Term', tempPerc],
        ],
      },
    ];

    return calcedData;
  }

  updateChart(ev) {
    if (!ev.blockEmit && ev.value >= 0) {
      this.mixChange.emit({
        perm: ev.value / 100,
        term: (100 - ev.value) / 100,
      });
    }

    if (ev) {
      const compare = this.formatCompareChartData(this.fundData, {
        perm: ev.value / 100,
        temp: (100 - ev.value) / 100,
      });

      if (this.compareChart && this.compareChart.ref) {
        this.compareChart.ref.series.forEach((ser, i) => {
          this.compareChart.ref.series[i].setData(compare[i].data);
        });
      }

      const premium = this.formatPremiumChartData(this.fundData, {
        perm: ev.value / 100,
        temp: (100 - ev.value) / 100,
      });
      if (this.premiumChart && this.premiumChart.ref) {
        this.premiumChart.ref.series[0].setData(premium[0].data);
      }

      if (this.pieChart && this.pieChart.ref) {
        this.pieChart.ref.series[0].setData([
          ['Permanent', ev.value],
          ['Term', 100 - ev.value],
        ]);
      }
    }
  }

  returnPercent(value: number): string {
    return `${value}%`;
  }

  formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(+amount - +i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e);
    }
  }

  updateCashValue(ev) {
    this.cashFlowChange.emit(this.removeMoneyMask(this.cashFlow));
  }

  editCashAmount(ev) {
    this.editAmount = false;
  }

  clearEdit() {
    this.editAmount = true;
    this.cashFlow = this.ogCashFlow;
    this.cashFlowChange.emit(this.cashFlow);
  }

  removeMoneyMask(obj) {
    if (obj) {
      return Number(obj.toString().replace(/\,/gi, '').replace('$', ''));
    }
  }
}
