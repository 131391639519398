import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  SortDescriptor,
  process,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FilterService } from '@progress/kendo-angular-grid';
import { CaseManagementService } from '../case-management.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { CaseManagementModalComponent } from '../case-management-modal/case-management-modal.component';
import { AdHocModalComponent } from '../ad-hoc-modal/ad-hoc-modal.component';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { isEmpty } from 'lodash';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';

@Component({
  selector: 'app-case-management',
  templateUrl: './case-management.component.html',
  styleUrls: ['./case-management.component.scss'],
})
export class CaseManagementComponent implements OnInit, OnDestroy {
  @ViewChild('caseManagementGrid', { static: false }) caseManagementGrid;

  constructor(
    private cms: CaseManagementService,
    private cstmFltrSrvc: CustomFiltersService,
    private ss: SessionStorageService,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private dialog: MatDialog,
    private rbs: RightBridgeApiService
  ) {}

  filterForm: FormGroup = this.fb.group({});
  formFields = { primaryForm: [], secondaryForm: [] };
  currentFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  assignedToControl = new FormControl();
  submitteByControl = new FormControl();
  titleControl = new FormControl();
  typeControl = new FormControl();
  public gridData;
  public ogData;
  public gridColumns = [
    {
      DataField: 'Title',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Title',
      RespVis: '',
      Sort: false,
      Type: 'string',
    },
    {
      DataField: 'Type',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Type',
      RespVis: '',
      Sort: false,
      Type: 'string',
      externalFilter: {
        controlType: 'dropdown',
        filterGroup: 'primary',
        filterType: 'equal',
        label: 'Type',
        varName: 'Type',
        options: [],
      },
    },
    {
      DataField: 'StatusName',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Status',
      RespVis: '',
      Sort: false,
      Type: 'string',
    },
    {
      DataField: 'AssignedToName',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Assigned To',
      RespVis: '',
      Sort: false,
      Type: 'string',
      externalFilter: {
        controlType: 'dropdown',
        filterGroup: 'primary',
        filterType: 'equal',
        label: 'Assigned To',
        varName: 'AssignedToName',
      },
    },
    {
      DataField: 'SubmittedBy',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Submitted By',
      RespVis: '',
      Sort: false,
      Type: 'string',
      externalFilter: {
        controlType: 'dropdown',
        filterGroup: 'primary',
        filterType: 'equal',
        label: 'Submitted By',
        varName: 'SubmittedBy',
      },
    },
    {
      DataField: 'CRID',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'CRID',
      RespVis: '',
      Sort: false,
      Type: 'string',
    },
    {
      DataField: 'CaseNumbers',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Case Numbers',
      RespVis: '',
      Sort: false,
      Type: 'string',
    },
    {
      DataField: 'DateLastUpdated',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Updated',
      RespVis: '',
      Sort: true,
      Type: 'date',
    },
    {
      DataField: 'CreatedDate',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Created Date',
      RespVis: '',
      Sort: true,
      Type: 'date',
    },
  ];
  public pageSize = 25;
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  public pageSizeOptions = [25, 50, 75, 100];
  loading = false;
  filterData = {};
  resetFilters = false;
  externalFilters = [];
  ogExternalFilters = [];
  hideGrid = false;
  globals = this.ss.get('globals');
  defaultUser = this.globals ? this.globals.user : null;
  unsubscribe: Subject<any> = new Subject();
  dateFilters = {};
  dropdownFilters = {};
  filterOptions = {
    Type: [],
    AssignedToName: [],
    SubmittedBy: [],
  };
  autocompleteFilters = ['AssignedToName', 'SubmittedBy'];
  useFilteredUsers = false;

  ngOnInit(): void {
    this.filterOptions = {
      Type: [],
      AssignedToName: [],
      SubmittedBy: [],
    };

    if (
      this.globals &&
      this.globals.user.rights.includes('FilteredUserFilter')
    ) {
      this.getFilteredOptions();
      this.autocompleteFilters.map(filter => {
        this.useFilteredUsers = true;
        const updateFilter = this.gridColumns.find(
          column => column.DataField === filter
        );
        if (updateFilter) {
          updateFilter.externalFilter.controlType = 'autocomplete';
          updateFilter.externalFilter.filterType = 'contains';
        }
      });
    } else {
      this.getUserList();
    }

    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params?.id !== 'undefined') {
        this.getGridData(params.id);
      } else {
        this.getGridData();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUserList(searchString?: string) {
    this.cms.getCmUserFilterList(searchString).subscribe(resp => {
      const newUsersList = resp.Users.map(user => ({
        value: user.UserID,
        display: user.UserName,
      }));
      this.autocompleteFilters.map(filter => {
        this.filterOptions[filter] = resp.Users;
        this.gridColumns.find(
          column => column.DataField === filter
        ).externalFilter.options = newUsersList;
      });
    });
  }

  getGridData(defaultCRID?) {
    this.loading = true;
    this.hideGrid = true;

    const data = {
      search: { ProfileValues: {} },
      skip: this.state.skip,
      take: this.state.take,
      sort: {},
    };
    data.sort = {
      DateLastUpdated: 'ASC',
    };

    if (defaultCRID) {
      data.search.ProfileValues['CRID'] = defaultCRID;
    }

    if (this.titleControl && this.titleControl.value) {
      data.search.ProfileValues['Title'] = this.titleControl.value;
    }

    if (this.typeControl && this.typeControl.value) {
      data.search.ProfileValues['Type'] = this.typeControl.value
    }

    if (isEmpty(this.dateFilters)) {
      this.dateFilters = {
        DateLastUpdated: {
          start: moment().subtract(30, 'days').startOf('day').toISOString(),
          end: moment().endOf('day').toISOString(),
        },
      };

      this.filter.filters.push({
        field: 'DateLastUpdated',
        operator: 'start',
        value: moment().subtract(30, 'days').startOf('day').toISOString(),
      });
      this.filter.filters.push({
        field: 'DateLastUpdated',
        operator: 'end',
        value: moment().endOf('day').toISOString(),
      });
    }

    if (this.filter.filters && this.filter.filters.length > 0) {
      data.search = { ProfileValues: data.search.ProfileValues };
      this.filter.filters.forEach(filter => {
        if (filter['operator'] !== 'start' && filter['operator'] !== 'end') {
          data.search[filter['field']] = filter['value'];
        } else if (!data.search[filter['field']]) {
          data.search[filter['field']] = {};
        }
        if (filter['operator'] === 'start') {
          data.search[filter['field']]['start'] = filter['value'];
        } else if (filter['operator'] === 'end') {
          data.search[filter['field']]['end'] = filter['value'];
        }
      });
    }

    if (this.sort && this.sort.length > 0) {
      this.sort.forEach(sort => {
        if (sort['dir']) {
          data.sort[sort['field']] = sort['dir'].toUpperCase();
        } else {
          delete data.sort[sort['field']];
        }
      });
    }
    this.cms.getCaseManagementData(data).subscribe(resp => {
      const gridData = { data: resp['Data'], total: resp['total'] };
      this.state.skip = resp['skip'];
      this.ogData = gridData;
      this.gridData = gridData;
      this.buildFilterOptions();
      this.updateExternalFilterOptions(this.gridColumns);

      if (defaultCRID) {
        this.filterValueChange([defaultCRID], 'CRID', new FilterService());
        this.reviewCase({ CRID: defaultCRID });
      }

      this.buildTypeOptions(this.ogData.data);

      this.hideGrid = false;
      this.loading = false;
    });
  }

  buildTypeOptions(data) {
    let options = [];

    this.rbs.postGroupProfile('CaseType', 'cm').subscribe(z => {
      z.UnitCaseType.forEach(x => {
        options.push({value: x.Type, display: x.FirmTypeName});
      });

      data.forEach(x => {
        if (!this.filterOptions.Type) {
          this.filterOptions['Type'] = [];
        }
        if (
          this.filterOptions['Type'] &&
          !this.filterOptions['Type'].includes(x.Type)
        ) {
          this.filterOptions['Type'].push(x.Type);
        }
      });
  
      const typeFilter = this.externalFilters.find(
        x => x.fullField.DataField === 'Type'
      );
  
      typeFilter.DataField = typeFilter.fullField.DataField;
      typeFilter.options = options;
    })


  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
  }

  useDefault() {
    this.filter = { logic: 'and', filters: [] };
    this.dateFilters = {};
    this.dropdownFilters = {};
    this.assignedToControl.setValue('');
    this.submitteByControl.setValue('');
    this.sort = [];
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.getGridData();
  }

  updateHidden(ev) {
    this.hidden = [...ev.value];
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  assignedToDisplay(value) {
    const displayOption = this.getDropdownOptions('Assigned To').find(
      option => option.value === value
    );
    const displayString = displayOption
      ? `${displayOption.value}: ${displayOption.display}`
      : '';
    return displayString ? displayString : null;
  }

  submittedByDisplay(value) {
    const displayOption = this.getDropdownOptions('Submitted By').find(
      option => option.value === value
    );
    const displayString = displayOption
      ? `${displayOption.value}: ${displayOption.display}`
      : '';
    return displayString ? displayString : null;
  }

  updateFilters() {
    this.resetFilters = false;
    this.skip = 0;

    if (this.filter.filters && this.filter.filters.length > 0) {
      this.filter.filters = this.deDupObjectArray([...this.filter.filters]);
    }

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    if (
      this.gridData.data &&
      this.gridData.data.length > 0 &&
      this.caseManagementGrid
    ) {
      this.gridData.data.forEach((item, idx) => {
        this.caseManagementGrid.collapseRow(idx);
      });

      this.gridData.data = process(this.ogData, { filter: this.filter }).data;
    }
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;

        if (
          this.externalFilters.findIndex(x => x.varName === el.DataField) === -1
        ) {
          this.externalFilters.push(entry);
        }

        if (el.Type && el.Type.toLowerCase() == 'curr') {
          entry.controlType = 'currency';
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });
  }

  buildDropdownFilterOptions(el) {
    let options;
    options = this.filterOptions[el.DataField].map(x => {
      if (typeof x === 'object') {
        return { value: x.UserID, display: x.UserName };
      } else {
        return { value: x, display: x };
      }
    });
    options = this.deDupObjectArray(options);
    options = this.clearEmptyFields(options);
    options = options.sort((a, b) => (a.display > b.display ? 1 : -1));

    if (el.Validation) {
      options = options.map(x => {
        let label = el.Validation.find(z => x.value == z.value);

        label = label ? label.label : x.value;

        return { value: x.value, display: label };
      });
    }

    return options;
  }

  showClearButton(fieldName) {
    if (
      this.state.filter.filters.find(filter => filter['field'] === fieldName)
    ) {
      return true;
    }
    return false;
  }

  getDropdownOptions(fieldName) {
    return this.externalFilters.find(filter => filter.label === fieldName)
      .options;
  }

  getFilteredOptions() {
    this.assignedToControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getUserList(value);
      });

    this.submitteByControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getUserList(value);
      });
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.getGridData();
  }

  reviewCase(caseData) {
    const data = caseData;
    data['defaultUserId'] = this.defaultUser.id;
    const cmDialog = this.dialog.open(CaseManagementModalComponent, {
      panelClass: 'app-case-management-modal',
      height: '90vh',
      width: '90vw',
      maxHeight: '90vh',
      maxWidth: '90vw',
      data,
      autoFocus: false,
    });

    cmDialog.afterClosed().subscribe(() => {
      this.getGridData();
    });
  }

  dateChange(e, fieldName, position) {
    if (fieldName === 'CreatedDate') {
      const dateUpdatedExists = this.dateFilters['DateLastUpdated'];
      if (dateUpdatedExists) {
        delete this.dateFilters['DateLastUpdated'];
      }

      this.checkAndRemoveFilter('DateLastUpdated');
    }

    if (fieldName === 'DateLastUpdated') {
      const dateUpdatedExists = this.dateFilters['CreatedDate'];
      if (dateUpdatedExists) {
        delete this.dateFilters['CreatedDate'];
      }

      this.checkAndRemoveFilter('CreatedDate');
    }

    console.log(e, fieldName, position);

    let dateValue;

    if (position === 'start') {
      dateValue = moment(e.value).startOf('day').toISOString();
    } else {
      dateValue = moment(e.value).endOf('day').toISOString();
    }

    const filterExists = this.state.filter.filters.findIndex(
      filter => filter['field'] === fieldName && filter['operator'] === position
    );
    const dateFilterExists = this.dateFilters[fieldName];
    if (dateFilterExists) {
      this.dateFilters[fieldName][position] = dateValue;
    } else {
      this.dateFilters[fieldName] = {};
      this.dateFilters[fieldName][position] = dateValue;
    }

    if (filterExists >= 0) {
      this.state.filter.filters[filterExists] = {
        field: fieldName,
        operator: position,
        value: dateValue,
      };
    } else {
      this.state.filter.filters.push({
        field: fieldName,
        operator: position,
        value: dateValue,
      });
    }
  }

  checkAndRemoveFilter(filterType) {
    const dateFilter = this.state.filter.filters.findIndex(
      filter => filter['field'] === filterType
    );
    if (dateFilter >= 0) {
      this.state.filter.filters.splice(dateFilter, 1);
      this.filter.filters.splice(dateFilter, 1);
    }
  }

  selectionChange(ev, fieldName) {
    this.checkAndRemoveFilter(fieldName);
    const filterExists = this.state.filter.filters.findIndex(
      filter => filter['field'] === fieldName
    );

    if (filterExists >= 0) {
      this.state.filter.filters[filterExists] = {
        field: fieldName,
        operator: 'equal',
        value: ev.value || ev.option.value,
      };
    } else {
      this.state.filter.filters.push({
        field: fieldName,
        operator: 'equal',
        value: ev.value || ev.option.value,
      });
    }
    this.dropdownFilters[fieldName] = ev.value || ev.option.value;
  }

  clearFilter(ev, fieldName) {
    ev.preventDefault();
    ev.stopPropagation();

    if (fieldName === 'AssignedTo') {
      this.assignedToControl.setValue('');
    }
    if (fieldName === 'SubmittedBy') {
      this.submitteByControl.setValue('');
    }

    this.dropdownFilters[fieldName] = '';

    this.checkAndRemoveFilter(fieldName);
  }

  createAdHocCase() {
    const dialogRef = this.dialog.open(AdHocModalComponent, {
      panelClass: 'app-ad-hoc-modal',
      data: {
        useFilteredUsers: this.useFilteredUsers,
        usersList: this.gridColumns.find(
          column => column.DataField === 'AssignedToName'
        ).externalFilter.options,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.CRID) {
        const cmDialog = this.dialog.open(CaseManagementModalComponent, {
          panelClass: 'app-case-management-modal',
          height: '90vh',
          width: '90vw',
          maxHeight: '90vh',
          maxWidth: '90vw',
          data: {
            CRID: result.CRID,
            defaultUserId: this.defaultUser.id,
          },
          autoFocus: false,
        });

        cmDialog.afterClosed().subscribe(() => {
          this.getGridData();
        });
      }
    });
  }
}
