import {of as observableOf} from 'rxjs';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { SessionStorageService }  from './session-storage.service'
import { Observable } from 'rxjs/Rx';

@Injectable()
export class SsoVerifyService{

  constructor(private rbs: RightBridgeApiService, private router: Router, private ss: SessionStorageService) { }
  
  verify(params): Observable<boolean>{
    let session: string = params.session || null,
      org: string = params.org || null,
      auth: any = false;

    console.log(session, org);

    if (session && org) {
      // console.log('hey');
      this.rbs.querySession(session, org).pipe(map(data => {
        let auth = Number(data.results) > 0;
        console.log(auth, data);
        return observableOf(auth);
      }));
    } else {
      return observableOf(false);
    }
  }
}
