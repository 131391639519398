
import {throwError as observableThrowError,  Observable, interval } from 'rxjs';
import { take, concat, tap, retryWhen } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { SessionStorageService }  from './session-storage.service'

// declare var require: any;

@Injectable()
export class AppAccessService  {
  rights = null;
  url: string;
  accessList = [];

  constructor(private rbs: RightBridgeApiService, private ss: SessionStorageService, public router: Router) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let auth: any = false;
    this.url = state.url ? state.url.split('/')[1] : '';

    this.ss.storageChangeExternal.subscribe((chng) => {
      if (chng.key === 'rights') {
        setTimeout(() => {
          auth = this.getRights();
          console.log('why?', auth, this.url, this.rights);
          return auth;
        }, 5000); 
      }
    })

    return true;
  }

  checkAccess() {
    console.log('checking access&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&', this.rights);
    let access: boolean = false;
    let app = this.url.toUpperCase();

    this.accessList = this.rights.filter(z => {
      return z.toUpperCase() == 'AW' || z.toUpperCase() == 'IW' || z.toUpperCase() == 'PP'
    });
    access = this.rights.includes(app);

    console.log(this.rights.length, access, this.accessList);
    
    return access;
  }

  getRights() {
    let auth: boolean = false;
    this.rbs.getRights()
      .pipe(tap(
        val => {
          console.log('@@@@@@@@@@@@', val);
          this.rights = val;
          console.log('######', this.rights);
          // if (this.rights) {
          auth = this.checkAccess();
          // }
        },
        err => {
          console.log('Here is the error ', err)
      }),
      retryWhen(err => {
        console.log(err)
        return interval(1000).pipe(take(10),concat(observableThrowError('error')))
      }))
      .subscribe(
        val => {
          console.log('&&&&&&', val);
          this.rights = val;
          auth = this.checkAccess();

          if (!auth) {
            console.log('no auth');
            if (this.accessList.length > 0 && !auth) {
              let redir = '/' + this.accessList[0];
              console.log('in no auth but access', this.accessList, redir);
              this.router.navigate([redir]);
              return auth;
            } else {
              console.log('in else');
              this.rbs.logout();
              return auth;
            }
          }

          console.log('success', new Date().toTimeString());
        },
        error => {
          console.log('subscribe', error + new Date().toTimeString());
          this.rights = null;
        },
        () => {
          console.log('finally', new Date().toTimeString());
        }
      );
      console.log(auth, 'in getrights');
      return auth;
  }
}
