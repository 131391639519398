import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { saveAs } from '@progress/kendo-file-saver';
import * as moment from 'moment';
// import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-living-benefits',
  templateUrl: './living-benefits.component.html',
  styleUrls: ['./living-benefits.component.scss']
})
export class LivingBenefitsComponent implements OnInit {
  private environment;
  public expiring: Array<object>;
  public updating: Array<object>;
  state: string = 'start';
  now = moment(moment.now());
  private baseUrl: string;

  constructor(
    private dbs: DashboardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {

  }

  GetClass(startDate: string, expireDate: string): string {
    let start = moment(startDate, "M/D/YYYY LTS").utc();
    let expire = moment(expireDate, "M/D/YYYY LTS").utc();
    if (expire >= moment(moment.now()).utc().subtract(1, 'days') && expire <= moment(moment.now()).utc().add(1, 'days'))
    {
      return "ExpiringTomorrow";
    }
    if (expire >= moment(moment.now()).utc().subtract(1, 'days') && expire <= moment(moment.now()).utc().add(7, 'days'))
    {
      return "ExpiringSoon";
    }
    if (start >= moment(moment.now()).utc() && start <= moment(moment.now()).utc().add(1, 'days'))
    {
      return "EffectiveToday";
    }
    if (start >= moment(moment.now()).utc() && start <= moment(moment.now()).utc().add(7, 'days'))
    {
      return "EffectiveSoon";
    }
    return "";
  }

  download(): void {
    let iosSafari = this.pltfrm.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.dbs.getDashboardAnnuitiesCsv("livingbenefits").subscribe(data => {
      let filename = `living_benefits.csv`;

      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari
        });
        // this.loading = false;
      };
    }, e => {

    });
  }

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  load(): void {
    this.state = "loading";
    this.dbs.getDashboardAnnuities("livingbenefits").subscribe(x => {
      this.state = 'loaded';
      if (x['updating']['data'].length) {
        this.updating = x['updating']['data'].slice(0, 10);
      }
      if (x['expiring']['data'].length) {
        this.expiring = x['expiring']['data'].slice(0, 10);
      }
      if (this.expiring == null && this.updating == null)
      {
        this.state = 'none';
      }
    }, e => {
      this.state = 'none';
      catchError(e);
    });
  }

}
