<div class="grid-component">
  <label *ngIf="props.label" [innerHTML]="props.label"></label>
  <div class="toolbar clearfix w-100">
    <ng-container *ngIf="showTemplates">
      <mat-form-field class="col-9">
        <mat-label>{{ label }}</mat-label>
        <mat-select
          [placeholder]="placeholder"
          (selectionChange)="applyTemplate($event)"
          [disabled]="!firmTemplates && !templates"
        >
          <ng-container *ngIf="firmTemplates && firmTemplates.length > 0">
            <mat-optgroup label="Firm Templates">
              <mat-option *ngFor="let tmplt of firmTemplates" [value]="tmplt">
                {{ tmplt.name }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
          <ng-container *ngIf="templates && templates.length > 0">
            <mat-optgroup label="User Templates">
              <mat-option *ngFor="let tmplt of templates" [value]="tmplt">
                {{ tmplt.name }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-select>
        <mat-hint></mat-hint>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="fiveTwoNineSearch">
      <mat-form-field class="col-12">
        <mat-label>Select a Plan</mat-label>
        <mat-select
          [selectionChanged]="handle529($event)"
          [(ngModel)]="selected529"
        >
          <mat-option *ngFor="let plan of plans529" [value]="plan">{{
            plan.PlanName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="searchOptions">
      <app-fund-search
        [type]="props.searchType"
        [options]="searchOptions"
        [program]="program"
        [fiveTwoNinePlan]="selected529?.PlanId"
        (fundSelected)="handleFund($event)"
        class="search-box mb-3"
      ></app-fund-search>
      <button
        mat-button
        class="btn btn-primary pull-right"
        color="primary"
        type="button"
        (click)="add()"
        *ngIf="buttonOption && this.field['showButton']"
      >
        <mat-icon class="mr-2">add_circle</mat-icon>{{ buttonOption }}
      </button>
    </ng-container>
    <button
      mat-button
      *ngIf="!searchOptions && this.field['showButton'] && !hideDelete"
      class="btn btn-primary pull-right"
      color="primary"
      type="button"
      (click)="add()"
    >
      <mat-icon class="mr-2">add_circle</mat-icon>Add
    </button>
  </div>

  <h5 *ngIf="field.key === 'SelectedFunds'" class="w-100 pull-right">
    Investment Program: {{ investmentProgramLabel }}
  </h5>

  <kendo-grid
    *ngIf="!loading"
    class="col-12"
    #gridExp
    [kendoGridBinding]="model"
    [selectable]="{ enabled: false }"
    [kendoGridExpandDetailsBy]="expandDetailsBy"
    [(expandedDetailKeys)]="expandedDetailKeys"
    [navigable]="true"
  >
    <ng-container *ngFor="let column of columns">
      <kendo-grid-column
        [field]="column.key"
        [editable]="true"
        [hidden]="column.visuallyHidden"
        *ngIf="!hideColumn(field, column, 0)"
      >
        <ng-template kendoGridHeaderTemplate>
          <div [ngClass]="{ 'has-help': column.help }">
            {{ column.label }}
            <mat-icon
              *ngIf="column.help"
              class="help-icon"
              [matTooltip]="column.help"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'rb-tooltip'"
            >
              help
            </mat-icon>
          </div>
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
          k-grid-ignore-click
          *ngIf="column.key !== 'CurrentPosition_Sell'"
        >
          <formly-field
            [field]="getField(field, column, rowIndex)"
            k-grid-ignore-click
          ></formly-field>
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
          *ngIf="column.key === 'CurrentPosition_Sell'"
        >
          <mat-checkbox
            (change)="changeSell(rowIndex, field, column, rowIndex)"
            [checked]="sellChecked(rowIndex)"
          >
            <span>Sell</span>
          </mat-checkbox>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
    <kendo-grid-column width="60" *ngIf="!hideDelete">
      <ng-template
        kendoGridCellTemplate
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <button
          mat-icon-button
          class="delete"
          (click)="remove(rowIndex, $event)"
          matToolTip="Delete Entry"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>

    <ng-template
      kendoGridDetailTemplate
      let-rowIndex="rowIndex"
      let-value="value"
      let-row="row"
    >
      <section>
        <ng-container *ngFor="let subField of subFields">
          <!-- <ng-container *ngIf="subField.props.subField"> -->
          <formly-field
            [field]="getField(field, subField, rowIndex)"
            k-grid-ignore-click
            *ngIf="
              subField.props.subField &&
              !getField(field, subField, rowIndex).hide
            "
          ></formly-field>
          <!-- </ng-container> -->
        </ng-container>
      </section>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>{{ noRecords }}</ng-template>
  </kendo-grid>
</div>
