<div class="page-container case-management-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12">
    Case Management
    <button mat-button color="primary" class="pull-right" (click)="createAdHocCase()"
      *hasPermission="'CreateCMCaseAdHoc'">
      <mat-icon>add_circle_outline</mat-icon>Create Case
    </button>
  </h1>

  <div class="row w-100 controls">
    <div class="w-100 px-3 mb-3" *ngIf="!hideGrid">
      <h5>Filter results</h5>
      <mat-form-field class="mr-2">
        <mat-label>Type</mat-label>
        <mat-select (selectionChange)="selectionChange($event, 'Type')" [value]="dropdownFilters['Type']"
          [formControl]="typeControl">
          @for (opt of getDropdownOptions('Type'); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.display }}
          </mat-option>
          }
        </mat-select>
        @if (showClearButton('Type')) {
        <button matSuffix mat-icon-button aria-label="Clear" color="warn" matTooltip="Clear Filter"
          (click)="clearFilter($event, 'Type')">
          <mat-icon>close</mat-icon>
        </button>
        }
      </mat-form-field>
      @if (!useFilteredUsers) {
      <mat-form-field class="mr-2 user-select">
        <mat-label>Assigned To</mat-label>
        <mat-select (selectionChange)="selectionChange($event, 'AssignedTo')" [value]="dropdownFilters['AssignedTo']">
          @for (opt of getDropdownOptions('Assigned To'); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
          }
        </mat-select>
        @if (showClearButton('AssignedToName')) {
        <button matSuffix mat-icon-button aria-label="Clear" color="warn" matTooltip="Clear Filter"
          (click)="clearFilter($event, 'AssignedToName')">
          <mat-icon>close</mat-icon>
        </button>
        }
      </mat-form-field>
      <mat-form-field class="mr-2 user-select">
        <mat-label>Submitted By</mat-label>
        <mat-select (selectionChange)="selectionChange($event, 'SubmittedBy')" [value]="dropdownFilters['SubmittedBy']">
          @for (opt of getDropdownOptions('Submitted By'); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
          }
        </mat-select>
        @if (showClearButton('SubmittedBy')) {
        <button matSuffix mat-icon-button aria-label="Clear" color="warn" matTooltip="Clear Filter"
          (click)="clearFilter($event, 'SubmittedBy')">
          <mat-icon>close</mat-icon>
        </button>
        }
      </mat-form-field>
      }
      <ng-container *ngIf="useFilteredUsers">
        <mat-form-field class="mr-2 user-select">
          <mat-label>Assigned To</mat-label>
          <input type="text" [placeholder]="'Assigned To'" matInput [matAutocomplete]="autoAssignedTo"
            name="unitsControl" [formControl]="assignedToControl" />
          <mat-autocomplete #autoAssignedTo="matAutocomplete" [displayWith]="assignedToDisplay.bind(this)"
            (optionSelected)="selectionChange($event, 'AssignedTo')">
            @for (opt of getDropdownOptions('Assigned To'); track opt.value) {
            <mat-option value="{{ opt.value }}">
              {{ opt.value }}: {{ opt.display }}
            </mat-option>
            }
          </mat-autocomplete>
          @if (showClearButton('AssignedTo')) {
          <button matSuffix mat-icon-button aria-label="Clear" color="warn" matTooltip="Clear Filter"
            (click)="clearFilter($event, 'AssignedTo')">
            <mat-icon>close</mat-icon>
          </button>
          }
        </mat-form-field>
        <mat-form-field class="mr-2 user-select">
          <mat-label>Submitted By</mat-label>
          <input type="text" [placeholder]="'Submitted By'" matInput [matAutocomplete]="autoSubmittedBy"
            name="unitsControl" [formControl]="submitteByControl" />
          <mat-autocomplete #autoSubmittedBy="matAutocomplete" [displayWith]="submittedByDisplay.bind(this)"
            (optionSelected)="selectionChange($event, 'SubmittedBy')">
            @for (opt of getDropdownOptions('Submitted By'); track opt.value) {
            <mat-option value="{{ opt.value }}">
              {{ opt.value }}: {{ opt.display }}
            </mat-option>
            }
          </mat-autocomplete>
          @if (showClearButton('SubmittedBy')) {
          <button matSuffix mat-icon-button aria-label="Clear" color="warn" matTooltip="Clear Filter"
            (click)="clearFilter($event, 'SubmittedBy')">
            <mat-icon>close</mat-icon>
          </button>
          }
        </mat-form-field>
      </ng-container>
      <mat-form-field class="mr-2 titleSearch">
        <mat-label>Case Title</mat-label>
        <input type="text" [placeholder]="'Case Title'" matInput name="unitsControl" [formControl]="titleControl" />
      </mat-form-field>
      <button mat-flat-button color="primary" class="search-button" (click)="getGridData()">
        <mat-icon class="mr-2">search</mat-icon>Search Results
      </button>
    </div>
    <div class="w-100 px-3 pb-3">
      <mat-form-field class="mr-2">
        <mat-label>Updated</mat-label>
        <mat-date-range-input [rangePicker]="updatedPicker">
          <input matStartDate placeholder="Start date" [value]="dateFilters['DateLastUpdated']?.start"
            (dateChange)="dateChange($event, 'DateLastUpdated', 'start')" />
          <input matEndDate placeholder="End date" [value]="dateFilters['DateLastUpdated']?.end"
            (dateChange)="dateChange($event, 'DateLastUpdated', 'end')" />
        </mat-date-range-input>
        <mat-hint> Cannot be used with 'Created' </mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="updatedPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #updatedPicker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field class="mr-2">
        <mat-label>Created</mat-label>
        <mat-date-range-input [rangePicker]="createdPicker">
          <input matStartDate placeholder="Start date" [value]="dateFilters['CreatedDate']?.start"
            (dateChange)="dateChange($event, 'CreatedDate', 'start')" />
          <input matEndDate placeholder="End date" [value]="dateFilters['CreatedDate']?.end"
            (dateChange)="dateChange($event, 'CreatedDate', 'end')" />
        </mat-date-range-input>
        <mat-hint> Cannot be used with 'Updated' </mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="createdPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #createdPicker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <button mat-button color="primary" class="reset-button" (click)="useDefault()">
      <mat-icon>replay</mat-icon>Reset
    </button>
  </div>

  <kendo-grid class="col-12 mb-5" #caseManagementGrid="kendoGrid" [data]="gridData" [skip]="state.skip"
    [pageSize]="state.take" [state]="state" [pageable]="true" [columnMenu]="{ columnChooser: false }" [filter]="filter"
    [filterable]="false" [sortable]="{ allowUnsort: true, mode: 'multiple' }" [sort]="sort"
    (sortChange)="sortChange($event)">
    <kendo-grid-column width="100" title="">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button mat-icon-button class="fs-button" (click)="reviewCase(dataItem)" color="primary"
          matTooltip="Review Case">
          <mat-icon>rate_review</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <ng-template ngFor [ngForOf]="gridColumns" let-column>
      <kendo-grid-column [field]="column.DataField" [hidden]="isHidden(column.DataField)" [title]="column.Label"
        [sortable]="column.Sort" [groupable]="column.Group" [media]="column.RespVis" [width]="125">
        <ng-template kendoGridFilterMenuTemplate let-filter="filter" let-filterService="filterService">
          <kendo-multiselect style="width: 99%; margin-top: -30px" [data]="filterData[column.DataField]"
            textField="display" valueField="value" [valuePrimitive]="true" [value]="filter | filterValues"
            (valueChange)="
              filterValueChange($event, column.DataField, filterService)
            " [fillMode]="'outline'" placeholder="Filter By..."></kendo-multiselect>
        </ng-template>
        @if (column.Type === 'integer') {
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem[column.DataField] | number: '1.0-0' }}
        </ng-template>
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          {{ value | number: '1.0-0' }}
        </ng-template>
        }
        @if (column.Type === 'perc') {
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem[column.DataField] | percent }}
        </ng-template>
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          {{ value | percent }}
        </ng-template>
        }
        @if (column.Type === 'perc2') {
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem[column.DataField] | percent: '1.2' }}
        </ng-template>
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          {{ value | percent: '1.2' }}
        </ng-template>
        }
        @if (column.Type === 'curr') {
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem[column.DataField] | currency }}
        </ng-template>
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          {{ value | currency }}
        </ng-template>
        }
        @if (column.Type === 'date') {
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem[column.DataField] | date: 'short' }}
        </ng-template>
        }
        @if (column.DataField === 'notes') {
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHtml]="dataItem[column.DataField]"></span>
        </ng-template>
        }
        @if (column.DataField === 'SubmittedBy') {
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHtml]="dataItem['SubmittedByName']"></span>
        </ng-template>
        }
      </kendo-grid-column>
    </ng-template>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="pageSizeOptions"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>

  <ngx-loading [show]="loading"></ngx-loading>
</div>