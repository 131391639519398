import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UniversalSearchService } from '../services/universal-search.service';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {
  headline;
  searchModel = {
    firstName: null,
    lastName: null,
    oppNum: 3,
    relThresh: 50,
    actionDate: '30',
    suggestions: [],
    actions: [],
    postalCode: null,
    city: null,
    ageFrom: null,
    ageTo: null,
    incomeFrom: null,
    incomeTo: null,
    assetsFrom: null,
    assetsTo: null,
    productsFrom: null,
    productsTo: null,
    profileFrom: null,
    profileTo: null,
    source: null,
    marketCategory: {
      youngConsumers: null,
      preRetirees: null,
      retirees: null,
      smallBusiness: null,
      professionals: null,
      others: null,
    },
    additional: null,
    timeSensitive: 'all',
    callBackStart: null,
    callBackEnd: null,
    callBackFilter: 'all',
  };

  savedSearch;
  searchType;
  searchDef;
  suggestions;
  selectedSuggestions = {};
  selectedActions = { '2100': true, '2300': true, '2200': true, '2900': true };

  constructor(
    private dialogRef: MatDialogRef<SearchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usService: UniversalSearchService
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.searchType = this.data.type;
    if (this.data.saved && !this.data.saved.searchDef) {
      this.savedSearch = this.data.saved;
    } else if (
      this.data.saved &&
      JSON.parse(JSON.parse(JSON.stringify(this.data.saved)).searchDef)
    ) {
      this.savedSearch = JSON.parse(
        JSON.parse(JSON.stringify(this.data.saved)).searchDef
      );
    }

    console.log(this.savedSearch);
    // this.savedSearch = this.data.saved && JSON.parse(JSON.parse(JSON.stringify(this.data.saved)).searchDef) ? JSON.parse(JSON.parse(JSON.stringify(this.data.saved)).searchDef) : null;
    switch (this.searchType) {
      case 'name':
        this.headline = 'Search by Name';
        break;

      case 'opp':
        this.headline = 'Search by Best Opportunities';
        break;

      case 'suggestion':
        this.headline = 'Search by Suggestions';
        const bridgesObj =
          this.savedSearch && this.savedSearch.search
            ? this.savedSearch.search.find(el =>
                Object.keys(el).includes('bridgeids')
              )
            : null;
        this.getSuggs(bridgesObj);
        break;

      case 'lastAction':
        this.headline = 'Search by Last Action';
        break;

      case 'timeSensitive':
        this.headline = 'Search by Time Sensitive Suggestions';
        break;

      case 'demographics':
        this.headline = 'Search by Demographics';
        break;

      case 'callBack':
        this.headline = 'Search by Callback Date';
        break;

      default:
        this.headline = 'Search';
        break;
    }
  }

  getSuggs(saved) {
    this.usService.getSuggestions().subscribe(x => {
      this.suggestions = x;
      if (saved && saved.bridgeids) {
        saved.bridgeids.split(',').forEach(el => {
          this.selectedSuggestions[el] = true;
        });
      }
    });
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  search() {
    this.dialogRef.close({ data: this.searchDef });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

  setSearchData() {
    switch (this.searchType) {
      case 'name':
        this.searchDef = {
          firstName: this.searchModel.firstName,
          lastName: this.searchModel.lastName,
          searchby: 'name',
        };
        this.search();
        break;

      case 'opp':
        this.searchDef = {
          RelevantBridgeCount: this.searchModel.oppNum,
          searchby: 'RelevantBridgeCount',
        };
        this.search();
        break;

      // case 'highScore':
      //   this.searchDef = {searchby: 'highbridge'};
      //   break;

      // case 'callList':
      //   this.searchDef = {searchby: 'callList'};
      //   break;

      case 'suggestion':
        const suggs = Object.keys(this.selectedSuggestions).filter(
          x => this.selectedSuggestions[x]
        );

        this.searchDef = {
          searchBy: 'suggestion',
          bridgeids: suggs,
          relThresh: this.searchModel.relThresh,
        };
        console.log(this.searchDef);
        this.search();
        break;

      case 'lastAction':
        if (this.selectedActions['2100']) {
          const follows = ['2120', '2128', '2150', '2155', '2160'];
          follows.forEach(x => {
            this.selectedActions[x] = true;
          });
        }

        if (this.selectedActions['2300']) {
          const likes = ['2300', '2310', '2320', '2330', '2335', '2390'];
          likes.forEach(x => {
            this.selectedActions[x] = true;
          });
        }

        if (this.selectedActions['2200']) {
          const noThanks = [
            '2210',
            '2220',
            '2230',
            '2240',
            '2245',
            '2250',
            '2290',
          ];
          noThanks.forEach(x => {
            this.selectedActions[x] = true;
          });
        }

        let actions = '';
        Object.keys(this.selectedActions).forEach(el => {
          if (this.selectedActions[el] && actions.length > 0) {
            actions += `,${el}`;
          } else if (this.selectedActions[el]) {
            actions += `${el}`;
          }
        });

        const date = moment(new Date())
          .subtract(+this.searchModel.actionDate, 'days')
          .format('MM/DD/YYYY');
        this.searchDef = {
          searchBy: 'action',
          actionids: actions,
          actionStartDate: date,
        };
        if (!this.searchDef.actionids) {
          this.searchDef = 'cancel';
        }
        console.log(this.searchDef);
        this.search();
        break;

      case 'timeSensitive':
        this.searchDef = { searchBy: 'TimeSensitive' };
        console.log(this.searchModel.timeSensitive);
        if (this.searchModel.timeSensitive !== 'all') {
          this.searchDef['prospects'] =
            this.searchModel.timeSensitive === 'prospects';
          this.search();
        } else {
          this.search();
        }

        break;

      case 'demographics':
        this.searchDef = { searchBy: 'Demographics', ...this.searchModel };
        let temp = '';
        Object.keys(this.searchDef.marketCategory).forEach(el => {
          if (this.searchDef.marketCategory[el]) {
            if (temp && temp.length > 0) {
              temp += `,${el}`;
            } else {
              temp += `${el}`;
            }
          }
        });
        Object.keys(this.searchDef).forEach(el => {
          this.searchDef[el] = !this.searchDef[el] ? '' : this.searchDef[el];
        });
        this.searchDef.marketCategory = temp;
        console.log(this.searchModel);
        this.search();

        break;

      case 'callBack':
        const start = moment(new Date(this.searchModel.callBackStart)).format(
          'MM/DD/YYYY'
        );
        const end = moment(new Date(this.searchModel.callBackEnd)).format(
          'MM/DD/YYYY'
        );

        this.searchDef = { searchBy: 'action', StartDate: start, EndDate: end };
        if (this.searchModel.callBackFilter !== 'all') {
          this.searchDef['prospects'] =
            this.searchModel.callBackFilter === 'prospects';
          this.search();
        } else {
          this.search();
        }

        break;

      default:
        break;
    }
  }

  toggleAllSuggs(ev) {
    Object.keys(this.suggestions).forEach(el => {
      this.suggestions[el].forEach(x => {
        this.selectedSuggestions[x.id] = ev.checked;
      });
    });
  }

  isDisabled() {
    switch (this.searchType) {
      case 'suggestion':
        const suggs = Object.keys(this.selectedSuggestions).filter(
          x => this.selectedSuggestions[x]
        );
        return suggs.length < 1;

      case 'callBack':
        return !this.searchModel.callBackEnd || !this.searchModel.callBackStart;

      default:
        return false;
    }
  }
}
