<div class="page-container explorer-container container mt-5 unified">
  <app-utility-toolbar
    [current]="'caseExplorer'"
    [vertical]="true"
    class="w-10 float-left vertical"
  ></app-utility-toolbar>
  <div class="explorer-content-container w-90 float-left">
    <h1 class="header col-12"
      >Case Explorer
      <button
        mat-button
        color="primary"
        class="grid-button header-button"
        (click)="dlXlsx()"
      >
        <!-- (click)="exportToExcel(explorerGrid)" -->
        <mat-icon>cloud_download</mat-icon>Download Excel File
      </button>
    </h1>
    <div class="tool-section">
      <div class="col-12 mb-3 row toolbar">
        <!-- <app-grid-filters class="col-12" (filtersUpdated)="updateFilters($event)" [filters]="externalFilters"></app-grid-filters> -->
        <div class="filters-container">
          <div class="solution-select-container">
            <mat-form-field class="ml-2">
              <mat-label>Solution</mat-label>
              <mat-select [(ngModel)]="solution">
                <mat-option *ngFor="let sltn of solutions" [value]="sltn.value">
                  {{ sltn.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="date-radio-container">
            <div class="date-select-container ml-2">
              <mat-form-field class="mr-2 startDate">
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="startPicker"
                  placeholder="Choose a start date"
                  [formControl]="startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="mr-2 endDate">
                <mat-label>End Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="endPicker"
                  placeholder="Choose an end date"
                  [formControl]="endDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="radio-container">
              <mat-label class="ml-2"><strong>View Mode:</strong></mat-label>
              <mat-radio-group [(ngModel)]="flatOrGrouped">
                <mat-radio-button class="mr-1" value="GROUP"
                  >Case</mat-radio-button
                >
                <mat-radio-button class="mr-1" value="FLAT"
                  >Product/Category</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </div>
          <!-- <mat-form-field class="pull-right mr-2" *ngIf="dataView == 'data'">
        <mat-label>Sort By</mat-label>
        <mat-select [(ngModel)]="sort" (selectionChange)="updateSorting()">
          <mat-option *ngFor="let sort of sortingPresets" [value]="sort.value">{{sort.label}}</mat-option>
        </mat-select>
      </mat-form-field> -->
        </div>
        <div class="update-search-button-container">
          <button
            mat-flat-button
            color="primary"
            class="update-search-button ml-2"
            (click)="getCaseStatsSummary()"
            [disabled]="disableSearch()"
          >
            Update Search
          </button>
        </div>
      </div>

      <div class="utilities">
        <div class="col-6 hierarchy pull-left mb-2">
          <button
            mat-button
            (click)="setHierarchy()"
            *ngIf="chooseHierarchy"
            color="primary"
          >
            <mat-icon>search</mat-icon>Change Search Hierarchy
          </button>
          <p *ngIf="selectedUnit || selectedUser || selectedRepCode">
            <strong>Searching for: </strong>
            <ng-container
              *ngIf="selectedUnit && !selectedUser && !selectedRepCode"
              class="search-as"
              >Unit: {{ selectedUnit.name }}</ng-container
            >
            <ng-container
              *ngIf="selectedUser && !selectedRepCode"
              class="search-as"
              >User: {{ selectedUser.name }}</ng-container
            >
            <ng-container *ngIf="selectedRepCode" class="search-as"
              >Rep Code: {{ selectedRepCode.name }}</ng-container
            >
          </p>
          <button
            mat-button
            (click)="clearHierarchy()"
            *ngIf="selectedUnit || selectedUser || selectedRepCode"
            class="clear"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <button
          mat-button
          color="primary"
          class="reset-button header-button pull-right"
          (click)="resetGrid()"
        >
          <mat-icon>replay</mat-icon>Reset
        </button>
      </div>
    </div>

    @if (!hideGrid) {
      <kendo-grid
        class="col-12 case-stats-activity-grid"
        #explorerGrid="kendoGrid"
        [kendoGridBinding]="gridData.data"
        [sortable]="{
          allowUnsort: true,
          mode: 'multiple'
        }"
        [sort]="state.sort"
        [columnMenu]="{ columnChooser: false }"
        [style.maxHeight.px]="700"
        [filterable]="'menu'"
        [filter]="filter"
        [pageable]="true"
        [pageSize]="pageSize"
        [skip]="state.skip"
        [state]="state"
      >
        <!-- && profileHistory.History.length < 1 -->
        <ng-template ngFor [ngForOf]="gridData.headers" let-column>
          <kendo-grid-column
            [field]="column.DataField"
            [title]="column.Label"
            [sortable]="column.Sort"
            [hidden]="isHidden(column.DataField)"
            [filterable]="column.Filter"
            [groupable]="column.Group"
            [media]="column.RespVis"
            [width]="column.DataField === 'User' ? '275' : null"
            [class]="{
              'text-center':
                column.DataField === 'ScoreClass' ||
                column.DataField === 'ProductChanges' ||
                column.DataField === 'ScoreClassChanges' ||
                column.DataField === 'ProductType' ||
                column.DataField === 'RunCount' ||
                column.DataField === 'Track' ||
                column.DataField === 'PurchaseType' ||
                column.DataField === 'Amount' ||
                column.DataField === 'AssessmentType',
              clickable:
                column.DataField === 'User' || column.DataField === 'ClientName'
            }"
          >
            <ng-template
              kendoGridFilterMenuTemplate
              let-column="column"
              let-filter="filter"
              let-filterService="filterService"
              *ngIf="column.FilterListDynamic || column.FilterList"
            >
              <app-multicheck-filter
                [isPrimitive]="true"
                [field]="column.field"
                [filterService]="filterService"
                [currentFilter]="filter"
                [data]="distinctPrimitive(column.field)"
              ></app-multicheck-filter>
            </ng-template>
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              *ngIf="column.DataField === 'User'"
            >
              {{ dataItem.User }}
              <!-- *ngIf="!gridData['subHead']" -->
              <button
                mat-icon-button
                *ngIf="solution !== 'ALL'"
                (click)="viewHistory($event, dataItem)"
                matTooltip="View Case History"
                class="pull-right"
                color="warn"
              >
                <mat-icon>history</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="solution !== 'ALL'"
                (click)="viewCase($event, dataItem)"
                matTooltip="Open Case"
                class="pull-right"
                color="primary"
              >
                <mat-icon>work</mat-icon>
              </button>
            </ng-template>
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              *ngIf="column.DataField === 'ClientName'"
              let-columnIndex="columnIndex"
            >
              {{ dataItem.ClientName }}
              <button
                mat-icon-button
                *ngIf="columnIndex === 0 && solution !== 'ALL'"
                (click)="viewHistory($event, dataItem)"
                matTooltip="View Case History"
                class="pull-right"
                color="warn"
              >
                <mat-icon>history</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="columnIndex === 0 && solution !== 'ALL'"
                (click)="viewCase($event, dataItem)"
                matTooltip="Open Case"
                class="pull-right"
                color="primary"
              >
                <mat-icon>work</mat-icon>
              </button>
            </ng-template>
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              *ngIf="column.DataField === 'ScoreClass'"
            >
              <mat-icon
                *ngIf="
                  dataItem.ScoreClass &&
                  dataItem.ScoreClass.toLowerCase() === 'positive'
                "
                class="positive"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="
                  dataItem.ScoreClass &&
                  dataItem.ScoreClass.toLowerCase() === 'neutral'
                "
                class="neutral"
                >error</mat-icon
              >
              <mat-icon
                *ngIf="
                  dataItem.ScoreClass &&
                  dataItem.ScoreClass.toLowerCase() === 'negative'
                "
                class="negative"
                >remove_circle</mat-icon
              >
            </ng-template>
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              *ngIf="column.DataField === 'DateTime'"
            >
              {{ dataItem['DateTime'] | localTime: 'shortDateTime' }}
            </ng-template>

            <ng-template
              kendoGridCellTemplate
              let-dataItem
              *ngIf="column.Validation"
            >
              {{ findValue(dataItem[column.DataField], column.Validation) }}
            </ng-template>
          </kendo-grid-column>
        </ng-template>
        <ng-template
          kendoGridDetailTemplate
          let-dataItem
          *ngIf="gridData['subHead']"
        >
          <section>
            <table class="w-95 pull-right detail-table">
              <thead>
                <th *ngFor="let header of gridData['subHead']">
                  {{ header.Label }}
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let product of dataItem.products">
                  <td *ngFor="let header of gridData['subHead']">
                    <ng-container
                      *ngIf="header.DataField === 'ScoreClass'; else normal"
                    >
                      <mat-icon
                        *ngIf="
                          product.ScoreClass &&
                          product.ScoreClass.toLowerCase() === 'positive'
                        "
                        class="positive"
                        >check_circle</mat-icon
                      >
                      <mat-icon
                        *ngIf="
                          product.ScoreClass &&
                          product.ScoreClass.toLowerCase() === 'neutral'
                        "
                        class="neutral"
                        >error</mat-icon
                      >
                      <mat-icon
                        *ngIf="
                          product.ScoreClass &&
                          product.ScoreClass.toLowerCase() === 'negative'
                        "
                        class="negative"
                        >remove_circle</mat-icon
                      >
                    </ng-container>
                    <ng-template #normal>
                      {{ product[header.DataField] }}
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </ng-template>
        <ng-template
          kendoPagerTemplate
          let-totalPages="totalPages"
          let-currentPage="currentPage"
        >
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-numeric-buttons
            [buttonCount]="buttonCount"
          ></kendo-pager-numeric-buttons>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-pager-input></kendo-pager-input>
          <kendo-pager-info></kendo-pager-info>
          <kendo-pager-page-sizes
            [pageSizes]="pageSizeOptions"
          ></kendo-pager-page-sizes>
        </ng-template>
        <kendo-grid-excel></kendo-grid-excel>
      </kendo-grid>
    }
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
