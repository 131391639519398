import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { uniq, remove } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuleStatusService {
  private environment;
  private org: string;

  appStatuses = signal({
    complete: [],
    inProgress: [],
  });
  apps = signal([]);
  apps$ = toObservable(this.apps);
  showCaseSummary = signal(false);
  hasAwComparisonPage = signal(false);
  availableApps = signal([]);

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.org = this.environment.org;
    this.availableApps.set(this.ss.get('availableApps'));
  }

  getSession() {
    return this.rbs.getSession();
  }

//
// Quick and Dirty refactor to get left nav working
// Sorry for butchering your code Shawn
//

  getApps(profile): any {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    return this.rbs
    .getRequest(
      `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=ModuleStatus&saveProfile=false`,
        reqHeaders
      )
        
  }

  processApps(resp) {
    const appStatusTemporary = {
    complete: [],
    inProgress: [],
  };
  let appsList;
  this.appStatuses().complete = [];
  this.appStatuses().inProgress = [];

  resp.Modules.map(module => {
    if (module.vars.ModuleStatus?.FlowStatus === 'C') {
      appStatusTemporary.complete.push(module.name.toLowerCase());
      remove(this.appStatuses().inProgress, app => {
        return app === module.name.toLowerCase();
      });
      this.appStatuses().complete = uniq(appStatusTemporary.complete);
    }
    if (module.vars.ModuleStatus?.FlowStatus === 'O' || module.vars.ModuleStatus?.FlowStatus === 'OR') {
      appStatusTemporary.inProgress.push(module.name.toLowerCase());
      remove(this.appStatuses().complete, app => {
        return app === module.name.toLowerCase();
      });
      this.appStatuses().inProgress = uniq(appStatusTemporary.inProgress);
    }

    if (
      module.vars.ModuleStatus?.ShowInSidebarNav && (appsList && appsList.includes(module.name.toLowerCase()))
    ) {
      appsList.push(module.name.toLowerCase());
    }

    if (module.name === 'SummaryPage') {
      this.showCaseSummary.set(true);
    }
    if (
      resp.Modules.find(z => z.name === 'AW') &&
      resp.Modules.find(z => z.name === 'AW').vars.ModuleStatus
        .EJComparisonPage
    ) {
      this.hasAwComparisonPage.set(true);
    }
  });

  this.apps.set(appsList);
}
}
