import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { Context } from '../../models/admin-models';
import {
  ActivatedRoute,
  Router,
  Event as NavigationEvent,
  NavigationStart,
} from '@angular/router';
import { Location } from '@angular/common';
import { SessionStorageService } from '../../services/session-storage.service';
import { ModuleStatusService } from '../../services/module-status.service';
import { FormlyComponent } from '../../conditional-form/formly.component';
import { UnifiedFlowService } from '../unified.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-unified-case',
  templateUrl: './unified-case.component.html',
  styleUrls: ['./unified-case.component.scss'],
})
export class UnifiedCaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FormlyComponent, { static: false })
  private questionnaire: FormlyComponent;
  isNaN: Function = Number.isNaN;

  tabContext: Context = { type: 'unified', unit: null, app: null };
  profileData;
  loading = false;
  save = 'og';
  steps = [];
  tabChange;
  state;
  currentApp = 'rb';
  profile;
  resultsPage = { app: null, visible: false };
  availableApps = [];
  currentStep: any = 0;
  formModel = {};
  dataLoaded = false;
  liValidate = false;
  iwValidate = false;
  availableAppStatus = {
    clientinfo: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    aw: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    pp: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    iw: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    li: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
  };
  rights = [];
  notesValid = true;
  navDisabled = false;
  newProfileObs;
  prodsSelectedPP = false;
  progSelectedIW = null;
  stepsObs;
  summaryClient = '';
  formValid = true;
  downloadingFactsheet = false;
  hasSummaryModule = false;
  hasAwComparisonPage = false;
  unsubscribe: Subject<any> = new Subject();
  formSubmitted = false;
  initialErrorsList = [];
  noSaveProfile = false;
  modules = [];
  clientInfoNotReq = false;

  constructor(
    private rbs: RightBridgeApiService,
    private route: ActivatedRoute,
    public unfFlowSvc: UnifiedFlowService,
    private location: Location,
    private ss: SessionStorageService,
    public modStatusSvc: ModuleStatusService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.rights = this.ss.get('rights');
    this.noSaveProfile = this.rights?.includes('ReadOnly');

    router.events
      .pipe(
        takeUntil(this.unsubscribe),
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((ev: NavigationEvent) => {
        if (ev instanceof NavigationStart) {
          const eventUrl = ev.url.split('/');
          const urlApp = eventUrl[2] ? eventUrl[2].toLowerCase() : '';
          const validApp =
            urlApp == 'aw' ||
            urlApp == 'pp' ||
            urlApp == 'rb' ||
            urlApp == 'li' ||
            urlApp == 'iw';

          if (
            this.currentApp === 'li' &&
            (this.currentStep === 'validate' ||
              this.currentStep === 'products' ||
              this.currentStep === 'compare' ||
              this.currentStep === 'results') &&
            eventUrl[eventUrl.length - 1] === '0' &&
            ev.navigationTrigger === 'popstate'
          ) {
            this.getQuestionnaire();
          } else if (validApp && urlApp != this.currentApp) {
            // this.handleAppChange({app: urlApp});
            this.currentApp = urlApp;
            this.getQuestionnaire();
          }
        }
      });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.profile = params.id;
      if (params.app) {
        if (this.currentApp != params.app) {
          this.currentApp = params.app;
          // this.getQuestionnaire();
        }
      }
      this.currentApp = params.app ? params.app : 'rb';
      this.currentStep = params.step ? params.step : this.currentStep;
      this.ss.set('currentStep', this.currentStep);
      this.ss.set('currentApp', this.currentApp);
    });

    this.setModuleStatus();

    this.availableApps = this.ss.get('availableApps');

    if (!this.availableApps || typeof this.availableApps == 'undefined') {
      this.availableApps = [];

      if (this.rights?.includes('PP')) {
        this.availableApps.push('pp');
        this.availableApps.push('clientinfo');
      }

      if (this.rights?.includes('AW')) {
        this.availableApps.push('aw');
      }

      if (this.rights?.includes('IW')) {
        this.availableApps.push('iw');
      }

      if (this.rights?.includes('LI')) {
        this.availableApps.push('li');
      }

      this.ss.set('availableApps', this.availableApps);
    }

    this.getQuestionnaire();
    this.ss.set('currentStep', this.currentStep);
  }

  ngAfterViewInit() {
    if (!this.newProfileObs) {
      this.newProfileObs = this.unfFlowSvc.newProfileExternal;
      this.newProfileObs.subscribe(x => this.handleNewProfile(x));
    }

    if (!this.stepsObs) {
      this.stepsObs = this.unfFlowSvc.stepsListExternal;
      this.stepsObs.pipe(debounceTime(50)).subscribe(x => {
        this.steps = x;
      });

      if (!this.steps.length && this.currentStep === 'compare-results') {
        this.getQuestionnaire();
      }
    }

    if (!this.steps.length && this.currentStep === 'compare-results') {
      this.getQuestionnaire();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setModuleStatus() {
    this.availableAppStatus = {
      clientinfo: {
        available: this.currentApp == 'rb',
        required: false,
        ShowInSidebarNav: false,
      },
      aw: {
        available: this.currentApp == 'aw',
        required: false,
        ShowInSidebarNav: false,
      },
      pp: {
        available: this.currentApp == 'pp',
        required: false,
        ShowInSidebarNav: false,
      },
      iw: {
        available: this.currentApp == 'iw',
        required: false,
        ShowInSidebarNav: false,
      },
      li: {
        available: this.currentApp == 'li',
        required: false,
        ShowInSidebarNav: false,
      },
    };

    if (this.profile) {
      //
      // Quick and Dirty refactor to get left nav working
      // Sorry for butchering your code Shawn
      //
      // TODO: fully integrate Signals here!
      this.modStatusSvc.getApps(this.profile).subscribe(x => {
        this.modStatusSvc.processApps(x);
        this.availableApps.forEach(app => {
          const working = x.Modules.find(z => z.name.toLowerCase() == app);
          if (working) {
            this.availableAppStatus[app].required =
              working.vars.ModuleStatus.FlowStatus == 'R' ||
              working.vars.ModuleStatus.FlowStatus == 'OR' ||
              working.vars.ModuleStatus.Required;
            this.availableAppStatus[app].available =
              working.vars.ModuleStatus.FlowStatus == 'R' ||
              working.vars.ModuleStatus.FlowStatus == 'OR' ||
              working.vars.ModuleStatus.FlowStatus == 'X' ||
              working.vars.ModuleStatus.FlowStatus == 'C' ||
              this.currentApp == app;
            this.availableAppStatus[app].ShowInSidebarNav =
              working.vars.ModuleStatus.ShowInSidebarNav;
          }
        });

        this.ss.set('appStatus', this.availableAppStatus);
        if (
          x.Modules.find(z => z.name === 'AW') &&
          x.Modules.find(z => z.name === 'AW').vars.ModuleStatus
            .EJComparisonPage
        ) {
          this.hasAwComparisonPage = true;
        }
        if (x.Modules.find(z => z.name === 'SummaryPage')) {
          this.hasSummaryModule = true;
        }
        if (x.UnitInfo.ClientInfoPageNotRequired) {
          this.clientInfoNotReq = true;
        }
      });
    } else {
      this.modStatusSvc.getApps(this.profile).subscribe(x => {
        this.modStatusSvc.processApps(x);
      });
           this.ss.set('appStatus', this.availableAppStatus);
    }
  }

  getQuestionnaire() {
    const existing = this.ss.get('noSave');

    this.setLoading(true);
    // this.profileData = null;
    if (!this.unfFlowSvc.getSaving()) {
      if (
        this.noSaveProfile &&
        this.currentApp != 'summary' &&
        existing?.profile
      ) {
        const data = JSON.parse(
          JSON.stringify(this.ss.get('noSave').questionnaire)
        );
        this.profileData = data;
        this.setLoading(false);
      } else {
        if (this.currentApp != 'summary') {
          this.rbs
            .getQuestions(this.profile, this.currentApp)
            .subscribe(data => {
              this.profileData = data.results.questionnaire;
              if (this.currentStep == 0) {
                this.unfFlowSvc.setStatusCheck();
              }
              setTimeout(() => {
                this.setLoading(false);
              }, 500);
              this.navDisabled = false;
            });
        }
      }
    } else {
      setTimeout(() => {
        this.getQuestionnaire();
      }, 250);
    }
  }

  handleTabChange(ev, fromNav = false) {
    const random = Math.floor(Math.random() * 10);
    const save = this.unfFlowSvc.getSaving();
    setTimeout(() => {
      if (save && fromNav) {
        setTimeout(() => {
          this.handleTabChange(ev, fromNav);
        }, 250);
      } else {
        let currentAppIdx = 0;
        if (
          this.isNaN(+ev) &&
          ev != 'next' &&
          ev != 'back' &&
          !ev.hasOwnProperty('index')
        ) {
          if (this.navGuard(true, fromNav)) {
            this.currentStep = ev;
            this.router.navigate([
              `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
            ]);
          }
          this.navDisabled = false;
        } else if (
          this.currentApp != 'rb' &&
          this.isNaN(+this.currentStep) &&
          !this.isNaN(+ev.index)
        ) {
          this.setLoading(true);
          if (this.navGuard(true, fromNav)) {
            this.getQuestionnaire();
            this.currentStep = ev.index;
            this.unfFlowSvc.setSaving(false);
            this.router.navigate([
              `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
            ]);
          }
        } else if (ev === 'next') {
          if (this.isNaN(+this.currentStep)) {
            if (
              this.navGuard(true, fromNav, !fromNav) &&
              this.unfFlowSvc.getNoteValidity(this.currentApp)
            ) {
              currentAppIdx =
                this.currentApp == 'rb'
                  ? -1
                  : this.availableApps.indexOf(this.currentApp);
              const option = this.navOption();
              if (option && option != 'appChange') {
                this.router.navigate([option]);
              }
              this.navDisabled = false;
            } else if (
              !this.unfFlowSvc.getNoteValidity(this.currentApp) &&
              !this.rights.includes('BridgeNotes') &&
              this.navGuard(true, fromNav, !fromNav)
            ) {
              currentAppIdx =
                this.currentApp == 'rb'
                  ? -1
                  : this.availableApps.indexOf(this.currentApp);
              const option = this.navOption();
              if (option && option != 'appChange') {
                this.router.navigate([option]);
              }
              this.navDisabled = false;
            }
            this.navDisabled = false;
          } else if (
            this.currentApp == 'rb' &&
            this.steps.length == +this.currentStep + 1
          ) {
            this.unfFlowSvc.setTriggerValidate({ validate: this.currentStep });
            setTimeout(() => {
              if (this.navGuard(true, fromNav)) {
                currentAppIdx =
                  this.currentApp == 'rb'
                    ? -1
                    : this.availableApps.indexOf(this.currentApp);

                if (!this.profile) {
                  this.unfFlowSvc.setTriggerSave({
                    msg: 'new-profile',
                    id: this.profile,
                  });
                } else if (this.profile) {
                  if (!this.isNaN(+this.currentStep)) {
                    this.unfFlowSvc.setTriggerSave({
                      msg: 'unifiedTabSave-' + currentAppIdx,
                      id: this.profile,
                    });
                  }
                  
                  setTimeout(() => {
                    this.unfFlowSvc.getNextApp(this.profile).subscribe(data => {
                      let nextApp;
                      if (data.NextModule && data.NextModule.Module) {
                        nextApp = this.availableApps.indexOf(
                          data.NextModule.Module.toLowerCase()
                        );
                      }

                      this.handleAppChange({
                        app: this.availableApps[nextApp === -1 ? 0 : nextApp],
                      });
                    });
                  }, 250);
                }
                this.navDisabled = false;
              }
            }, 250);
          } else if (
            this.currentApp != 'rb' &&
            this.steps.length == +this.currentStep + 1
          ) {
            this.ss.set('currentStep', 9999);
            this.setLoading(true);

            this.unfFlowSvc.setTriggerValidate({ validate: this.currentStep });

            setTimeout(() => {
              if (this.navGuard(false, false, !fromNav)) {
                if (!this.profile) {
                  this.unfFlowSvc.setTriggerSave({
                    msg: 'new-profile',
                    id: this.profile,
                  });
                } else if (this.profile) {
                  this.unfFlowSvc.setTriggerSave({
                    msg: 'unifiedTabSave-' + random,
                    id: this.profile,
                  });
                  let url;
                  setTimeout(() => {
                    url = this.navOption();
                    this.router.navigate([url]);
                  }, 500);
                }
              }
              // I commented this line as a hack. We need to refactor
              // the timeouts in the navigation of the formly/unified stuff
              this.setLoading(false);
              this.navDisabled = false;
            }, 500);
          } else {
            this.setLoading(true);
            const existing = this.profile && this.profile.length > 0;
            const saveVal = existing
              ? `unifiedTabSave-${this.currentApp}-${this.currentStep}`
              : 'new-profile';
            this.unfFlowSvc.setTriggerSave({ msg: saveVal, id: this.profile });
            if (this.navGuard(true, fromNav, !fromNav)) {
              this.currentStep = +this.currentStep + 1;
              if (existing) {
                this.router.navigate([
                  `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
                ]);
              }
              this.ss.set('currentStep', this.currentStep);
            }
            this.setLoading(false);
          }
          this.navDisabled = false;
        } else if (ev === 'back') {
          if (
            // this.currentApp !== 'iw' &&
            this.currentStep !== 'validate' &&
            this.currentApp !== 'li' &&
            this.currentStep !== 'products'
          ) {
            this.unfFlowSvc.setTriggerSave({
              msg: 'unifiedTabSave-' + this.currentStep,
              id: this.profile,
            });
          }
          if (this.currentApp === 'iw' && this.currentStep === 'validate') {
            this.unfFlowSvc.setNoteValidity({
              app: this.currentApp,
              valid: true,
            });
          }
          const timer =
            !this.isNaN(+this.profile) && this.profile.length > 7 ? 4000 : 0;
          setTimeout(() => {
            this.location.back();
            this.getQuestionnaire();
          }, timer);
        } else if (fromNav && !this.isNaN(ev.index)) {
          this.unfFlowSvc.setTriggerSave({
            msg: 'unifiedTabSave-' + this.currentStep,
            id: this.profile,
          });
          this.currentStep = ev.index;
          this.router.navigate([
            `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
          ]);
          this.ss.set('currentStep', this.currentStep);
          this.navDisabled = false;
        } else {
          if (this.navGuard(true, fromNav)) {
            this.unfFlowSvc.setTriggerSave({
              msg: 'unifiedTabSave-' + this.currentStep,
              id: this.profile,
            });
            // if(!this.isNaN(+ev.index)) {
            // this.getQuestionnaire();
            // }
            this.currentStep = ev.index;
            this.router.navigate([
              `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
            ]);
            this.ss.set('currentStep', this.currentStep);
            this.navDisabled = false;
          }
        }
        this.navDisabled = false;
      }
    }, 250);
  }

  navGuard(stepNum = true, externalClick?, next = false, forceTrue = false) {
    const steps = this.unfFlowSvc.getSteps();
    const notesActive =
      this.rights.includes('BridgeNotes') &&
      this.isNaN(+this.currentStep) &&
      (this.currentStep == 'validate' ||
        this.currentStep == 'results' ||
        this.currentStep == 'products');
    const saveActive = this.unfFlowSvc.getSaving();
    this.notesValid = this.unfFlowSvc.getNoteValidity(this.currentApp);

    if (forceTrue) {
      return true;
    } else if (!stepNum && !this.formValid) {
      this.formSubmitted = true;
      let navDialog = this.dialog.open(WarnDialogComponent, {
        minWidth: '35vw',
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content: 'Please Fix Questionnaire Errors to Continue',
          confirm: 'OK',
          hideCancel: true,
        },
      });
      navDialog.afterClosed().subscribe(() => {
        this.setLoading(false);
        navDialog = null;
      });
    } else if (
      !this.formValid &&
      steps.length - 1 == this.currentStep &&
      !steps[0].valid &&
      !this.clientInfoNotReq
    ) {
      this.formSubmitted = true;
      let navDialog = this.dialog.open(WarnDialogComponent, {
        minWidth: '35vw',
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content: 'Please Fix Questionnaire Errors to Continue',
          confirm: 'OK',
          hideCancel: true,
        },
      });
      navDialog.afterClosed().subscribe(() => {
        this.setLoading(false);
        navDialog = null;
      });
    } else if (
      this.currentApp == 'rb' &&
      !this.formValid &&
      this.currentStep !== '0' &&
      !this.clientInfoNotReq
    ) {
      this.formSubmitted = true;
      let navDialog = this.dialog.open(WarnDialogComponent, {
        minWidth: '35vw',
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content: 'Please Fix Questionnaire Errors to Continue',
          confirm: 'OK',
          hideCancel: true,
        },
      });
      navDialog.afterClosed().subscribe(() => {
        this.setLoading(false);
        navDialog = null;
      });
    } else if (
      !this.prodsSelectedPP &&
      this.currentApp == 'pp' &&
      this.currentStep == 'results' &&
      next
    ) {
      let navDialog = this.dialog.open(WarnDialogComponent, {
        minWidth: '35vw',
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content: 'Please Select a Product to Continue',
          confirm: 'OK',
          hideCancel: true,
        },
      });
      navDialog.afterClosed().subscribe(() => {
        this.setLoading(false);
        navDialog = null;
      });
    } else if (
      !this.progSelectedIW &&
      this.currentApp == 'iw' &&
      this.currentStep == 'program-selection' &&
      (next || !stepNum)
    ) {
      let navDialog = this.dialog.open(WarnDialogComponent, {
        minWidth: '35vw',
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content: 'Please Select a Program to Continue',
          confirm: 'OK',
          hideCancel: true,
        },
      });

      navDialog.afterClosed().subscribe(() => {
        this.setLoading(false);
        navDialog = null;
      });
    } else {
      if (!saveActive || this.unfFlowSvc.getloading()) {
        if (notesActive) {
          if (this.notesValid || externalClick) {
            return true;
          } else {
            this.handleNotesError();
            return false;
          }
        } else {
          return true;
        }
      } else {
        setTimeout(() => {
          this.navGuard();
        }, 250);
      }
    }
  }

  handleAppChange(ev, fromNav = false) {
    this.setModuleStatus();
    const save = this.unfFlowSvc.getSaving();
    if (save) {
      setTimeout(() => {
        this.handleAppChange(ev, fromNav);
      }, 250);
    } else {

      let nextApp = ev.app;
      this.unfFlowSvc.getNextApp(this.profile).subscribe(x => {
        nextApp = x.NextModule.Module != ev.app && !fromNav ? x.NextModule.Module.toLowerCase() : ev.app;
      });

      if (
        this.currentApp != 'pp' &&
        this.currentApp != 'summary' &&
        nextApp != 'summary' &&
        this.currentStep != 'validate' &&
        this.currentStep != 'results' 
        // &&
        // this.currentApp !== 'rb'
      ) {
        this.unfFlowSvc.setTriggerValidate({ validate: this.currentStep });
        this.unfFlowSvc.setTriggerSave({
          msg: 'appChangeSave-' + nextApp,
          id: this.profile,
        });
      }
      if (this.currentApp == 'rb') {
        this.unfFlowSvc.setTriggerValidate({ validate: this.currentStep });
        // If we are leaving the Client Info page then double check the app statuses
        this.modStatusSvc.getApps(this.profile).subscribe(x => {
          this.modStatusSvc.processApps(x);
        });
      }

      this.unfFlowSvc.setNoteValidity({ app: this.currentApp, valid: true });
      this.steps = [];
      const timer =
        this.currentApp === 'rb' && fromNav && nextApp === 'pp' ? 2500 : 1000;

      setTimeout(() => {
        if (fromNav) {
          this.unfFlowSvc.setSaving(save);
        }
        if (this.navGuard(true, true, false, !fromNav)) {
          if (this.currentApp != nextApp) {
            this.currentApp = nextApp;
            this.currentStep = nextApp == 'summary' ? 'summary' : 0;
            // this.getQuestionnaire();
          } else {
            this.currentStep =
              nextApp == 'summary' || this.currentApp == 'summary'
                ? 'summary'
                : this.currentStep;
          }

          if (nextApp == 'summary') {
            const nestedTimer =
              !this.isNaN(+this.profile) && this.profile.length > 7 ? 3000 : 0;
            setTimeout(() => {
              this.router.navigate([`/case/summary/${this.profile}`]);
            }, nestedTimer);
          } else {
            setTimeout(() => {
              this.getQuestionnaire();
              this.router.navigate([
                `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
              ]);
            }, 250);
          }

          this.ss.set('currentApp', this.currentApp);
          this.ss.set('currentStep', this.currentStep);
        }
      }, timer);
    }
  }

  handleAppPage(ev, noSave = false) {
    this.unfFlowSvc.setTriggerValidate({ validate: this.currentStep });
    if (!noSave) {
      const random = Math.floor(Math.random() * 10);
      this.unfFlowSvc.setTriggerSave({
        msg: 'appPageSave-' + random,
        id: this.profile,
      });
      // let last = this.steps.length - 1;
      // this.ss.set('currentStep',last);
    }

    const save = this.unfFlowSvc.getSaving();

    if (save) {
      setTimeout(() => {
        this.handleAppPage(ev, true);
      }, 250);
    } else {
      setTimeout(() => {
        if (this.navGuard(false, true)) {
          this.currentStep = ev.tab;
          this.router.navigate([
            `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
          ]);
        }
      });
    }
  }

  handleNewProfile(ev) {
    this.profile = ev;
    let app = this.currentApp;
    let step = 0;

    if (this.currentApp == 'rb') {
      const currentAppIdx =
        this.currentApp == 'rb'
          ? -1
          : this.availableApps.indexOf(this.currentApp);
      app = this.availableApps[+currentAppIdx + 1];
    } else {
      step++;
    }

    this.router.navigate([`/case/${app}/${this.profile}/${step}`]);
  }

  processErrors(field) {
    if (Object.getPrototypeOf(field).constructor.name == 'FormGroup') {
      Object.entries(field.controls).forEach(fld => {
        if (fld[1] instanceof FormArray) {
          const fldList = fld[1] as FormArray;
          fldList.controls.forEach(subFld => {
            this.processErrors(subFld);
          });
        } else if (
          (fld[1]['errors'] || fld[1]['status'] === 'INVALID') &&
          fld[1]['touched']
        ) {
          this.initialErrorsList.push(fld[1]['errors']);
        }
      });
    } else if (Object.getPrototypeOf(field).constructor.name == 'FormArray') {
      field.controls.forEach(fld => {
        this.processErrors(fld);
      });
    }
  }

  handleModelChange(ev) {
    this.initialErrorsList = [];
    if (ev.form.errors && ev.form.errors.length > 0) {
        this.initialErrorsList = [...this.initialErrorsList, ...ev.form.errors];
    }

    Object.entries(ev.form.controls).map(field => {
      if (
        field[0] === 'PurposeRankOrder_OrderedSelection' &&
        field[1]['errors'] &&
        field[1]['touched']
      ) {
        this.initialErrorsList.push(field[1]['errors']);
      } else if (
        Object.getPrototypeOf(field[1]).constructor.name == 'FormControl'
      ) {
        if (field[1]['errors'] && field[1]['touched']) {
          this.initialErrorsList.push(field[1]['errors']);
        }
      } else if (
        Object.getPrototypeOf(field[1]).constructor.name == 'FormArray'
      ) {
        field[1]['controls'].forEach(fld => {
          this.processErrors(fld);
        });
      } else if (
        Object.getPrototypeOf(field[1]).constructor.name == 'FormGroup'
      ) {
        if (field[1]['errors'] && field[1]['touched']) {
          this.initialErrorsList.push(field[1]['errors']);
        }
        Object.entries(field[1]['controls']).forEach(fld => {
          if (fld[1]['errors'] && fld[1]['touched']) {
            this.initialErrorsList.push(fld[1]['errors']);
          }
        });
      }
    });
    const errorsList = this.initialErrorsList.filter(x => {
      return x && x != undefined;
    });

    const optOut = ev.model.regSkipped;
    const useOptOut =
      (typeof ev.model.UnitRegulation_UseOptOut == 'string' &&
        ev.model.UnitRegulation_UseOptOut == 'Y') ||
      (typeof ev.model.UnitRegulation_UseOptOut == 'boolean' &&
        ev.model.UnitRegulation_UseOptOut);
    // let regState = ev.model.UnitRegulationState_States ? ev.model.UnitRegulationState_States.includes(ev.model.LifeWizard_StateOfIssue) || ev.model.UnitRegulationState_States.includes(ev.model.AnnuityWizard_StateOfIssue) : false;
    // { regRequired: true }

    const regRequiredError = errorsList.some(x => x.regRequired);
    const requiredError = errorsList.some(x => x.required);

    if (optOut && useOptOut && regRequiredError) {
      this.formValid = !requiredError;
    } else if (optOut && useOptOut && !regRequiredError && !requiredError) {
      this.formValid = true;
    } else {
      this.formValid = errorsList.length < 1;
    }

    this.formModel = ev.model;

    if (
      ev.model['LifeWizard_AssessmentType'] &&
      ev.model['LifeWizard_AssessmentType'] == 2
    ) {
      this.liValidate = true;
    } else if (
      ev.model['LifeWizard_AssessmentType'] &&
      ev.model['LifeWizard_AssessmentType'] != 2
    ) {
      this.liValidate = false;
    }

    if (
      ev.model.ValidateProduct &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] == 2
    ) {
      this.iwValidate = true;
    } else if (
      ev.model.ValidateProduct &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] != 2
    ) {
      this.iwValidate = false;
    }
  }

  currentStepDisplay() {
    if (this.currentApp === 'summary') {
      return this.summaryClient;
    }
    if (!this.isNaN(+this.currentStep)) {
      return this.steps[this.currentStep]
        ? this.steps[this.currentStep].label
        : null;
    } else {
      let title;
      switch (this.currentStep) {
        case 'investment-model':
          title = 'Investment Model';
          break;
        case 'program-selection':
          title = 'Program Selection';
          break;
        case 'fund-allocations':
          title = 'Fund Allocations';
          break;
        case 'validate':
          if (this.currentApp == 'iw') {
            title = 'Validate Portfolio';
          } else {
            title = 'Validate Products';
          }
          break;
        case 'advisory':
          title = 'Advisory';
          break;
        case 'compare-results':
          title = 'Income Comparison';
          break;
        case 'results':
          if (this.currentApp == 'li') {
            title = 'Calculated Needs Results';
          } else {
            title = 'Results';
          }
          break;
        case 'needs':
          title = 'Calculated Needs Results';
          break;
        case 'compare':
          title = 'Compare Scenarios';
          break;
        case 'products':
          title = 'Product Considerations';
          break;
      }
      return title;
    }
  }

  currentAppDisplay() {
    switch (this.currentApp) {
      case 'pp':
        return 'Product Profiler';
      case 'aw':
        return 'Annuity Wizard';
      case 'iw':
        return 'Investment Wizard';
      case 'li':
        return 'Life Wizard';
      case 'rb':
        return 'Client Information';
      case 'summary':
        return 'Case Summary';
    }
  }

  navOption(returnStepName = false) {
    let nextApp;
    switch (this.currentApp) {
      case 'pp':
        if (this.currentStep == 'results') {
          this.unfFlowSvc.getNextApp(this.profile).subscribe(x => {
            nextApp = x.NextModule.Module.toLowerCase();
            if (this.currentApp == nextApp) {
              this.handleAppChange({ app: 'summary' });
            } else {
              this.handleAppChange({ app: nextApp });
            }
            return 'appChange';
          });
        } else {
          this.setLoading(true);
          this.currentStep = 'results';
          return returnStepName ? 'results' : `case/pp/${this.profile}/results`;
        }
        break;
      case 'aw':
        if (this.currentStep == 'results') {
          this.unfFlowSvc.getNextApp(this.profile).subscribe(x => {
            nextApp = x.NextModule.Module.toLowerCase();
            if (this.currentApp == nextApp) {
              this.handleAppChange({ app: 'summary' });
            } else {
              this.handleAppChange({ app: nextApp });
            }
            return 'appChange';
          });
        } else if (
          this.currentStep * 1 === this.steps.length - 1 &&
          this.hasAwComparisonPage
        ) {
          this.currentStep = 'compare-results';
          return returnStepName
            ? 'compare-results'
            : `case/aw/${this.profile}/compare-results`;
        } else {
          if (this.noSaveProfile) {
            // setTimeout(_ => {
            this.currentStep = 'results';
            return returnStepName
              ? 'results'
              : `case/aw/${this.profile}/results`;
            // }, 500);
          } else {
            this.currentStep = 'results';
            return returnStepName
              ? 'results'
              : `case/aw/${this.profile}/results`;
          }
        }
        break;
      case 'iw':
        if (this.currentStep == 'program-selection') {
          if (this.progSelectedIW.selectionType.toLowerCase() == 'advisory') {
            this.currentStep = 'validate';
            return returnStepName
              ? 'validate'
              : `case/iw/${this.profile}/validate`;
          } else {
            this.currentStep = 'investment-model';
            return returnStepName
              ? 'investment-model'
              : `case/iw/${this.profile}/investment-model`;
          }
        } else if (this.currentStep == 'investment-model') {
          this.currentStep = 'fund-allocations';
          return returnStepName
            ? 'fund-allocations'
            : `case/iw/${this.profile}/fund-allocations`;
        } else if (this.currentStep == 'fund-allocations') {
          this.currentStep = 'validate';
          return returnStepName
            ? 'validate'
            : `case/iw/${this.profile}/validate`;
        } else if (
          this.currentStep == 'advisory' ||
          this.currentStep == 'validate'
        ) {
          this.unfFlowSvc.getNextApp(this.profile).subscribe(x => {
            nextApp = x.NextModule.Module.toLowerCase();
            if (this.currentApp == nextApp) {
              this.handleAppChange({ app: 'summary' });
            } else {
              this.handleAppChange({ app: nextApp });
            }
            return 'appChange';
          });
        } else if (
          this.formModel['ValidateProduct'][
            'InvestmentWizard_AssessmentType'
          ] == 2
        ) {
          this.currentStep = 'validate';
          return returnStepName
            ? 'validate'
            : `case/iw/${this.profile}/validate`;
        } else {
          this.currentStep = 'program-selection';
          return returnStepName
            ? 'program-selection'
            : `case/iw/${this.profile}/program-selection`;
        }
        break;
      case 'li':
        const gotoAnalysis = this.formModel[
          'UnitNeeds_PurposesUsingCalc'
        ]?.includes(this.formModel['LifeWizard_InsurancePurpose']);
        const gotoStrategies = !this.formModel[
          'UnitNeeds_PurposesUsingCalc'
        ]?.includes(this.formModel['LifeWizard_InsurancePurpose']);

        if (this.currentStep == 'products' || this.currentStep == 'validate') {
          this.unfFlowSvc.getNextApp(this.profile).subscribe(x => {
            nextApp = x.NextModule.Module.toLowerCase();
            if (this.currentApp == nextApp) {
              this.handleAppChange({ app: 'summary' });
            } else {
              this.handleAppChange({ app: nextApp });
            }
            return 'appChange';
          });
        } else if (
          (this.formModel['LifeWizard_AssessmentType'] == 2 &&
            this.formModel['UnitRegulationState_States'].includes(
              this.formModel['LifeWizard_StateOfIssue']
            )) ||
          this.formModel['LifeWizard_PurchaseType'] == 2
        ) {
          this.currentStep = 'validate';
          return returnStepName
            ? 'validate'
            : `case/li/${this.profile}/validate`;
        } else if (this.currentStep == 'compare') {
          this.currentStep = 'products';
          return returnStepName
            ? 'products'
            : `case/li/${this.profile}/products`;
        } else if (this.currentStep == 'results') {
          this.currentStep = 'compare';
          return returnStepName ? 'compare' : `case/li/${this.profile}/compare`;
        } else if (gotoStrategies) {
          this.currentStep = 'compare';
          return returnStepName ? 'compare' : `case/li/${this.profile}/compare`;
        } else if (gotoAnalysis) {
          this.currentStep = 'results';
          return returnStepName ? 'results' : `case/li/${this.profile}/results`;
        } else {
          this.currentStep = 'results';
          return returnStepName ? 'results' : `case/li/${this.profile}/results`;
        }
    }
  }

  hideForm() {
    if (
      this.unfFlowSvc.getloading() ||
      this.isNaN(+this.currentStep) ||
      this.currentApp == 'summary'
    ) {
      return true;
    }
  }

  checkStatus() {
    return (
      (this.currentApp != 'summary' && !this.isNaN(+this.currentStep)) ||
      this.currentApp != 'compare-results'
    );
  }

  handleNotesError() {
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      minWidth: '60vw',
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content:
          '<div class="warn-text">Please enter rationale on all required items (as indicated by the "Notes Required for This Item" flag).<br/>Each required note needs to be filled out and <u>saved</u> in order to continue.</div>',
        confirm: 'OK',
        hideCancel: true,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      dialogRef = null;
    });
  }

  handlePPProds(ev) {
    this.prodsSelectedPP = ev;
  }

  handleIWProg(ev) {
    this.progSelectedIW = ev;
  }

  handleSummaryNavigate(ev) {
    if (ev.app == 'back') {
      const timer =
        !this.isNaN(+this.profile) && this.profile.length > 7 ? 3000 : 0;
      setTimeout(_ => {
        this.location.back();
      }, timer);
    } else {
      this.currentApp = ev.app;
      this.currentStep = ev.step;

      this.router.navigate([
        `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
      ]);
      this.getQuestionnaire();
    }
  }

  setSummaryClient(e) {
    if (e) {
      const clientName =
        e.Client.Client.FinalName ||
        e.Client.ClientPerson.FirstName + ' ' + e.Client.ClientPerson.LastName;
      this.summaryClient = clientName.replace('undefined', '');
    }
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }

  downloadFactsheet(e) {
    this.downloadingFactsheet = e;
  }

  saveInProgress() {
    const random = Math.floor(Math.random() * 10);
    this.unfFlowSvc.setTriggerSave({
      msg: 'inProgressSave-' + random,
      id: this.profile,
    });
  }
}
