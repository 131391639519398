import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundFeeDialogComponent } from './fund-fee-dialog/fund-fee-dialog.component';
import { FundFeeHandlerComponent } from './fund-fee-handler/fund-fee-handler.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GridModule } from '@progress/kendo-angular-grid';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { FundFeeLookupService } from './fund-fee-lookup.service';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { IMaskModule } from 'angular-imask';

@NgModule({
  declarations: [FundFeeDialogComponent, FundFeeHandlerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    GridModule,
    MatAutocompleteModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    MatTooltipModule,
    MatDialogModule,
    IMaskModule,
  ],
  exports: [FundFeeHandlerComponent],
  providers: [FundFeeLookupService],
})
export class FundFeeLookupModule {}
