import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModelSelectionComponent } from './model-selection/model-selection.component';
import { ProgramSelectionComponent } from './program-selection/program-selection.component';
import { FundAllocationsComponent } from './fund-allocations/fund-allocations.component';
import { SearchComponent } from '../search/search.component';
import { ProxyComponent } from '../proxy/proxy.component';
import { AuthService } from '../services/auth-service.service';
import { AppGlobalsService } from '../services/app-globals.service';
import { FormlyComponent } from '../conditional-form/formly.component';
import { TemplatesComponent } from '../account-management/templates/templates.component';
import { HelpComponent } from '../shared-components/help/help.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ValidateResultComponent } from './validate-result/validate-result.component';
import { ProductGridComponent } from './product-grid/product-grid.component';

const routes: Routes = [
  {
    path: 'iw',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        redirectTo: '/unified',
        pathMatch: 'full',
        data: {
          breadcrumb: 'Landing',
        },
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'lookup',
        component: ProductSearchComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Product Look Up',
        },
      },
      {
        path: 'questionnaire',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'questionnaire/:id',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'investment-model/:id',
        component: ModelSelectionComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Investment Model Selection',
        },
      },
      {
        path: 'program-selection/:id',
        component: ProgramSelectionComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Program Selection',
        },
      },
      {
        path: 'fund-allocations/:id',
        component: FundAllocationsComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'select Funds',
        },
      },
      {
        path: 'validate/:id',
        component: ValidateResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'select Funds',
        },
      },
      {
        path: 'advisory/:id',
        component: FundAllocationsComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'select Funds',
        },
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthService],
      },
      {
        path: 'proxy',
        component: ProxyComponent,
        canActivate: [AuthService],
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AuthService],
      },
      {
        path: 'product-shelf',
        component: ProductGridComponent,
        canActivate: [AuthService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IwRouting {}
