
import {map,  switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UniversalSearchService } from '../services/universal-search.service';
import { SessionStorageService }  from '../../services/session-storage.service'

@Component({
  selector: 'app-hierarchy-modal',
  templateUrl: './hierarchy-modal.component.html',
  styleUrls: ['./hierarchy-modal.component.scss']
})
export class HierarchyModalComponent implements OnInit {
  // @ViewChild('userCtrl') userCtrl: FormGroup;
  // @ViewChild('unitCtrl') unitCtrl: FormGroup;
  // @ViewChild('repCtrl') repCtrl: FormGroup;

  form;
  users: any[];
  filteredUsers;
  selectedUser;
  units: any[];
  filteredUnits;
  selectedUnit;
  repCodes: any[];
  filteredRepCodes;
  selectedRepCode;

  userSearch;
  unitSearch;
  repCodeSearch;

  unitCtrl = new FormControl();
  userCtrl = new FormControl();
  repCtrl = new FormControl();
  rights;

  constructor(private dialogRef: MatDialogRef<HierarchyModalComponent>, private usServ: UniversalSearchService, @Inject(MAT_DIALOG_DATA) public data: any, private ss: SessionStorageService) { }

  ngOnInit() {
    this.rights = this.ss.get('rights');
    this.getUnits();
    this.getUsers();
    this.getRepCodes();
  }

  getUsers() {
    if (this.selectedUnit) {
      this.filteredUsers = this.userCtrl.valueChanges
        // .startWith('%')
        // .debounceTime(500)
        .pipe(
          distinctUntilChanged(),
          switchMap(user => { if(user.length > 2) { return this._filterUsers(user, this.selectedUnit.id);} return []; })
        );
    } else {
      this.filteredUsers = this.userCtrl.valueChanges
        // .startWith('%')
        // .debounceTime(500)
        .pipe(
          distinctUntilChanged(),
          switchMap(user => { if(user.length > 2) { return this._filterUsers(user);} return []; })
        );
    }
  }

  selectUser(ev) {
    // console.log(ev);
    this.selectedUser = ev.option.value;
  }

  getUnits() {
    // this.usServ.unitSearch().subscribe(x => {
    //   console.log(x.names);
    //   this.units = x.names;
    this.filteredUnits = this.unitCtrl.valueChanges
      // .startWith('')
      // .debounceTime(500)
      .pipe(
        distinctUntilChanged(),
        switchMap(unit => { if(unit.length > 2) { return this._filterUnits(unit); } return [];})
      );
    // });

  }

  selectUnit(ev) {
    this.selectedUnit = ev.option.value;
    // this.getUsers();
    // this.getRepCodes();
  }

  getRepCodes() {
    if (this.selectedUnit) {
      this.filteredRepCodes = this.repCtrl.valueChanges
        // .startWith('')
        // .debounceTime(500)
        .pipe(
          distinctUntilChanged(),
          switchMap(rep => { if(rep.length > 2) { return this._filterReps(rep, this.selectedUnit.id);} return []; })
        );
    } else {
      this.filteredRepCodes = this.repCtrl.valueChanges
        // .startWith('')
        // .debounceTime(500)
        .pipe(
          distinctUntilChanged(),
          switchMap(rep => { if(rep.length > 2) { return this._filterReps(rep);} return []; })
        );
    }
  }

  selectRep(ev) {
    this.selectedRepCode = ev.option.value
  }

  // criteriaCheck(ev, type) {
  //   // console.log(ev, type);
  //   if (ev.checked) {
  //     switch (type) {
  //       case 'user':
  //         this.getUsers();
  //         break;
  //       case 'unit':
  //         this.getUnits();
  //         break;
  //       case 'rep':
  //         this.getRepCodes();
  //         break;
  //     }
  //   }
  // }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
      this.dialogRef.close({ data: {unit: this.selectedUnit, user: this.selectedUser, rep: this.selectedRepCode} });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
  }

  private _filterUnits(value: string) {
    let list = this.usServ.unitSearch(value).pipe(map(x => {
      // console.log(x.names);
      return x.names;
    }));
    // console.log(list);
    return list;
  }

  private _filterUsers(value: string, unit?) {
    let list = this.usServ.userSearch(value, unit).pipe(map(x => {
      // console.log(x.names);
      return x.names;
    }));
    // console.log(list);
    return list;
  }

  private _filterReps(value: string, unit?) {
    let list = this.usServ.repCodeSearch(value, unit).pipe(map(x => {
      // console.log(x.names);
      return x.names;
    }));
    // console.log(list);
    return list;
  }

  displayFn(val) {
    // console.log(val);
    return val ? val.name : '';
  }

  displayReps(val) {
    // console.log(val);
    return val ? `${val.repCode} ${val.name} (${val.unitName})` : '';
  }

}
