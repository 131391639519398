import { Component, OnInit, AfterViewInit, NgModule } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { GridComponent } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  process,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { formatNumber } from '@telerik/kendo-intl';
import { SessionStorageService } from '../../services/session-storage.service';
import { UniversalSearchService } from '../services/universal-search.service';
import { HierarchyModalComponent } from '../hierarchy-modal/hierarchy-modal.component';
import { SearchModalComponent } from '../search-modal/search-modal.component';
import { SuggestionEngineService } from '../../suggestion-engine/suggestion-engine.service';
import { HasPermissionDirective } from '../../directives/permissions.directive';
import { SharedReportDialogComponent } from '../../shared-components/report-dialog/report-dialog.component';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import * as moment from 'moment';
import { saveAs } from '@progress/kendo-file-saver';
// import { environment } from '../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.scss'],
})
export class UniversalSearchComponent implements OnInit, AfterViewInit {
  private environment;
  private baseUrl: string;

  public gridData: any;
  public ogData = { data: [], total: 0 };
  public gridColumns: any[] = [];
  public pageSize = 10;
  public pageableData = {
    buttonCount: 3,
    refresh: true,
    alwaysVisible: false,
    pageSizes: [this.pageSize, this.pageSize * 2.5, this.pageSize * 5],
  };
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public state: State = {
    skip: this.skip,
    take: this.pageSize,
  };
  current = 'search';
  loading = false;
  currentUser: string;
  chooseHierarchy = false;
  rights;

  selectedUser;
  selectedUnit;
  selectedRepCode;
  selectedProfiles = [];

  userSearch;
  unitSearch;
  repCodeSearch;
  searchType;
  suggestions;
  searchModel;
  bulkAction;
  header = 'Case Search';
  hideTiles = false;
  allSelected = false;

  passedInSearch = false;

  startRow = 1;
  // endRow = this.pageSize;
  endRow = 99999;

  savedSearch;

  constructor(
    private usService: UniversalSearchService,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private dialog: MatDialog,
    private router: Router,
    private ses: SuggestionEngineService,
    private params: ActivatedRoute,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {
    this.exportExcelData = this.exportExcelData.bind(this);
  }

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    this.rights = this.ss.get('rights');
    const globals = this.ss.get('globals');
    this.currentUser = globals?.user.id;
    this.hidden = this.ss.get('hiddenSearchColumns') || [];
    this.chooseHierarchy =
      !this.selectedUnit &&
      !this.selectedUser &&
      !this.selectedRepCode &&
      this.rights?.includes(
        'DisplayAndSearchOnUnitId',
        'DisplayAndSearchOnUserId',
        'DisplayAndSearchOnRepID',
        'ForceHierarchySelection'
      );
    // this.chooseHierarchy = (!this.selectedUnit && !this.selectedUser && !this.selectedRepCode);
    this.header =
      this.ss.get('currentApp') === 'se' ? 'Client Search' : this.header;

    this.savedSearch = this.ss.get('searchStorage');

    if (this.savedSearch) {
      this.reloadSearch();
    }

    // this.params.params.subscribe(pr => {
    //   console.log(pr);
    //   // this.passedInSearch = pr && pr.type ? pr.type : null;
    //   if (pr && pr.type) {
    //     this.setSearchType(pr.type);
    //   }
    // });
  }

  ngAfterViewInit() {
    this.params.params.subscribe(pr => {
      // this.passedInSearch = pr && pr.type ? pr.type : null;
      if (pr && pr.type && pr.id) {
        if (pr.type == 'suggestion') {
          this.passedInSearch = true;
          this.searchType = pr.type;
          const saved = `{"search": [{"searchBy": "profileBridge"}, {"userid": "${this.currentUser}"}, {"bridgeids": "${pr.id}"}, {"score": 50}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;

          const searchStorage = { type: this.searchType, searchDef: saved };
          this.ss.set('searchStorage', searchStorage);
          this.search(saved);
        }
      } else if (pr && pr.type && pr.type == 'callBack') {
        this.passedInSearch = true;
        this.searchType = pr.type;
        const start = pr.id.split('~')[0].replace('-', '/');
        const end = pr.id.split('~')[1].replace('-', '/');
        console.log(start, end);
        const saved = `{"search": [{Prospects: true}, {"userid": "${this.currentUser}"}, {"searchby": "callBackDate"},  {"StartDate": "${start}"}, {"EndDate": "${end}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
        const searchStorage = { type: this.searchType, searchDef: saved };

        this.ss.set('searchStorage', searchStorage);
        this.search(saved);
      } else if (pr && pr.type && pr.type == 'lastAction30') {
        // console.log('!!!!!!!!!!!!', pr);
        const date = moment(new Date())
          .subtract(30, 'days')
          .format('MM/DD/YYYY');
        this.passedInSearch = true;
        this.searchType = 'lastAction';
        const saved = `{"search": [{"searchBy": "action"}, {"userid": "${this.currentUser}"}, {"actionId": "2300,2310,2320,2330,2335,2390"}, {"actionoccurred": "${date}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
        const searchStorage = { type: this.searchType, searchDef: saved };

        this.ss.set('searchStorage', searchStorage);
        this.search(saved);
      } else if (pr && pr.type && pr.type == 'callList') {
        this.passedInSearch = true;
        this.searchType = pr.type;
        const saved = `{"search": [{"userid": "${this.currentUser}"}, {"searchby": "callList"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
        const searchStorage = { type: this.searchType, searchDef: saved };

        this.ss.set('searchStorage', searchStorage);
        this.search(saved);
      } else if (pr && pr.type && pr.type == 'timeSensitive') {
        this.passedInSearch = true;
        this.searchType = pr.type;
        const saved = `{"search": [{"searchby": "TimeSensitive"}, {"userid": "${this.currentUser}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
        const searchStorage = { type: this.searchType, searchDef: saved };

        this.ss.set('searchStorage', searchStorage);
        this.search(saved);
      } else if (pr && pr.type) {
        this.setSearchType(pr.type);
      }
    });
  }

  clearSearch() {
    this.ogData.data = [];
    this.ogData.total = 0;
    this.gridColumns = null;
    this.searchType = null;
    this.hideTiles = false;
    this.ss.remove('searchStorage');
    this.savedSearch = null;
    this.router.navigate(['/se/search']);
  }

  clearSavedSearch() {
    // this.ogData.data = [];
    // this.ogData.total = 0;
    // this.gridColumns = null;
    // this.hideTiles = false;
    this.ss.remove('searchStorage');
    this.savedSearch = null;
  }

  reloadSearch() {
    this.hideTiles = true;
    this.searchType = this.savedSearch.type;
    this.search(this.savedSearch.searchDef, false, this.savedSearch.type);
  }

  setSearchType(type?, id?) {
    if (this.passedInSearch) {
      this.clearParams();
    }
    this.hideTiles = true;
    if (!type.value) {
      this.searchType = type;
    }

    if (this.searchType === 'highScore' || this.searchType === 'callList') {
      this.search(null, true);
    } else {
      const min = this.searchType === 'suggestion' ? '95vw' : '0vw';
      const saved = this.ss.get('searchStorage');
      if (saved && saved.type == this.searchType) {
        this.searchModel = saved;
      }
      const dialogRef = this.dialog.open(SearchModalComponent, {
        maxWidth: '95vw',
        minWidth: min,
        panelClass: 'search-dialog',
        data: { type: this.searchType, saved: this.searchModel },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data !== 'cancel') {
          this.searchModel = result.data;
          this.search(null, true);
        } else {
          this.searchType = this.savedSearch.type;
        }
      });
    }
  }

  search(saved?, ignoreSaved = false, type?) {
    this.loading = true;
    const srch = { user: null };
    let searchDef: string;

    let filter = '';

    if (this.selectedUnit) {
      srch['unit'] = this.selectedUnit.id;
    }
    if (this.selectedUser) {
      srch['user'] = this.selectedUser.id;
    }
    if (this.selectedRepCode) {
      srch['rep'] = this.selectedRepCode.id;
    }
    // console.log(saved, this.savedSearch, this.searchType, ignoreSaved);
    ignoreSaved =
      type && type != this.searchType && !ignoreSaved ? true : ignoreSaved;

    let checkedSaved;
    if (saved && !ignoreSaved) {
      checkedSaved = saved;
    } else if (!ignoreSaved && this.savedSearch && this.savedSearch.searchDef) {
      checkedSaved = this.savedSearch.searchDef;
    } else {
      this.clearSavedSearch();
    }

    if (!checkedSaved) {
      switch (this.searchType) {
        case 'name':
          if (this.selectedRepCode) {
            searchDef = `{"search": [${
              this.searchModel.firstName
                ? '{"firstName": "' + this.searchModel.firstName + '"},'
                : ''
            } ${
              this.searchModel.lastName
                ? '{"lastName": "' + this.searchModel.lastName + '"},'
                : ''
            } {"searchby": "name"}, {"repCode": "${
              this.selectedRepCode
            }"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [${
              this.searchModel.firstName
                ? '{"firstName": "' + this.searchModel.firstName + '"},'
                : ''
            } ${
              this.searchModel.lastName
                ? '{"lastName": "' + this.searchModel.lastName + '"},'
                : ''
            } {"searchby": "name"}, {"userid": "${
              this.selectedUser
            }"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [${
              this.searchModel.firstName
                ? '{"firstName": "' + this.searchModel.firstName + '"},'
                : ''
            } ${
              this.searchModel.lastName
                ? '{"lastName": "' + this.searchModel.lastName + '"},'
                : ''
            } {"searchby": "name"}, {"unitid": "${
              this.selectedUnit
            }"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [${
              this.searchModel.firstName
                ? '{"firstName": "' + this.searchModel.firstName + '"},'
                : ''
            } ${
              this.searchModel.lastName
                ? '{"lastName": "' + this.searchModel.lastName + '"},'
                : ''
            } {"searchby": "name"}, {"userid": "${
              this.currentUser
            }"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'opp':
          if (this.selectedRepCode) {
            searchDef = `{"search": [{"RelevantBridgeCount": ${this.searchModel.RelevantBridgeCount}}, {"searchby": "RelevantBridgeCount"}, {"repCode": "${this.selectedRepCode}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [{"RelevantBridgeCount": ${this.searchModel.RelevantBridgeCount}}, {"searchby": "RelevantBridgeCount"}, {"userid": "${this.selectedUser}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [{"RelevantBridgeCount": ${this.searchModel.RelevantBridgeCount}}, {"searchby": "RelevantBridgeCount"}, {"unitid": "${this.selectedUnit}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [{"RelevantBridgeCount": ${this.searchModel.RelevantBridgeCount}}, {"searchby": "RelevantBridgeCount"}, {"userid": "${this.currentUser}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'timeSensitive':
          if (this.searchModel.prospects) {
            filter = '{Prospects: true},';
          } else if (this.searchModel.prospects === false) {
            filter = '{Prospects: false},';
          }

          if (this.selectedRepCode) {
            searchDef = `{"search": [${filter} {"searchby": "TimeSensitive"}, {"repCode": "${this.selectedRepCode}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [${filter} {"searchby": "TimeSensitive"}, {"userid": "${this.selectedUser}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [${filter} {"searchby": "TimeSensitive"}, {"unitid": "${this.selectedUnit}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [${filter} {"searchby": "TimeSensitive"}, {"userid": "${this.currentUser}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'highScore':
          if (this.selectedRepCode) {
            searchDef = `{"search": [{"repcode":"${this.selectedRepCode}"}, {"searchby":"highbridge"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [{"userid":"${this.selectedUser}"}, {"searchby":"highbridge"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [{"unitid":"${this.selectedUnit}"}, {"searchby":"highbridge"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [{"userid":"${this.currentUser}"}, {"searchby":"highbridge"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'callList':
          if (this.selectedRepCode) {
            searchDef = `{"search": [{"repCode": "${this.selectedRepCode}"}, {"searchby": "callList"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [{"userid": "${this.selectedUser}"}, {"searchby": "callList"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [{"unitid": "${this.selectedUser}"}, {"searchby": "callList"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [{"userid": "${this.currentUser}"}, {"searchby": "callList"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'callBack':
          if (this.searchModel.prospects) {
            filter = '{Prospects: true},';
          } else if (this.searchModel.prospects === false) {
            filter = '{Prospects: false},';
          }

          if (this.selectedRepCode) {
            searchDef = `{"search": [${filter} {"repCode": "${this.selectedRepCode}"}, {"searchby": "callBackDate"},  {"StartDate": "${this.searchModel.StartDate}"}, {"EndDate": "${this.searchModel.EndDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [${filter} {"userid": "${this.selectedUser}"}, {"searchby": "callBackDate"},  {"StartDate": "${this.searchModel.StartDate}"}, {"EndDate": "${this.searchModel.EndDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [${filter} {"unitid": "${this.selectedUser}"}, {"searchby": "callBackDate"},  {"StartDate": "${this.searchModel.StartDate}"}, {"EndDate": "${this.searchModel.EndDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [${filter} {"userid": "${this.currentUser}"}, {"searchby": "callBackDate"},  {"StartDate": "${this.searchModel.StartDate}"}, {"EndDate": "${this.searchModel.EndDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'suggestion':
          if (this.selectedRepCode) {
            searchDef = `{"search": [{"searchBy": "profileBridge"}, {"repCode": "${this.selectedRepCode}"}, {"bridgeids": "${this.searchModel.bridgeids}"}, {"score": ${this.searchModel.relThresh}}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [{"searchBy": "profileBridge"}, {"userid": "${this.selectedUser}"}, {"bridgeids": "${this.searchModel.bridgeids}"}, {"score": ${this.searchModel.relThresh}}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [{"searchBy": "profileBridge"}, {"unitid": "${this.selectedUnit}"}, {"bridgeids": "${this.searchModel.bridgeids}"}, {"score": ${this.searchModel.relThresh}}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [{"searchBy": "profileBridge"}, {"userid": "${this.currentUser}"}, {"bridgeids": "${this.searchModel.bridgeids}"}, {"score": ${this.searchModel.relThresh}}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'lastAction':
          if (this.selectedRepCode) {
            searchDef = `{"search": [{"searchBy": "action"}, {"repCode": "${this.selectedRepCode}"}, {"actionId": "${this.searchModel.actionids}"}, {"actionoccurred": "${this.searchModel.actionStartDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [{"searchBy": "action"}, {"userid": "${this.selectedUser}"}, {"actionId": "${this.searchModel.actionids}"}, {"actionoccurred": "${this.searchModel.actionStartDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [{"searchBy": "action"}, {"unitid": "${this.selectedUnit}"}, {"actionId": "${this.searchModel.actionids}"}, {"actionoccurred": "${this.searchModel.actionStartDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [{"searchBy": "action"}, {"userid": "${this.currentUser}"}, {"actionId": "${this.searchModel.actionids}"}, {"actionoccurred": "${this.searchModel.actionStartDate}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        case 'demographics':
          if (this.searchModel.additional === 'prospects') {
            filter = '{Prospects: true},';
          } else if (this.searchModel.additional === 'clients') {
            filter = '{Prospects: false},';
          }

          if (this.selectedRepCode) {
            searchDef = `{"search": [${filter} {"searchby": ""}, {"repCode": "${this.selectedRepCode}"}, { "AgeLow": "${this.searchModel.ageFrom}"}, {"AgeHigh": "${this.searchModel.ageTo}"}, {"ProductsLow": "${this.searchModel.productsFrom}"}, {"ProductsHigh": "${this.searchModel.productsTo}"}, {"IncomeLow": "${this.searchModel.incomeFrom}"}, {"IncomeHigh": "${this.searchModel.incomeTo}"}, {"TotalLifeLow": "${this.searchModel.assetsFrom}"}, {"TotalLifeHigh": "${this.searchModel.assetsTo}"}, {"ClientScoreLow": "${this.searchModel.profileFrom}"}, {"ClientScoreHigh": "${this.searchModel.profileTo}"}, {"City": "${this.searchModel.city}"}, {"ProspectSource": "${this.searchModel.source}"}, {"PostalCodes": "${this.searchModel.postalCode}"}, {"MarketCategories": "${this.searchModel.marketCategory}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUser) {
            searchDef = `{"search": [${filter} {"searchby": ""}, {"userid": "${this.selectedUser}"}, { "AgeLow": "${this.searchModel.ageFrom}"}, {"AgeHigh": "${this.searchModel.ageTo}"}, {"ProductsLow": "${this.searchModel.productsFrom}"}, {"ProductsHigh": "${this.searchModel.productsTo}"}, {"IncomeLow": "${this.searchModel.incomeFrom}"}, {"IncomeHigh": "${this.searchModel.incomeTo}"}, {"TotalLifeLow": "${this.searchModel.assetsFrom}"}, {"TotalLifeHigh": "${this.searchModel.assetsTo}"}, {"ClientScoreLow": "${this.searchModel.profileFrom}"}, {"ClientScoreHigh": "${this.searchModel.profileTo}"}, {"City": "${this.searchModel.city}"}, {"ProspectSource": "${this.searchModel.source}"}, {"PostalCodes": "${this.searchModel.postalCode}"}, {"MarketCategories": "${this.searchModel.marketCategory}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else if (this.selectedUnit) {
            searchDef = `{"search": [${filter} {"searchby": ""}, {"unitid": "${this.selectedUnit}"}, { "AgeLow": "${this.searchModel.ageFrom}"}, {"AgeHigh": "${this.searchModel.ageTo}"}, {"ProductsLow": "${this.searchModel.productsFrom}"}, {"ProductsHigh": "${this.searchModel.productsTo}"}, {"IncomeLow": "${this.searchModel.incomeFrom}"}, {"IncomeHigh": "${this.searchModel.incomeTo}"}, {"TotalLifeLow": "${this.searchModel.assetsFrom}"}, {"TotalLifeHigh": "${this.searchModel.assetsTo}"}, {"ClientScoreLow": "${this.searchModel.profileFrom}"}, {"ClientScoreHigh": "${this.searchModel.profileTo}"}, {"City": "${this.searchModel.city}"}, {"ProspectSource": "${this.searchModel.source}"}, {"PostalCodes": "${this.searchModel.postalCode}"}, {"MarketCategories": "${this.searchModel.marketCategory}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          } else {
            searchDef = `{"search": [${filter} {"searchby": ""}, {"userid": "${this.currentUser}"}, { "AgeLow": "${this.searchModel.ageFrom}"}, {"AgeHigh": "${this.searchModel.ageTo}"}, {"ProductsLow": "${this.searchModel.productsFrom}"}, {"ProductsHigh": "${this.searchModel.productsTo}"}, {"IncomeLow": "${this.searchModel.incomeFrom}"}, {"IncomeHigh": "${this.searchModel.incomeTo}"}, {"TotalLifeLow": "${this.searchModel.assetsFrom}"}, {"TotalLifeHigh": "${this.searchModel.assetsTo}"}, {"ClientScoreLow": "${this.searchModel.profileFrom}"}, {"ClientScoreHigh": "${this.searchModel.profileTo}"}, {"City": "${this.searchModel.city}"}, {"ProspectSource": "${this.searchModel.source}"}, {"PostalCodes": "${this.searchModel.postalCode}"}, {"MarketCategories": "${this.searchModel.marketCategory}"}, {"startRow": ${this.startRow}}, {"endRow": ${this.endRow}}]}`;
          }
          break;

        default:
          break;
      }
    } else {
      searchDef = checkedSaved;
    }

    this.usService.profileSearch(searchDef).subscribe(x => {
      // console.log(x);
      const data = x;
      this.ogData.data = data.data;
      this.ogData.total = data.total;
      this.hideTiles = true;
      const searchStorage = { type: this.searchType, searchDef: searchDef };

      this.ss.set('searchStorage', searchStorage);
      this.savedSearch = searchStorage;

      if (data.headers.length > 0) {
        data.headers.forEach(z => {
          switch (z.RespVis) {
            case 'xs':
              z.RespVis = '';
              break;
            case 'sm':
              z.RespVis = '(min-width: 700px)';
              break;
            case 'md':
              z.RespVis = '(min-width: 1100px)';
              // this.hideColumn(z.DataField);
              break;
            case 'lg':
              z.RespVis = '(min-width: 1200px)';
              this.hideColumn(z.DataField);
              break;
            case 'xl':
              z.RespVis = '(min-width: 1500px)';
              this.hideColumn(z.DataField);
              break;
          }

          // if (z.Type) {
          //   formatting.push(x);
          // }

          if (z.Hidden) {
            this.hidden.push(z.DataField);
          }
        });

        this.ogData.data.forEach(el => {
          el.slctd = false;
        });

        // console.log(this.ogData.data);

        this.gridColumns = data.headers.filter(col => {
          const notLegit =
            col.DataField == 'id' ||
            col.DataField == 'IsInCallList' ||
            col.DataField == 'IsProspect' ||
            col.DataField == 'followupflag';
          return !notLegit;
        });
      } else {
        console.warn(data, 'no data, do something');
      }

      // this.hidden.forEach(x => {
      //   this.hideColumn(x);
      // });
      this.loading = false;
    });
    setTimeout(() => {
      console.log(this.ogData);
    }, 2000);
  }

  pageChange(ev) {
    // this.skip = ev.skip;
    // this.startRow = ev.skip + 1;
    // this.endRow = ev.skip + ev.take;
    // let search = JSON.parse(this.ss.get('searchStorage').searchDef);
    // search.search.forEach(el => {
    //   Object.keys(el).forEach(x => {
    //     if (x == 'startRow') {
    //       el[x] = this.startRow;
    //     }
    //     if (x == 'endRow') {
    //       el[x] = this.endRow;
    //     }
    //   });
    // });
    // this.search(JSON.stringify(search));
  }

  clear() {}

  clearParams() {
    this.router.navigate([], {
      queryParams: {
        type: null,
        id: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  isHidden(columnName: string): boolean {
    // console.log(columnName, this.hidden, this.hidden.includes(columnName));
    return this.hidden.includes(columnName);
  }

  hideColumn(columnName: string): void {
    // console.log(this.hidden);
    if (this.hidden.includes(columnName) == false) {
      // this.hidden.push(columnName);
      // this.setStorage();
    }
  }

  setStorage() {
    this.ss.set('hiddenSearchColumns', this.hidden);
  }

  suggCheck(ev, val) {
    this.searchModel.suggestions[val] = !this.searchModel.suggestions[val];
  }

  actionCheck(ev, val) {
    this.searchModel.actions[val] = !this.searchModel.actions[val];
  }

  setHierarchy() {
    const dialogRef = this.dialog.open(HierarchyModalComponent, {
      panelClass: 'hierarchy-dialog',
      minHeight: '30vh',
      width: '70vw',
      data: { mode: 'SE' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data !== 'cancel') {
        this.selectedUnit = result.data.unit || null;
        this.selectedUser = result.data.user || null;
        this.selectedRepCode = result.data.rep || null;
        this.chooseHierarchy = this.chooseHierarchy =
          !this.selectedUnit &&
          !this.selectedUser &&
          !this.selectedRepCode &&
          this.rights.includes(
            'DisplayAndSearchOnUnitId',
            'DisplayAndSearchOnUserId',
            'ForceHierarchySelection'
          );
        if (this.searchType) {
          this.search(null, true);
        }
      }
    });
  }

  clearHierarchy() {
    this.selectedUnit = null;
    this.selectedUser = null;
    this.selectedRepCode = null;
    this.chooseHierarchy =
      !this.selectedUnit &&
      !this.selectedUser &&
      !this.selectedRepCode &&
      this.rights.includes(
        'DisplayAndSearchOnUnitId',
        'DisplayAndSearchOnUserId',
        'ForceHierarchySelection'
      );
  }

  viewProfile(ev) {
    this.router.navigate(['/se/client/' + ev.selectedRows[0].dataItem.id]);
  }

  selectAll(ev) {
    if (ev.checked) {
      this.ogData.data.forEach(el => {
        el.slctd = true;
        this.selectedProfiles.push(el.id);
      });
    } else {
      this.ogData.data.forEach(el => {
        el.slctd = false;
        this.selectedProfiles = [];
      });
    }
  }

  selectRow(ev, row) {
    if (ev.checked) {
      this.selectedProfiles.push(row.id);
    } else {
      const idx = this.selectedProfiles.indexOf(row.id);
      this.selectedProfiles.splice(idx, 1);
    }
  }

  executeBulk() {
    const data = { records: [] };
    if (this.selectedProfiles.length > 0) {
      if (this.bulkAction === 'excel') {
        this.loading = true;
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        const client = this.selectedProfiles.map(z => z);
        this.usService.excelAddress(client).subscribe(data => {
          this.loading = true;
          const blob = new Blob([data.body], {
            type: 'text/plain;charset=utf-8',
          });
          const date = new Date();
          const filename = `expanded-excel-data-reportdate-${date.getMonth()}${date.getDay()}${date.getFullYear()}.csv`;

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
          };
          this.search();
          this.loading = false;
        });
      } else if (this.bulkAction === 'clientReport') {
        const client = this.selectedProfiles.map(z => z);
        const dialogRef = this.dialog.open(SharedReportDialogComponent, {
          panelClass: 'report-dialog',
          data: { app: 'se', bulk: false },
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.data != 'cancel') {
            this.loading = true;
            const iosSafari =
              this.pltfrm.IOS ||
              (navigator.platform === 'MacIntel' &&
                navigator.maxTouchPoints > 1);

            const options = { ...result.data };
            this.rbs.getClientReport(client, options).subscribe(x => {
              const ext = client.length > 1 ? '.zip' : '.pdf';
              const filename = result.data.filename
                ? result.data.filename + ext
                : 'RightBridge-client-reports' + ext;

              let fileData;
              const reader = new FileReader();
              reader.readAsDataURL(x);
              reader.onloadend = () => {
                fileData = reader.result;
                saveAs(fileData, filename, {
                  proxyURL: `${this.baseUrl}/util/proxy`,
                  proxyTarget: '_self',
                  forceProxy: iosSafari,
                });
              };
              this.search();
              this.loading = false;
            });
          }
        });
      } else if (this.bulkAction === 'addToList') {
        this.ses.addToCallList(this.selectedProfiles).subscribe(resp => {
          this.loading = true;
          this.search();
        });
      } else if (this.bulkAction === 'removeFromList') {
        this.ses.removeFromCallList(this.selectedProfiles).subscribe(resp => {
          this.loading = true;
          this.search();
        });
      } else {
        this.selectedProfiles.forEach(el => {
          const entry = {
            profileID: el,
            actionID: this.bulkAction,
            note: null,
            followUpDate: null,
          };
          data.records.push(entry);
        });
        this.ses.takeBulkAction(data).subscribe(resp => {
          console.log(resp);
          this.search();
        });
      }
    }
    this.bulkAction = null;
    this.selectedProfiles = [];
    this.allSelected = false;
  }

  exportExcelData(): ExcelExportData {
    const data = [];
    this.selectedProfiles.forEach(el => {
      const entry = this.ogData.data.find(x => el === x.id);
      data.push(entry);
    });
    // console.log('##########',data);
    const ids = data.map(x => x.id);
    this.ses.viewedProfile(ids).subscribe();
    const result: ExcelExportData = {
      data: process(data, { group: this.group, sort: this.sort }).data,
      group: this.group,
    };
    return result;
  }

  nav(location) {
    this.router.navigate([`/${location}`]);
  }
}
