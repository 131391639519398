import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpEventType, HttpParams } from '@angular/common/http';
import { RightBridgeApiService } from "./right-bridge-api.service";
import { SessionStorageService }  from './session-storage.service'
import { EnvironmentService } from '../services/environment.service';
// import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JunkService {
  private environment;
  private baseUrl: string;

  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    private http: HttpClient,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  getAuditProfile(id?): Observable<any> {
    let sessId = this.ss.get('session'),
        org = 'basev2',
        app = this.ss.get('currentApp');
    const reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', app).set('X-RB-O', org);
    let profile = id;

    let url = this.baseUrl + '/GroupProfile/c297e759-e7b3-4cea-9dc5-11ec3a1cee79?groupName=AuditLog';

    return this.http.get(url, { headers: reqHeaders })
      .pipe(map((res) => {
        // console.log(res);
        return res;
      }),
      catchError((error: any) => {

        return observableThrowError(error.error || 'Server error')
      }));
  }
}
