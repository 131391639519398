<mat-form-field class="" class="mr-3">
  <input
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="sales.unit"
    [matAutocomplete]="salesAuto"
    name="salesUnitsControl"
    [formControl]="salesUnitsControl"
  />
  <mat-autocomplete #salesAuto="matAutocomplete">
    <mat-option
      *ngFor="let unit of filteredUnits | async"
      [value]="unit.id"
    >
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getSalesTracking()"
  [disabled]="!sales.unit"
>
  Get Report
</button>
<button
  mat-button
  color="primary"
  class="grid-button pull-right"
  [disabled]="!salesTrackingData.data"
  (click)="exportToExcel(salesGrid)"
  *ngIf="salesTrackingData.data"
>
  <mat-icon>cloud_download</mat-icon>Download Excel File
</button>
<kendo-grid
  #salesGrid="kendoGrid"
  [hidden]="!salesTrackingData.data"
  class="col-12 mt-3 usage-grid"
  [kendoGridBinding]="salesTrackingData.data"
  [height]="600"
  [selectable]="true"
>
  <kendo-grid-column
    *ngFor="let col of salesTrackingData.headers"
    [field]="col.DataField"
    [title]="col.Label"
  ></kendo-grid-column>
  <kendo-grid-excel
    fileName="RightBridge-SalesTrackingReport.xlsx"
  ></kendo-grid-excel>
</kendo-grid>
