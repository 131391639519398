<mat-form-field class="" class="mr-3">
  <input
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="action.unit"
    [matAutocomplete]="actionAuto"
    name="actionUnitsControl"
    [formControl]="actionUnitsControl"
  />
  <mat-autocomplete #actionAuto="matAutocomplete">
    <mat-option
      *ngFor="let unit of filteredUnits | async"
      [value]="unit.id"
    >
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getBridgeAction()"
  [disabled]="!action.unit"
>
  Get Report
</button>
<button
  mat-button
  color="primary"
  class="grid-button pull-right"
  [disabled]="!bridgeActionData.data"
  (click)="exportToExcel(bridgeActionGrid)"
  *ngIf="bridgeActionData.data"
>
  <mat-icon>cloud_download</mat-icon>Download Excel File
</button>

<kendo-grid
  #bridgeActionGrid="kendoGrid"
  [hidden]="!bridgeActionData.data"
  class="col-12 mt-3 usage-grid"
  [kendoGridBinding]="bridgeActionData.data"
  [height]="600"
  [selectable]="true"
>
  <kendo-grid-column
    *ngFor="let col of bridgeActionData.headers"
    [field]="col.DataField"
    [title]="col.Label"
  ></kendo-grid-column>
</kendo-grid>
