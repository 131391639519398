import {
  Component,
  ViewChild,
  ViewContainerRef,
  HostBinding,
  OnInit,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-wrapper-panel',
  styleUrls: ['panel-wrapper.component.scss'],
  template: `
    <div
      class="w-100"
      [ngClass]="{ error: formControl.hasError('requiredGroup') }"
    >
      <ng-container #fieldComponent> </ng-container>
    </div>
    <mat-error
      *ngIf="formControl.hasError('requiredGroup')"
      class="pl-3 pt-3 pull-left"
    >
      A minimum of one option must be selected
    </mat-error>
  `,
})
export class PanelWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true })
  fieldComponent: ViewContainerRef;
  @HostBinding('class') classes = 'w-100 form-section';

  ngOnInit() {
    (<any>this.field.options)._buildForm(true);
  }
}
