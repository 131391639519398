import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../dashboard.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';

require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge.src')(Highcharts);

import * as moment from 'moment';

@Component({
  selector: 'app-summary-charts',
  templateUrl: './summaryCharts.component.html',
  styleUrls: ['./summaryCharts.component.scss'],
})
export class SummaryChartsComponent implements OnInit {
  @Input() set solutionId(value) {
    this.solution = value;
  }
  get solutionId() {
    return this.solution;
  }

  Highcharts: typeof Highcharts = Highcharts;
  loading = false;
  caseData = {};
  chartData = [];
  solutions = [];
  solution;
  startDate = moment().utc().subtract(30, 'days').toISOString();
  endDate = moment().utc().toISOString();
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  // startDate = new FormControl(moment().subtract(30, 'days'));
  // endDate = new FormControl(moment());

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getCaseStats();
  }

  getCaseStats() {
    this.dbs
      .getActivitySummary(this.unit, this.startDate, this.endDate)
      .subscribe(x => {
        const data = x.Summary.find(
          z => z.Solution.toLowerCase() == this.solution.toLowerCase()
        );
        this.buildChartsArray(data);
      });
  }

  buildChartsArray(data) {
    const total = data.ActiveCases + data.Executions + data.ScoreClassChanges;
    this.chartData = [];

    this.chartData.push(
      this.buildChart({
        data: data.ActiveCases,
        label: 'Cases With Interactions',
        total: total,
        color: 2,
      })
    );
    this.chartData.push(
      this.buildChart({
        data: data.Executions,
        label: 'Results Viewed',
        total: total,
        color: 3,
      })
    );
    this.chartData.push(
      this.buildChart({
        data: data.ScoreClassChanges,
        label: 'Score Classes Changed',
        total: total,
        color: 4,
      })
    );
  }

  goToCaseExplorer() {
    this.router.navigate(['/case-explorer']);
  }

  buildChart(data) {
    const colors = ['#ffcb21', '#f77c00', '#89c540', '#6734ba', '#CD0000'];
    return {
      chart: {
        type: 'solidgauge',
        // Edit chart spacing
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        // Explicitly tell the width and height of a chart
        // width: '100%',
        // height: '100%',
        backgroundColor: null,
      },

      title: {
        text: null,
      },

      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      pane: {
        size: '100%',
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            // Track for Move
            outerRadius: '100%',
            innerRadius: '85%',
            backgroundColor: '#e6e7e8',
            borderWidth: 0,
          },
        ],
      },

      yAxis: {
        min: 0,
        max: data.total,
        lineWidth: 0,
        tickPositions: [],
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            // format: '<span style="text-align: center; width: 100%; display: inline-block;">{point.y}<br>{series.name}</span>',
            padding: 0,
            x: 0,
            y: 0,
            verticalAlign: 'middle',
            align: 'center',
            style: {
              width: '75%',
              //  fontSize: '18px',
              fontFamily: 'Roboto',
              textOverflow: 'clip',
            },
            useHTML: true,
            formatter: function () {
              return `<span style="text-align: center; width: 100%; font-size: 26px; display: inline-block; color: ${
                colors[data.color]
              };">${
                this.point.y
              }<br><span style="text-align: center; width: 100%; font-size: 14px; display: inline-block; font-weight: 400; color: #939597;">${
                this.series.name
              }</span></span>`;
            },
            shadow: false,
          },

          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },

      series: [
        {
          name: data.label,
          data: [
            {
              color: colors[data.color],
              radius: '100%',
              innerRadius: '85%',
              y: data.data,
            },
          ],
        },
      ],
    };
  }
}
