import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionStorageService } from '../../services/session-storage.service';
import {
  SortDescriptor,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
  process,
} from '@progress/kendo-data-query';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { MatDialog } from '@angular/material/dialog';
import { uniqBy } from 'lodash';
import { FormControl } from '@angular/forms';
import { saveAs } from '@progress/kendo-file-saver';
import { EnvironmentService } from '../../services/environment.service';
import { Platform } from '@angular/cdk/platform';
import {
  DataStateChangeEvent,
  FilterService,
} from '@progress/kendo-angular-grid';
import { HttpUrlEncodingCodec } from '@angular/common/http';

@Component({
  selector: 'app-plan-lookup',
  templateUrl: './plan-lookup.component.html',
  styleUrls: ['./plan-lookup.component.scss'],
})
export class PlanLookupComponent implements OnInit {
  // @ViewChild("productGrid", { static: false }) productGrid;
  gridData = {
    data: null,
    headers: null,
    subHead: new Map(),
  };
  gridColumns;
  ogData;
  ogHeaders;
  ogHidden = [];
  loading = false;
  public pageSize = 10;
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  planTypes = [
    {
      value: 'DC',
      label: 'Defined Contribution',
    },
    {
      value: 'DB',
      label: 'Defined Benefit',
    },
  ];
  hideGrid = false;
  filterData = {};
  planType = new FormControl();
  planName = new FormControl();
  ein = new FormControl();
  zipCode = new FormControl();
  distance = new FormControl();
  baseUrl;
  environment;
  rlcData = false;
  rights;

  constructor(
    private envSvc: EnvironmentService,
    private pltfrm: Platform,
    public dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private cstmFltrSrvc: CustomFiltersService,
    private ss: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.rights = this.ss.get('rights');
    this.rlcData = this.rights.includes('RLC');
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.gridData.data,
      this.gridData.headers
    );
  }

  updateFilters(ev) {
    this.skip = 0;
    if (this.filter.filters.length > 0) {
      this.filter.filters = uniqBy(
        [...this.filter.filters, ...ev.filters],
        'field'
      );
    } else {
      this.filter = ev;
    }

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  searchResults() {
    this.loading = true;
    let token = this.ss.get('token');
    const visibleHeaders = this.ss.get('planLookupHeaders');
    const filterParams = {};

    if (this.planType.value !== '' && this.planType.value !== null) {
      filterParams['PlanType'] = {
        id: 'PlanType',
        value: this.planType.value,
        type: 'or',
      };
    }

    if (this.planName.value !== '' && this.planName.value !== null) {
      const encode = new HttpUrlEncodingCodec();
      const name = encode.encodeValue(this.planName.value);
      filterParams['Sponsor'] = {
        id: 'Sponsor_DF_NAME',
        value: name,
        type: 'like',
      };
    }

    if (this.ein.value !== '' && this.ein.value !== null) {
      filterParams['SponsorEIN'] = {
        id: 'SponsorEIN',
        value: this.ein.value,
        type: 'ex',
      };
    }

    if (this.zipCode.value !== '' && this.zipCode.value !== null) {
      filterParams['Zip'] = {
        id: 'Zip',
        value: this.zipCode.value,
        type: 'ex',
      };
    }

    if (this.distance.value !== '' && this.distance.value !== null) {
      filterParams['Distance'] = {
        id: 'Distance',
        value: this.distance.value,
        type: 'ex',
      };
    }

    if (
      this.zipCode.value !== '' &&
      this.zipCode.value !== null &&
      (!this.distance ||
        this.distance.value === '' ||
        this.distance.value === null)
    ) {
      filterParams['Distance'] = { id: 'Distance', value: '0', type: 'ex' };
    }
    const paramString = `Values=${JSON.stringify(filterParams)}`;
    if (!token || this.rbs.isTokenExpired()) {
      this.rbs.getToken().subscribe(resp => {
        if (resp?.access_token) {
          token = resp.access_token;
          this.rbs
            .getf5500Products(resp.access_token, paramString, this.rlcData)
            .subscribe(resp => {
              this.gridData.data = resp.results.data;
              this.gridData.headers = this.buildHeaders();
              this.buildFilterOptions();
              this.loading = false;
            });
        }
      });
    } else {
      this.rbs
        .getf5500Products(token, paramString, this.rlcData)
        .subscribe(resp => {
          this.gridData.data = resp.results.data;
          this.gridData.headers = visibleHeaders
            ? JSON.parse(visibleHeaders)
            : this.buildHeaders();
          this.dataStateChange({
            filter: this.filter,
            sort: this.sort,
            skip: this.skip,
            take: this.pageSize,
          });
          this.buildFilterOptions();
          this.loading = false;
        });
    }
  }

  

  toggleVisible(ev, dataField) {
    ev.stopPropagation();
    ev.preventDefault();

    const headerIndex = this.gridData.headers.findIndex(
      header => header.DataField === dataField
    );
    this.gridData.headers[headerIndex].Hidden =
      !this.gridData.headers[headerIndex].Hidden;
    this.saveUserDefinedColumns();
  }

  saveUserDefinedColumns() {
    this.ss.set('planLookupHeaders', JSON.stringify(this.gridData.headers));
  }

  dlFactsheet(dataItem) {
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    this.loading = true;

    this.rbs.getFactsheet(dataItem.FundID).subscribe(data => {
      const filename = `F5500_Factsheet_${dataItem.FundID}.pdf`;
      this.loading = false;

      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
      };
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
  }

  roundDecimal(value) {
    return Math.round(value);
  }

  isBool(val) {
    return typeof val === 'boolean';
  }

  buildHeaders() {
    if (this.rlcData) {
      return [
        {
          DataField: 'FundID',
          Label: 'Fund ID',
          Hidden: true,
          Sort: false,
          Group: false,
          Filter: false,
          RespVis: '',
        },
        {
          DataField: 'PlanName',
          Label: 'Plan Name',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'PlanType',
          Label: 'Plan Type',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'PlanBeginYear',
          Label: 'Year Plan Began',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'SponsorZip',
          Label: 'Sponsor ZIP Code',
          Hidden: false,
          Sort: true,
          Group: true,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'ParticipantCount',
          Label: 'Participant Count',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'num',
        },
        {
          DataField: 'AdminFee',
          Label: 'Admin Fee',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'Expenses',
          Label: 'Subsidized Plan Expenses',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'FundFee',
          Label: 'Fund Fee Estimate',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'TotalAssets',
          Label: 'Assets in Plan',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'DebtToAssets',
          Label: 'Debt-to-Asset Ratio',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'ReturnOnAssets',
          Label: 'Return on Invested Assets',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'PercentOfAssetsInSponsor',
          Label: '% Assets in Plan Sponsor',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'MERGED_FailTransmitContribAmount',
          Label: 'Failure to Transmit',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_FidelityBondAmount',
          Label: 'Fidelity Bond',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_LossDiscoveredAmount',
          Label: 'Loss Discovered',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_AssetsDistributedIndicator',
          Label: 'All Assets Distributed',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MERGED_FailProvideBenefitAmount',
          Label: 'Failure to Provide Benefit',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_ComplyBlackoutPeriodIndicator',
          Label: 'Blackout Period Violation',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MERGED_PlanTerminationIndicator',
          Label: 'Plan Terminating',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MERGED_PBGCInsuranceIndicator',
          Label: 'PBGC Insurance',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'Annuities',
          Label: 'Annuities Available',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'Loans',
          Label: 'Loans Available',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'LifeInsuranceFeature',
          Label: 'Life Insurance Available',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'SelfDirectedFeature',
          Label: 'Self-Directed Option',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'FrozenPlanFeature',
          Label: 'Frozen Plan',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'CashBalanceFeature',
          Label: 'Cash Balance',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'PlanAtRisk',
          Label: 'Plan At Risk',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'EmployerStock',
          Label: 'Employer Stock Available',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'Education',
          Label: 'Education Offered',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'InService',
          Label: 'In-Service Distributions',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'Match',
          Label: 'Match Offered',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'SafeHarborMatch',
          Label: 'Safe Harbor Match',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'SafeHarborMatchDescription',
          Label: 'Safe Harbor Match Description',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MatchYears',
          Label: 'Match Years',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MatchPct',
          Label: 'Match Percent',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'ContributionYears',
          Label: 'Vesting Years',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'ContributionPct',
          Label: 'Vesting Percent',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
      ];
    } else {
      return [
        {
          DataField: 'FundID',
          Label: 'Fund ID',
          Hidden: true,
          Sort: false,
          Group: false,
          Filter: false,
          RespVis: '',
        },
        {
          DataField: 'PlanName',
          Label: 'Plan Name',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'PlanType',
          Label: 'Plan Type',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'PlanBeginYear',
          Label: 'Year Plan Began',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'SponsorZip',
          Label: 'Sponsor ZIP Code',
          Hidden: false,
          Sort: true,
          Group: true,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'ParticipantCount',
          Label: 'Participant Count',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'num',
        },
        {
          DataField: 'AdminFee',
          Label: 'Admin Fee',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'Expenses',
          Label: 'Subsidized Plan Expenses',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'bool',
        },
        {
          DataField: 'FundFee',
          Label: 'Fund Fee Estimate',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'TotalAssets',
          Label: 'Assets in Plan',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'DebtToAssets',
          Label: 'Debt-to-Asset Ratio',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'ReturnOnAssets',
          Label: 'Return on Invested Assets',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'PercentOfAssetsInSponsor',
          Label: '% Assets in Plan Sponsor',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'perc3',
        },
        {
          DataField: 'MERGED_FailTransmitContribAmount',
          Label: 'Failure to Transmit',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_FidelityBondAmount',
          Label: 'Fidelity Bond',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_LossDiscoveredAmount',
          Label: 'Loss Discovered',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_AssetsDistributedIndicator',
          Label: 'All Assets Distributed',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MERGED_FailProvideBenefitAmount',
          Label: 'Failure to Provide Benefit',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
          Type: 'curr',
        },
        {
          DataField: 'MERGED_ComplyBlackoutPeriodIndicator',
          Label: 'Blackout Period Violation',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MERGED_PlanTerminationIndicator',
          Label: 'Plan Terminating',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'MERGED_PBGCInsuranceIndicator',
          Label: 'PBGC Insurance',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'LifeInsuranceFeature',
          Label: 'Life Insurance Available',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'SelfDirectedFeature',
          Label: 'Self-Directed Option',
          Hidden: false,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'FrozenPlanFeature',
          Label: 'Frozen Plan',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'CashBalanceFeature',
          Label: 'Cash Balance',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
        {
          DataField: 'PlanAtRisk',
          Label: 'Plan At Risk',
          Hidden: true,
          Sort: true,
          Group: false,
          Filter: true,
          RespVis: '',
        },
      ];
    }
  }
}
