<div class="col-12 chart-container mt-3">
  <h4>System Usage</h4>
  <highcharts-chart *ngIf="chart.data" [Highcharts]="Highcharts" [options]="chart.data" class="col-12 pull-left"></highcharts-chart>
  <!-- <div class="chart-legend col-4 pull-left" >
    <ng-container *ngIf="chart.legend;">
      <table class="col-12">
        <thead>
          <tr>
            <ng-container *ngFor="let th of chart.legend.header">
              <th *ngIf="th.toLowerCase() != 'color'">
                {{th.replace('_', ' ')}}
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tr of chart.legend.data">
            <ng-container *ngFor="let key of chart.legend.header">
              <td>
                {{tr[key]}}
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div> -->
</div>
