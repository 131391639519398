import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseManagementService } from '../case-management.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { EnvironmentService } from '../../services/environment.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-ad-hoc-modal',
  templateUrl: './ad-hoc-modal.component.html',
  styleUrls: ['./ad-hoc-modal.component.scss'],
})
export class AdHocModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AdHocModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private cms: CaseManagementService,
    private ss: SessionStorageService,
    private snacky: MatSnackBar,
    private envSvc: EnvironmentService
  ) {}

  repCode = null;
  repCodeValue = null;
  repCodeControl = new FormControl();
  caseType = null;
  caseTitle = new FormControl();

  assignedTo = null;
  caseStatus = null;
  caseStatusOptions = [];
  fileName;
  fileNameDisplay;
  file;
  fileType;
  caseDetails = {};
  message = '';
  messages = [];
  editMessageId = null;
  editMessage = '';
  selectedCaseFile = null;
  caseFiles = null;
  statusOptions = [];
  caseProfile = {};
  loading = false;
  caseUsers = [];
  caseTypes = [
    { value: 'AW', display: 'AW' },
    { value: 'MIX', display: 'MIX' },
    { value: 'GENERIC', display: 'GENERIC' },
  ];
  isCaseManager = false;
  baseUrl: string;
  environment;
  caseNote = '';

  ngOnInit() {
    this.loading = true;
    const rights = this.ss.get('rights');
    this.isCaseManager = rights && rights.includes('CaseManager');
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    if (this.data.useFilteredUsers) {
      this.getFilteredOptions();
      this.loading = false;
    } else {
      this.caseUsers = this.data.usersList;
      this.loading = false;
    }
  }

  getFilteredOptions() {
    this.repCodeControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getUserList(value);
      });
  }

  getUserList(searchString?: string) {
    this.cms.getCmRepCodes(searchString).subscribe(resp => {
      const newUsersList = resp.names.map(user => ({
        value: user.repCode,
        display: user.name,
      }));
      this.caseUsers = newUsersList;
    });
  }

  setRepCode(ev) {
    const {
      option: { value },
    } = ev;
    this.repCode = value;
    this.repCodeValue = this.caseUsers.find(user => user.value === value).value;
  }

  createCase() {
    this.loading = true;
    const caseData = {
      CaseManagement: {
        Type: this.caseType,
        Title: this.caseTitle.value,
      },
      Client_Rep: {
        RepCode: [this.repCode],
      },
    };

    this.cms.createCaseManagementRecord(null, caseData).subscribe(resp => {
      if (resp) {
        setTimeout(() => {
          this.loading = false;
          this.snacky.open(`Case created!`, 'Close', {
            duration: 3000,
          });
          this.dialogRef.close({
            CRID: resp['result'],
          });
        }, 2000);
      }
    });
  }

  close() {
    this.dialogRef.close({ data: 'cancel' });
  }
}
