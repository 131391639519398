import { throwError as observableThrowError, Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpEventType, HttpParams } from '@angular/common/http';
import { SessionStorageService }  from '../services/session-storage.service'
// import { environment } from '../../environments/environment';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';

@Injectable({
  providedIn: 'root'
})
export class LifeWizardService {
  private environment;
  private baseUrl: string;
  private firm: String;
  private app: string = 'li';
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private router: Router,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
  }

  getLifeNeeds(profile): Observable<any> {
    let sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', this.app).set('X-RB-O', this.org).set('Content-Type', 'application/json');
    // let profile = id;

    let url = `${this.baseUrl}/Process/LifeNeeds/${profile}`;


    return this.http.get(url, { headers: reqHeaders })
      .pipe(map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
        this.rbs.logout();
      }));
  }

  getLifeScenarios(profile): Observable<any> {
    let sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', this.app).set('X-RB-O', this.org).set('Content-Type', 'application/json');
    // let profile = id;

    let url = `${this.baseUrl}/Process/LifeScenarios/${profile}?includeAll=true`;


    return this.http.get(url, { headers: reqHeaders })
      .pipe(map((res) => {
        return res;
      }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
          this.rbs.logout();
        }));
  }

  getLifeProducts(profile, includeAll = false): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', this.app).set('X-RB-O', this.org).set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/Process/LifeProducts/${profile}?includeAll=${includeAll}`;

    return this.http.get(url, { headers: reqHeaders })
      .pipe(map((res) => {
        return res;
      }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
          this.rbs.logout();
        }));
  }

  getValidateCarriers(): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', this.app).set('X-RB-O', this.org).set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/LifeProducts/UnitCarriers`;

    return this.http.get(url, { headers: reqHeaders })
      .pipe(map((res) => {
        return res;
      }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
          this.rbs.logout();
        }));
  }

  getValidateProducts(group, state): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', this.app).set('X-RB-O', this.org).set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/LifeProducts/UnitProductsByCarrier?carrierGroupID=${group}&stateOfIssue=${state}`;

    return this.http.get(url, { headers: reqHeaders })
      .pipe(map((res) => {
        return res;
      }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
          this.rbs.logout();
        }));
  }

  getReport(profile, prods, silentPrint?): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders().set('X-RB-S', id).set('X-RB-I', this.app).set('X-RB-O', this.org).set('Accept', 'application/pdf');
    let url = `${this.baseUrl}/reports/li/${profile}`;

    if (silentPrint) {
      url += '&returnReport=false';
    }

    return this.http.post(url, prods, { headers: reqHeaders, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        // console.log(res);
        return res.body;
      }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
          this.rbs.logout();
        }));
  }

  getLifeStats(): Observable<any> {
    let sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', this.app).set('X-RB-O', this.org).set('Content-Type', 'application/json');

    let url = `${this.baseUrl}/widget/ProfileCaseStats`;


    return this.http.get(url, { headers: reqHeaders })
      .pipe(map((res) => {
        return res;
      }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
          this.rbs.logout();
        }));
  }
}
