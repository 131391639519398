import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { SearchComponent } from '../search/search.component';
import { ProductResultComponent } from './product-result/product-result.component';
import { FormlyComponent } from '../conditional-form/formly.component';
import { ProxyComponent } from '../proxy/proxy.component';
import { AppGlobalsService } from '../services/app-globals.service';
import { HelpComponent } from '../shared-components/help/help.component';
import { PlanLookupComponent } from './plan-lookup/plan-lookup.component';

const routes: Routes = [
  {
    path: 'pp',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        redirectTo: '/unified',
        pathMatch: 'full',
        data: {
          breadcrumb: 'Landing',
        },
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'questionnaire',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'questionnaire/:id',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'results/:id',
        component: ProductResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Results',
        },
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthService],
      },
      {
        path: 'proxy',
        component: ProxyComponent,
        canActivate: [AuthService],
      },
      {
        path: 'plan-lookup',
        component: PlanLookupComponent,
        canActivate: [AuthService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PpRouting {}
