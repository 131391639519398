<div class="page-container container unified">
  <div class="row">
    <div class="col-12">
      <button
        color="primary"
        mat-raised-button
        class="print-button pull-right mb-3"
        (click)="report()"
        *ngIf="!rights?.includes('DropdownReportMenu') && !hasSummaryModule"
      >
        <mat-icon>insert_drive_file</mat-icon>
        <span>{{ printText }}</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="products-container col">
      <div class="product-container">
        <div class="row">
          <h2 class="mb-2 col"
            >Validated Product{{ validateProducts.length > 1 ? 's' : '' }}</h2
          >
          <app-print-dropdown
            class="print-button"
            [params]="{ profile: id, color: 'primary' }"
            (standardReport)="report($event)"
            *hasPermission="'DropdownReportMenu'"
          ></app-print-dropdown>
        </div>
        <ul class="products col-12">
          <li
            *ngFor="let prod of validateProducts"
            class="col-12 product validated expanded {{ prod.ScoreClass }}"
          >
            <div class="col-12 row">
              <ng-container *hasPermission="'BridgeNotes'">
                <div
                  class="notes-notification"
                  [ngClass]="{ valid: prod.notesValid }"
                  *ngIf="
                    prod.vars['CurrentBridgeNote^Required'].raw[0] === 2 ||
                    (prod.vars['CurrentBridgeNote^Required'].raw[0] === 3 &&
                      prod.negativeReason)
                  "
                >
                  <ng-container *ngIf="prod.notesValid; else invalid">
                    <mat-icon>thumb_up</mat-icon>Required Notes Entered for This
                    Item
                  </ng-container>
                  <ng-template #invalid>
                    <mat-icon>error</mat-icon>Notes Required for This Item
                  </ng-template>
                </div>
              </ng-container>
              <div class="col-11 content">
                <div
                  class="type-indicator {{
                    prod.ProductTypeHeader.toLowerCase()
                  }}"
                  *ngIf="prod.ProductTypeHeader"
                >
                  {{ prod.ProductTypeHeader }}</div
                >
                <div class="score-icon {{ prod.ScoreClass }}">
                  <ng-container *hasPermission="'RelevanceBridgeIcon'">
                    <mat-icon *ngIf="prod.ScoreClass === 'positive'"
                      >check_circle</mat-icon
                    >
                    <mat-icon *ngIf="prod.ScoreClass === 'neutral'"
                      >error</mat-icon
                    >
                    <mat-icon *ngIf="prod.ScoreClass === 'negative'"
                      >remove_circle</mat-icon
                    >
                  </ng-container>
                </div>
                <h4>
                  <span class="product-name">
                    {{ prod.ShortName }}
                    <span
                      class="product-score"
                      *hasPermission="'!RelevanceHideBridgeScore'"
                    >
                      | {{ scoreFormat(prod.score) }}</span
                    >
                  </span>
                </h4>
              </div>
              <div class="col-1 button">
                <button
                  class="collapse-button"
                  mat-icon-button
                  (click)="expand($event)"
                >
                  <span class="collapsed-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                  <span class="expanded-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                </button>
              </div>
            </div>
            <div class="details-container">
              <app-sub-item-chart
                *ngIf="prod.ComplianceChart"
                [data]="prod.ComplianceChart"
                [chartTitle]="'Regulation Review'"
              ></app-sub-item-chart>
              <h5 class="col-12">Reasons for this Product</h5>
              <div class="reasons-container w-100">
                <div
                  class="details col-6 {{ z.reasonType.toLowerCase() }}"
                  *ngFor="let z of prod.reasons"
                >
                  <strong class="header">
                    <img
                      *ngIf="z.reasonType"
                      src="{{
                        './assets/' + path + z.reasonType.toLowerCase() + '.png'
                      }}"
                      [alt]="z.reasontType"
                    />
                    <span [innerHtml]="z.headline"></span>
                    <span *hasPermission="'!RelevanceHideReasonScores'">
                      | {{ scoreFormat(z.score) }}</span
                    >
                  </strong>
                  <p class="explanation" [innerHtml]="z.explanation"></p>
                </div>
              </div>
              <app-notes-entry
                class="w-100 mt-2"
                *ngIf="rights.includes('BridgeNotes')"
                [bridgeVal]="prod.reasons"
                (noteEntered)="handleNotesValidation($event, prod)"
                [notesData]="{
                  app: 'li',
                  profile: this.id,
                  bridgeId: prod.vars['CurrentBridgeNote^NoteBridgeID'].raw[0],
                  note: prod.vars['CurrentBridgeNote^NoteText'].raw[0],
                  prompt: prod.vars['CurrentBridgeNote^Prompt'].raw[0],
                  required:
                    (prod.SelectedBridge &&
                      prod.vars['CurrentBridgeNote^Required'].raw[0] === 2) ||
                    (prod.vars['CurrentBridgeNote^Required'].raw[0] === 3 &&
                      prod.negativeReason)
                }"
              ></app-notes-entry>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="products-container col-12 mt-4" *ngIf="alternatives.length > 0">
      <div class="product-container">
        <h2 class="mb-2">Alternatives</h2>
        <ul class="products col-12">
          <li
            *ngFor="let prod of alternatives"
            class="col-12 product {{ prod.ScoreClass }}"
          >
            <div class="col-12 row">
              <div class="col-11 content">
                <div
                  class="type-indicator {{
                    prod.ProductTypeHeader.toLowerCase()
                  }}"
                  *ngIf="prod.ProductTypeHeader"
                >
                  {{ prod.ProductTypeHeader }}</div
                >
                <div class="score-icon {{ prod.ScoreClass }}">
                  <ng-container *hasPermission="'RelevanceBridgeIcon'">
                    <mat-icon *ngIf="prod.ScoreClass === 'positive'"
                      >check_circle</mat-icon
                    >
                    <mat-icon *ngIf="prod.ScoreClass === 'neutral'"
                      >error</mat-icon
                    >
                    <mat-icon *ngIf="prod.ScoreClass === 'negative'"
                      >remove_circle</mat-icon
                    >
                  </ng-container>
                </div>
                <h4>
                  <span class="product-name">
                    {{ prod.ShortName }}
                    <span
                      class="product-score"
                      *hasPermission="'!RelevanceHideBridgeScore'"
                    >
                      | {{ scoreFormat(prod.score) }}</span
                    >
                  </span>
                </h4>
              </div>
              <div class="col-1 button">
                <button mat-icon-button (click)="expand($event)">
                  <span class="collapsed-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                  <span class="expanded-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                </button>
              </div>
            </div>
            <div class="details-container">
              <app-sub-item-chart
                *ngIf="prod.ComplianceChart"
                [data]="prod.ComplianceChart"
                [chartTitle]="'Regulation Review'"
              ></app-sub-item-chart>
              <h5 class="col-12">Reasons for this Product</h5>
              <div class="reasons-container w-100">
                <div
                  class="details col-6 {{ z.reasonType.toLowerCase() }}"
                  *ngFor="let z of prod.reasons"
                >
                  <strong class="header">
                    <img
                      *ngIf="z.reasonType"
                      src="{{
                        './assets/' + path + z.reasonType.toLowerCase() + '.png'
                      }}"
                      [alt]="z.reasontType"
                    />
                    <span [innerHtml]="z.headline"></span>
                    <span *hasPermission="'!RelevanceHideReasonScores'">
                      | {{ scoreFormat(z.score) }}
                    </span>
                  </strong>
                  <p class="explanation" [innerHtml]="z.explanation"></p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="report-button row w-100 buttons">
      <button
        mat-raised-button
        *hasPermission="'ELinLI'"
        (click)="externalAction()"
        color="primary"
        class="external-link"
      >
        <mat-icon>link</mat-icon>{{ externalLink?.label }}
      </button>
      <button
        mat-raised-button
        *hasPermission="'ELinLIDynamic'"
        (click)="externalAuditAction()"
        color="primary"
        class="external-link"
      >
        <mat-icon>link</mat-icon>{{ externalAudit?.label }}
      </button>
    </div>
  </div>
</div>
