import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { UnifiedFlowService } from '../../../unified-flow/unified.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form-repcode',
  styleUrls: ['form-repCode.component.scss'],
  template: `
    <label
      class="section w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
      [innerHTML]="props.label"
      ><sup *ngIf="props.required">*</sup></label
    >
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>

    <mat-form-field class="w-100">
      <mat-label>{{ loading ? 'Loading Rep Codes...' : to.label }}</mat-label>
      <mat-select
        [disabled]="disabledDrop || loading"
        [formControl]="formControl"
        [placeholder]="props.placeholder || props.label"
        [formlyAttributes]="field"
        class="w-100"
        [required]="props.required"
        (selectionChange)="props.change ? to.change(field, formControl) : ''"
      >
        <mat-option *ngFor="let opt of dropOptions" [value]="opt.value">{{
          opt.label
        }}</mat-option>
      </mat-select>
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        >help</mat-icon
      >
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
        >This field is required</mat-error
      >
      <mat-error *ngIf="formControl.hasError('regRequired')"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-form-field>
  `,
})
export class FormlyRepCodeComponent extends FieldType implements OnInit {
  globals;
  dropOptions = [{ value: 'no_rep_codes', label: 'No Rep Codes Available' }];
  disabledDrop = false;
  appStatus = false;
  profile = '';
  loading = false;

  constructor(
    private ss: SessionStorageService,
    private unfFlowSvc: UnifiedFlowService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    if (Array.isArray(this.formControl.value)) {
      this.formControl.setValue(this.formControl.value[0]);
    }

    this.route.params.subscribe(params => {
      this.profile = params.id ? params.id : null;
    });

    if (this.profile) {
      this.unfFlowSvc.getApps(this.profile).subscribe(x => {
        x.Modules.map(module => {
          if (module.vars.ModuleStatus.FlowStatus === 'C') {
            this.appStatus = true;
          }
        });
        this.repCodeEval();
      });
    } else {
      this.repCodeEval();
    }
  }

  repCodeEval() {
    this.globals = this.ss.get('globals');
    const rawRepCodes = this.globals.user.repUnits.map(x => x.repCode);

    if (rawRepCodes.length < 1 && this.props.rawValues) {
      this.removeLoadingOption();
      this.dropOptions = [this.props.rawValues];
      this.disabledDrop = true;
    } else if (
      rawRepCodes.length > 0 &&
      this.formControl.value != null &&
      !rawRepCodes.includes(this.formControl.value)
    ) {
      this.removeLoadingOption();
      this.dropOptions = [
        { value: this.formControl.value, label: this.formControl.value },
      ];
      this.disabledDrop = true;
    } else if (this.profile && this.appStatus) {
      this.removeLoadingOption();
      this.dropOptions = [
        { value: this.formControl.value, label: this.formControl.value },
      ];
      this.disabledDrop = true;
    } else if (
      (rawRepCodes.length > 0 && this.formControl.value == null) ||
      (rawRepCodes.length > 0 &&
        this.formControl.value != null &&
        rawRepCodes.includes(this.formControl.value)) ||
      !this.profile ||
      (this.profile && !this.appStatus)
    ) {
      this.dropOptions = this.globals.user.repUnits.map(x => {
        return { value: x.repCode, label: `${x.repName} (${x.repCode})` };
      });
      this.removeLoadingOption();
    } else {
      this.removeLoadingOption();
      this.formControl.setValue('no_rep_codes');
      this.disabledDrop = true;
    }
  }

  removeLoadingOption() {
    const idx = this.dropOptions.findIndex(
      x => x.value === 'loading_rep_codes'
    );
    if (idx > -1) {
      this.dropOptions.splice(idx, 1);
    }
    this.formControl.setValue(this.formControl.value);
    this.loading = false;
  }
}
