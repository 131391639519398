import { Component, HostBinding, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { UnitProfileHistoryDialogComponent } from '../../../admin/unit-profile-history-dialog/unit-profile-history-dialog.component';
import { SessionStorageService }  from '../../../services/session-storage.service'

@Component({
  selector: 'form-tabs',
  styleUrls: ['tabs.component.scss'],
  template: `
    <button
      mat-button
      type="button"
      (click)="viewHistory()"
      color="primary"
      class="unit-profile-button pull-right"
    >
      <mat-icon>view_list</mat-icon>View History
    </button>
    <button
      mat-button
      type="submit"
      color="primary"
      class="unit-profile-button pull-right"
    >
      <mat-icon>save</mat-icon>Save Unit Profile
    </button>
    <mat-tab-group class="col-12" *ngIf="field.fieldGroup">
      <ng-container *ngFor="let step of field.fieldGroup; let index = index; let last = last;" >
        <mat-tab
          label="{{ step.props.label }}"
          *ngIf="(
            step.props.right &&
            rights.includes(step.props.right)
          ) || (
            !step.props.right && (step.hide === undefined || step.hide === false
          ))"
        >
          <ng-template matTabContent>
            <formly-field [field]="step"></formly-field>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
    <button
      mat-button
      type="submit"
      color="primary"
      class="unit-profile-button pull-right"
    >
      <mat-icon>save</mat-icon>Save Unit Profile
    </button>
  `
})

export class FormlyTabs extends FieldType<FormlyFieldConfig> implements OnInit {

  dialogRef;
  rights = this.ss.get('rights');

  constructor(private dialog: MatDialog, private ss: SessionStorageService) {
    super();
  }

  ngOnInit() {}

  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup.every(f => this.isValid(f));
  }

  viewHistory() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(UnitProfileHistoryDialogComponent, { panelClass: 'beacon-dialog', data: {unit: this.props.context.unit, solution: this.props.context.app}, maxHeight: '90vh', minHeight: '50vh', width: '90vw' });
      // dialogRef.afterOpened().subscribe(x => { this.loading = false; });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        this.dialogRef = null;
      });
    }
  }
}

