import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { SafeContent } from '../safe-html.pipe';
import { Visible } from '../visible.pipe';
import { FilterValuesPipe } from '../filter-value.pipe';
import { LocalTime } from '../local-time.pipe';

import { NgxLoadingModule } from 'ngx-loading';

import { UniversalSearchModule } from '../universal-search/universal-search.module';
import { SummaryPageComponent } from './summary-page/summary-page.component';
import { ProductResultComponent } from './product-result/product-result.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { PostbackLoadingComponent } from './postback-loading/postback-loading.component';
import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { LifeEventComponent } from './life-event-dialog/life-event-dialog.component';
import { ResultDetailComponent } from './result-detail/result-detail.component';
import { ToolbarSearchComponent } from './toolbar-search/toolbar-search.component';
import { SharedReportDialogComponent } from './report-dialog/report-dialog.component';
import { SaveUnitProfileDialogComponent } from '../admin/save-unit-profile-dialog/save-unit-profile-dialog.component';
import { TemplatesComponent } from '../account-management/templates/templates.component';
import { TemplateModalComponent } from '../account-management/template-modal/template-modal.component';
import { SharedTemplateModalComponent } from '../account-management/shared-template-modal/shared-template-modal.component';
import { ReasonTextComponent } from './reason-text/reason-text.component';
import { HelpComponent } from './help/help.component';
import { SubItemChartComponent } from './sub-item-chart/sub-item-chart.component';
import { SearchComponent } from '../search/search.component';
import { ProxyComponent } from '../proxy/proxy.component';
import { ToolbarComponent } from '../admin/toolbar/toolbar.component';
import { PershingPrintComponent } from './pershing-print/pershing-print.component';
import { BuildInformationComponent } from '../admin/build-information/build-information.component';
import { ToolLinkComponent } from './tool-link/tool-link.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

//Directives
import { HasPermissionDirective } from '../directives/permissions.directive';
import { DebounceKeyUpDirective } from '../directives/debounceKeyUp.directive';

//Highcharts
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highcharts3d from 'highcharts/highcharts-3d.src';

export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [more, exporting, highcharts3d];
}

import { HighchartsChartModule } from 'highcharts-angular';

//Kendo Imports
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';

import { PostbackService } from '../services/postback.service';
import { ChartsComponent } from './charts/charts.component';
import { MulticheckFilterComponent } from './multicheck-filter/multicheck-filter.component';
import { GridFiltersComponent } from './grid-filters/grid-filters.component';
import { FundSearchComponent } from './fund-search/fund-search.component';
import { PrintDropdownComponent } from './print-dropdown/print-dropdown.component';
import { ConsolidatedReportModalComponent } from './consolidated-report-modal/consolidated-report-modal.component';
import { CustomReportModalComponent } from './custom-report-modal/custom-report-modal.component';
import { AgPrintComponent } from './ag-print/ag-print.component';
import { UnifiedPrintComponent } from './unified-print/unified-print.component';
import { GridColumnFilterComponent } from './grid-column-filter/grid-column-filter.component';
import { LeftNavComponent } from '../unified-flow/left-nav/left-nav.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';

//Shared Unified
import { AnnuitiesResultComponent } from '../annuity-wizard/annuities-result/annuities-result.component';
import { AwComparisonComponent } from '../unified-flow/aw-comparison/aw-comparison.component';
import { ModelSelectionComponent } from '../investment-wizard/model-selection/model-selection.component';
import { ProgramSelectionComponent } from '../investment-wizard/program-selection/program-selection.component';
import { FundAllocationsComponent } from '../investment-wizard/fund-allocations/fund-allocations.component';
import { ValidateResultComponent } from '../investment-wizard/validate-result/validate-result.component';
import { ResultComponent } from '../life-wizard/results/results.component';
import { ScenariosComponent } from '../life-wizard/scenarios/scenarios.component';
import { ValidateComponent } from '../life-wizard/validate/validate.component';
import { NotesEntryComponent } from '../unified-flow/notes-entry/notes-entry.component';
import { SliderChartsComponent } from '../life-wizard/slider-charts/slider-charts.component';
import { UtilityToolbarComponent } from './utility-toolbar/utility-toolbar.component';
import { WarnDialogComponent } from '../warn-dialog/warn-dialog.component';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { TechNotesModalComponent } from '../app-header/tech-note-modal/tech-notes-modal.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SimpleLoadingComponent } from './simple-loading/simple-loading.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatIconModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxLoadingModule,
    ReactiveFormsModule,
    FormsModule,
    UniversalSearchModule,
    ChartModule,
    GridModule,
    ExcelModule,
    MatSliderModule,
    MatSnackBarModule,
    MultiSelectModule,
    InputsModule,
    DatePickerModule,
    NgxDatatableModule,
    HighchartsChartModule,
    NgxMaskPipe,
    NgxMaskDirective,
    BreadcrumbComponent,
    SimpleLoadingComponent,
  ],
  declarations: [
    // BreadcrumbComponent,
    HasPermissionDirective,
    DebounceKeyUpDirective,
    SafeContent,
    Visible,
    SummaryPageComponent,
    ProductResultComponent,
    ClientInfoComponent,
    NotesDialogComponent,
    PostbackLoadingComponent,
    ActionDialogComponent,
    LifeEventComponent,
    ResultDetailComponent,
    ToolbarSearchComponent,
    SharedReportDialogComponent,
    SaveUnitProfileDialogComponent,
    TemplatesComponent,
    TemplateModalComponent,
    SharedTemplateModalComponent,
    ReasonTextComponent,
    HelpComponent,
    SearchComponent,
    ProxyComponent,
    SubItemChartComponent,
    ChartsComponent,
    MulticheckFilterComponent,
    GridFiltersComponent,
    FundSearchComponent,
    PrintDropdownComponent,
    ConsolidatedReportModalComponent,
    UnifiedPrintComponent,
    ToolbarComponent,
    GridColumnFilterComponent,
    FilterValuesPipe,
    LocalTime,
    CustomReportModalComponent,
    AgPrintComponent,
    PershingPrintComponent,
    BuildInformationComponent,
    ToolLinkComponent,
    LeftNavComponent,
    AnnuitiesResultComponent,
    AwComparisonComponent,
    ModelSelectionComponent,
    ProgramSelectionComponent,
    FundAllocationsComponent,
    ValidateResultComponent,
    ResultComponent,
    ScenariosComponent,
    ValidateComponent,
    NotesEntryComponent,
    DateRangeFilterComponent,
    SliderChartsComponent,
    UtilityToolbarComponent,
    WarnDialogComponent,
    LoadingDialogComponent,
    TechNotesModalComponent,
  ],
  providers: [
    PostbackService,
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  exports: [
    BreadcrumbComponent,
    HasPermissionDirective,
    DebounceKeyUpDirective,
    SafeContent,
    Visible,
    UniversalSearchModule,
    SummaryPageComponent,
    ProductResultComponent,
    NgxLoadingModule,
    ActionDialogComponent,
    LifeEventComponent,
    ResultDetailComponent,
    ToolbarSearchComponent,
    SharedReportDialogComponent,
    SaveUnitProfileDialogComponent,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatIconModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    TemplatesComponent,
    TemplateModalComponent,
    SharedTemplateModalComponent,
    GridModule,
    ExcelModule,
    ReasonTextComponent,
    HelpComponent,
    SearchComponent,
    ProxyComponent,
    MatSliderModule,
    SubItemChartComponent,
    MatSnackBarModule,
    MulticheckFilterComponent,
    GridFiltersComponent,
    FundSearchComponent,
    PrintDropdownComponent,
    ConsolidatedReportModalComponent,
    UnifiedPrintComponent,
    ToolbarComponent,
    GridColumnFilterComponent,
    PostbackLoadingComponent,
    FilterValuesPipe,
    LocalTime,
    MultiSelectModule,
    InputsModule,
    DatePickerModule,
    CustomReportModalComponent,
    AgPrintComponent,
    PershingPrintComponent,
    BuildInformationComponent,
    ToolLinkComponent,
    LeftNavComponent,
    AnnuitiesResultComponent,
    AwComparisonComponent,
    ModelSelectionComponent,
    ProgramSelectionComponent,
    FundAllocationsComponent,
    ValidateResultComponent,
    ResultComponent,
    ScenariosComponent,
    ValidateComponent,
    NotesEntryComponent,
    DateRangeFilterComponent,
    SliderChartsComponent,
    UtilityToolbarComponent,
    WarnDialogComponent,
    LoadingDialogComponent,
    TechNotesModalComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    SimpleLoadingComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedComponentsModule {}
