<div mat-dialog-close class="close-button" (click)="close()">
  <mat-icon>close</mat-icon>
</div>
<div mat-dialog-header>
  <h1 class="mb-0" *ngIf="!loading && !editCaseTitle">
    {{ caseProfile['CaseManagement']['Title'] }}
    <button mat-icon-button [matTooltip]="'Edit case title'" (click)="toggleEditCaseTitle(true)">
      <mat-icon>edit</mat-icon>
    </button>
  </h1>
  <div class="edit-title-container mb-3" *ngIf="!loading && editCaseTitle">
    <mat-form-field class="mb-0 w-50">
      <mat-label>Case Title</mat-label>
      <input type="text" [placeholder]="'Case Title'" matInput name="caseTitle" [(ngModel)]="caseTitle" />
    </mat-form-field>
    <button color="warn" mat-icon-button [matTooltip]="'Cancel'" (click)="cancelEditCaseTitle(false)">
      <mat-icon>close</mat-icon>
    </button>
    <button color="primary" mat-icon-button [matTooltip]="'Save case title'" (click)="saveCaseTitle()">
      <mat-icon>save</mat-icon>
    </button>
  </div>
  <div class="case-data" *ngIf="!loading && showCaseHistory">
    <p class="mb-0">
      Case Management Number: {{ data.CRID }} | Submitted By:
      {{ caseHistoryData[0]['SavedByName'] }}
    </p>
  </div>
</div>

<div class="row dialog-content mt-3">
  <div class="col-5 case-info">
    <div class="assigned-to-container mb-3" *ngIf="!loading">
      <mat-form-field class="w-100">
        <mat-label>Assigned To</mat-label>
        <mat-select [(ngModel)]="caseProfile['CaseManagement']['AssignedTo']" (selectionChange)="changeValue()">
          <ng-container *ngIf="caseUsers.length > 0">
            <mat-option *ngFor="let user of caseUsers" [value]="user.UserID">
              {{ user.UserName || user.UserID }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="case-status-container mb-3" *ngIf="!loading">
      <mat-form-field class="w-100">
        <mat-label>Case Status</mat-label>
        <mat-select [(ngModel)]="caseProfile['CaseManagement']['StatusID']" (selectionChange)="changeValue()">
          <mat-option *ngFor="let status of caseProfile['ActiveStatuses']" [value]="status.StatusID"
            [disabled]="!status['Active']">
            {{ status.StatusName || status.StatusID }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="save-case-container mb-4">
      <button class="w-100" mat-raised-button aria-label="Submit Note" color="primary" (click)="saveCaseChanges()"
        [disabled]="!changesMade">
        <mat-icon>save</mat-icon> Save Changes
      </button>
    </div>

    <div class="upload-case-files mb-2">
      <h4 class="mb-2"> Upload Case Files </h4>
      <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload />
      <span class="mb-2">
        {{ fileName || 'No file selected yet' }}
      </span>
      <button *ngIf="!fileName" class="w-100" mat-stroked-button aria-label="Attach File" color="primary"
        (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon> Attach File
      </button>
      <button *ngIf="fileName" class="w-100 mb-3" mat-stroked-button aria-label="Choose a Different File" color="warn"
        (click)="fileUpload.click()">
        <mat-icon>upload</mat-icon> Choose a Different File
      </button>
      <button *ngIf="fileName" class="w-100 mb-3" mat-raised-button aria-label="Complete Upload" color="primary"
        (click)="completeFileUpload()">
        <mat-icon>check</mat-icon> Complete Upload
      </button>
    </div>

    <div class="case-files list" *ngIf="caseFiles && caseFiles.length > 0 && isArray(caseFiles)">
      <h4 class="mb-2">
        Case Files <small>({{ caseFiles.length }} attached)</small>
      </h4>

      <div class="attached-case-files">
        <ng-container *ngFor="let caseFile of caseFiles">
          <div class="download-row">
            <span> {{ caseFile.Name }} </span>
            <button class="ml-2" mat-icon-button color="primary" (click)="getCaseRecordFile(caseFile)"
              matTooltip="Download File">
              <mat-icon>download</mat-icon>
            </button>
            <ng-container *ngIf="caseFile.Category !== 'System' && canDeleteFiles">
              <button class="ml-2" mat-icon-button color="warn" aria-label="Delete Case File"
                (click)="deleteCaseRecordFile(caseFile)" matTooltip="Delete File">
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="col-7 cm-tab-container custom-tabs blue">
    <mat-tab-group>
      <mat-tab label="Notes">
        <div class="chat-center">
          <div class="chat-messages-container">
            <ng-container *ngIf="messages.length !== 0">
              <div class="message-container w-100">
                <div *ngFor="let message of messages; let i = index" class="chat-message" [ngClass]="{
                    incoming: message.CreatedByUserId !== data.defaultUserId,
                    'type-two':
                      message.NoteTypeId === '2' || message.NoteTypeId === 2,
                    hidden:
                      (message.NoteTypeId === '2' ||
                        message.NoteTypeId === 2) &&
                      !isCaseManager,
                    'top-message': i === 0
                  }">
                  <div class="sender-info">
                    {{ message.CreatedByUserName || message.CreatedByUserId }}
                    at
                    {{ message.CreateDate }}
                  </div>
                  <div class="chat-bubble mb-3" *ngIf="editMessageId !== message.NoteId">
                    {{ message.NoteText }}
                    <span class="edit-icon-container">
                      <mat-icon class="edit-icon" (click)="editNote(message)">
                        edit_note
                      </mat-icon>
                    </span>
                  </div>
                  <div class="edit-bubble" *ngIf="editMessageId === message.NoteId">
                    <mat-form-field class="w-20 mr-1" *hasPermission="'CaseManager'">
                      <mat-label>Type</mat-label>
                      <mat-select [(ngModel)]="editNoteType">
                        <mat-option *ngFor="let noteType of noteTypes" [value]="noteType.value">
                          {{ noteType.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="input-container">
                      <input matInput type="text" placeholder="Message" name="Message Input"
                        [(ngModel)]="editMessage" />
                    </div>
                    <mat-icon (click)="submitEdit()">save</mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>

            <div *ngIf="messages.length === 0" class="no-chat-container">
              No messages found
            </div>
          </div>

          <div class="chat-input">
            <form (submit)="submitNote()">
              <!-- <mat-form-field class="w-20 mr-1" *hasPermission="'CaseManager'">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="noteType">
                  <mat-option
                    *ngFor="let noteType of noteTypes"
                    [value]="noteType.value"
                  >
                    {{ noteType.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
              <div class="input-container">
                <textarea matInput placeholder="Message" name="Message Input" [(ngModel)]="message"></textarea>
              </div>
              <button class="mx-2" mat-mini-fab [disabled]="!message" aria-label="Submit Note" color="primary"
                type="submit">
                <mat-icon>send</mat-icon>
              </button>
            </form>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Case History" *ngIf="showCaseHistory">
        <div class="history-center">
          <table class="w-100">
            <thead>
              <ng-container *ngFor="let header of caseHistoryHeaders">
                <th>{{ header.label }}</th>
              </ng-container>
            </thead>
            <tbody>
              <tr *ngFor="let entry of caseHistoryData">
                <ng-container *ngFor="let header of caseHistoryHeaders">
                  <td>{{ entry[header.fieldName] }}</td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: false }"></ngx-loading>