<h5>Filters</h5>
@if (
  filterForm &&
  (filterForm.controls.primaryForm || filterForm.controls.secondaryForm)
) {
  <form [formGroup]="filterForm">
    <div
      *ngFor="let group of formFields | keyvalue"
      formGroupName="{{ group.key }}"
    >
      <mat-form-field class="mr-2 policy-review" *ngIf="showBridgeIdSearch">
        <mat-label>
          <span *ngIf="bridgeIdOptions?.length > 0">
            Policy Review Opportunity
          </span>
          <span *ngIf="bridgeIdOptions?.length <= 0"> Loading... </span>
        </mat-label>
        <mat-select
          [disabled]="
            bridgeIdOptions?.length <= 0 ||
            (bridgeIdOptions?.length > 0 && bridgeIdOptions[0] === 'noOptions')
          "
          (selectionChange)="setBridgeIdSearch($event)"
          [value]="bridgeId"
        >
          <mat-option
            *ngFor="let opt of bridgeIdOptions"
            value="{{ opt.BridgeID }}"
          >
            {{ opt.BridgeName }}
          </mat-option>
        </mat-select>
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          *ngIf="bridgeId"
          (click)="setBridgeIdSearch($event, true)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <ng-container
        *ngFor="let field of group.value"
        [ngSwitch]="field.controlType"
      >
        <mat-form-field *ngSwitchCase="'integer'" class="mr-2">
          <mat-label>{{ field.label }}</mat-label>
          <input
            matInput
            (keyup)="textChange($event, field)"
            [formControlName]="field.varName"
          />
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'currency'" class="mr-2">
          <mat-label>{{ field.label }}</mat-label>
          <input
            matInput
            (keyup)="textChange($event, field)"
            mask="separator.2"
            thousandSeparator=","
            decimalMarker="."
            prefix="$"
            [formControlName]="field.varName"
          />
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'text'" class="mr-2">
          <mat-label>{{ field.label }}</mat-label>
          <input
            matInput
            (keyup)="textChange($event, field)"
            [formControlName]="field.varName"
          />
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'states'" class="mr-2">
          <mat-label>Select States to Filter by</mat-label>
          <mat-select
            multiple
            (selectionChange)="selectionChange($event, field)"
            [formControlName]="field.varName"
          >
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{ state.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'dropdown'" class="mr-2">
          <mat-label>{{ field.label }}</mat-label>
          <mat-select
            (selectionChange)="selectionChange($event, field)"
            [formControlName]="field.varName"
          >
            <mat-option
              *ngFor="let opt of field.options"
              value="{{ opt.value }}"
            >
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            color="warn"
            matTooltip="Clear Filter"
            *ngIf="
              filterForm.value[group.key][field.varName] &&
              filterForm.value[group.key][field.varName].length >= 1
            "
            (click)="clearFilter($event, field, group.key)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'multiselect'" class="mr-2">
          <mat-label>{{ field.label }}</mat-label>
          <mat-select
            multiple
            (selectionChange)="selectionChange($event, field)"
            [formControlName]="field.varName"
          >
            <mat-option
              *ngFor="let opt of field.options"
              value="{{ opt.value }}"
            >
              {{ opt.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="manualSearch">
        <button
          mat-flat-button
          color="primary"
          class="search-button"
          (click)="triggerManualSearch()"
        >
          <mat-icon class="mr-2">search</mat-icon
          >{{ searchButtonText || 'Search Results' }}
        </button>
      </ng-container>
    </div>
  </form>
}

<!-- {{filterForm | json}} -->
