<div
  class="page-container results-container container unified"
  [ngClass]="{ 'se-container': currentApp === 'se' }"
>
  <h1 class="header col-12" *ngIf="currentApp !== 'se'">
    <ng-container *ngIf="currentApp === 'li' || currentApp === 'cp'">
      <button
        mat-button
        class="header-button"
        [disabled]="canCompare()"
        (click)="compare()"
        *ngIf="currentApp !== 'cp'"
      >
        <mat-icon>compare_arrows</mat-icon>
        <span>Compare</span>
      </button>
      <button
        mat-button
        class="header-button mr-2"
        (click)="report()"
        *ngIf="
          (!hidePrint && currentApp !== 'cp') ||
          !rights.includes('DropdownReportMenu')
        "
        [disabled]="
          !selectedProduct || (selectedProduct && selectedProduct.length < 1)
        "
      >
        <mat-icon>insert_drive_file</mat-icon>
        <span>{{ printText }}</span>
      </button>
    </ng-container>
  </h1>

  <breadcrumb *ngIf="currentApp === 'se'" [crumb]="crumbType"></breadcrumb>

  <client-info
    [client]="id"
    [name]="name"
    [dataSet]="seData"
    *ngIf="currentApp === 'se'"
    (profileNavigate)="handleProfileChange($event)"
  ></client-info>
  <div class="w-100 toolbar clearfix">
    <mat-button-toggle-group
      class="view-toggle"
      [(ngModel)]="viewMode"
      *ngIf="currentApp === 'se'"
    >
      <mat-button-toggle value="grid">
        <mat-icon>view_module</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="list">
        <mat-icon>view_list</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <ng-container *ngIf="currentApp !== 'se' && currentApp !== 'li'">
      <button
        mat-raised-button
        class="compare"
        (click)="compare()"
        *ngIf="currentApp !== 'cp'"
      >
        <mat-icon class="mat-icon material-icons">compare_arrows</mat-icon
        >Compare
      </button>
      <button
        mat-raised-button
        class="report"
        (click)="report()"
        *ngIf="!hidePrint && currentApp !== 'cp'"
      >
        <mat-icon class="mat-icon material-icons">insert_drive_file</mat-icon
        >Download report
      </button>
    </ng-container>
    <mat-form-field *ngIf="needsList" class="w-25">
      <mat-select
        placeholder="Filter Products by Need"
        [(ngModel)]="selectedNeed"
      >
        <mat-option value="all"> See All Products </mat-option>
        <mat-option *ngFor="let n of needsList" [value]="n.BridgeID">
          {{ n.BridgeName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    class="products-container {{ viewMode }} {{ currentApp }}"
    *ngIf="currentApp !== 'li'"
  >
    <ul class="products col-12 row" *ngIf="viewMode === 'list'">
      <ng-container *ngFor="let x of products">
        <ng-container *ngIf="x.Hide !== 'Y'">
          <li
            *ngIf="setFiltering(x.NeedBridgeIDs)"
            class="product col-12 {{ x.ScoreClass }}"
            [ngClass]="{ 'se-list-product': currentApp === 'se' }"
          >
            <div class="col-12 row justify-content-between">
              <div
                class="col-10 content"
                [ngClass]="{ 'se-content': currentApp === 'se' }"
              >
                <h4 [ngClass]="{ 'no-pad': currentApp !== 'se' }">
                  <div class="relevance-indicator">
                    <ng-container>
                      <mat-icon *ngIf="x.ScoreClass === 'positive'"
                        >check_circle</mat-icon
                      >
                      <mat-icon *ngIf="x.ScoreClass === 'neutral'"
                        >error</mat-icon
                      >
                      <mat-icon *ngIf="x.ScoreClass === 'negative'"
                        >remove_circle</mat-icon
                      >
                    </ng-container>
                  </div>
                  {{ x.ShortName }}
                  <span *hasPermission="'!RelevanceHideBridgeScore'">
                    | {{ scoreFormat(x.score) }}</span
                  >
                </h4>
                <button
                  class="no-thanks actions se-action-btns"
                  *ngIf="currentApp === 'se'"
                  mat-icon-button
                  matTooltip="No Thank You"
                  matTooltipPosition="above"
                  (click)="
                    bridgeAction('noThanks', x.id, x.name);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>thumb_down</mat-icon>
                </button>
                <button
                  class="follow-up actions se-action-btns"
                  *ngIf="currentApp === 'se'"
                  mat-icon-button
                  matTooltip="Follow Up"
                  matTooltipPosition="above"
                  (click)="
                    bridgeAction('followUp', x.id, x.name);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>update</mat-icon>
                </button>
                <button
                  class="like actions se-action-btns"
                  *ngIf="currentApp === 'se'"
                  mat-icon-button
                  matTooltip="I Like It"
                  matTooltipPosition="above"
                  (click)="
                    bridgeAction('like', x.id, x.name); $event.stopPropagation()
                  "
                >
                  <mat-icon>thumb_up</mat-icon>
                </button>
                <div class="sub">{{ x.subhead }}</div>
              </div>
              <div class="col-1 expand-button">
                <button mat-icon-button (click)="expand($event)">
                  <span class="collapsed-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                  <span class="expanded-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                </button>
              </div>
            </div>
            <div class="plan-grid-table col-11" *ngIf="x.PlanGrid">
              <table class="plan-grid">
                <thead>
                  <tr>
                    <th *ngFor="let h of x.PlanGrid.Header">{{ h }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let t of x.PlanGrid.Data">
                    <td *ngFor="let z of t">{{ z }}</td>
                  </tr>
                </tbody></table
              >
            </div>
            <div class="details w-100 row">
              <h5 class="w-100">Why this product scored this way:</h5>
              <app-reason-text
                class="col-12"
                [ngClass]="{ 'pl-0': currentApp === 'se' }"
                [data]="x.reasons"
              ></app-reason-text>
            </div>
            <div
              class="rating-line {{ x.ScoreClass }}"
              [style.width.%]="scoreFormat(x.score)"
            ></div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
    <ul class="products col-12 row" *ngIf="viewMode === 'grid'">
      <ng-container *ngFor="let x of products; let idx = index">
        <li
          *ngIf="x.Hide !== 'Y'"
          class="product {{ x.ScoreClass }} col-6 row"
          [ngClass]="{ 'se-product': currentApp === 'se' }"
        >
          <div class="se-card-content d-flex flex-column col-12">
            <div class="se-card-item">
              <h4
                class="{{ x.ScoreClass }}"
                [ngClass]="{ 'se-grid-h4 row': currentApp === 'se' }"
              >
                <div class="priority-indicator">
                  <mat-icon>{{ x.icon }}</mat-icon>
                </div>
                {{ x.ShortName }}
                <span *hasPermission="'!RelevanceHideBridgeScore'">
                  | {{ scoreFormat(x.score) }}</span
                >
              </h4>
              <div class="row">
                <div class="col-7 row">
                  <div class="col-12 content" *ngIf="currentApp !== 'se'">
                    <div class="description hidden">{{ x.description }}</div>
                    <ul class="details">
                      <ngTemplate *ngFor="let r of x.reasons; let i = index">
                        <li class="col-12 reason" *ngIf="i < 3">
                          <strong>
                            <span [innerHTML]="r.headline"></span>
                            <span *hasPermission="'!RelevanceHideReasonScores'">
                              | {{ scoreFormat(r.score) }}</span
                            >
                          </strong>
                        </li>
                      </ngTemplate>
                    </ul>
                  </div>
                  <div
                    class="col-12 content"
                    [ngClass]="{ 'se-content': currentApp === 'se' }"
                    *ngIf="currentApp === 'se'"
                  >
                    <div class="description notHidden">{{ x.description }}</div>
                    <ul class="details">
                      <ngTemplate *ngFor="let r of x.reasons; let i = index">
                        <li class="col-12 reason" *ngIf="i < 3">
                          <strong>
                            <span [innerHTML]="r.headline"></span>
                            <span *hasPermission="'!RelevanceHideReasonScores'">
                              | {{ scoreFormat(r.score) }}</span
                            >
                          </strong>
                        </li>
                      </ngTemplate>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-5 {{ x.ScoreClass }}"
                  [ngClass]="{
                    'chart-container': x.formattedChart,
                    'info-container': !x.formattedChart
                  }"
                >
                  <div
                    [chart]="x.formattedChart"
                    *ngIf="x.formattedChart"
                    class="chart-div"
                  ></div>
                  <div
                    *ngIf="!x.formattedChart"
                    class="infographic w-100 {{ x.ScoreClass }}"
                  >
                    <div class="inner">
                      <div
                        class="detail"
                        *ngIf="x.Detail"
                        [ngClass]="{ smaller: x.Detail.length > 6 }"
                      >
                        {{ x.Detail }}
                      </div>
                      <div class="detailName" *ngIf="x.detailname">
                        {{ x.detailname }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="se-card-item mt-auto">
              <div class="row buttons-need w-100 align-self-end">
                <button mat-button color="primary" (click)="details(x)">
                  <span class="collapsed-content">
                    <mat-icon>list_alt</mat-icon>
                    More Details
                  </span>
                </button>
                <button
                  class="no-thanks bridge-actions"
                  mat-icon-button
                  matTooltip="No Thank You"
                  matTooltipPosition="above"
                  (click)="
                    bridgeAction('noThanks', x.id, x.name);
                    $event.stopPropagation()
                  "
                  *ngIf="currentApp === 'se'"
                  color="primary"
                >
                  <mat-icon>thumb_down</mat-icon>
                </button>
                <button
                  class="follow-up bridge-actions"
                  mat-icon-button
                  matTooltip="Follow Up"
                  matTooltipPosition="above"
                  (click)="bridgeAction('followUp', x.id, x.name)"
                  *ngIf="currentApp === 'se'"
                  color="primary"
                >
                  <mat-icon>update</mat-icon>
                </button>
                <button
                  class="like bridge-actions"
                  mat-icon-button
                  matTooltip="I Like It"
                  matTooltipPosition="above"
                  (click)="
                    bridgeAction('like', x.id, x.name); $event.stopPropagation()
                  "
                  *ngIf="currentApp === 'se'"
                  color="primary"
                >
                  <mat-icon>thumb_up</mat-icon>
                </button>
              </div>
              <div class="rating-container row">
                <div
                  class="rating-line {{ x.ScoreClass }}"
                  [style.width.%]="x.score"
                ></div>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="products-container {{ viewMode }} li" *ngIf="currentApp === 'li'">
    <ng-container *ngFor="let category of lifeCategories">
      <h3
        *ngIf="category === 'selected' && selectedProduct.length"
        class="w-50 pull-left"
      >
        Selected Product{{ products.selected.length > 1 ? 's' : '' }}
      </h3>
      <h3
        *ngIf="category === 'perm' && products[category].length"
        class="w-50 pull-left"
      >
        Permanent Product Options
      </h3>
      <h3
        *ngIf="category === 'term' && products[category].length"
        class="w-50 pull-left"
      >
        Term Product Options
      </h3>
      <button
        mat-button
        class="show pull-right"
        color="primary"
        (click)="showLi(category)"
        *ngIf="hiddenItems"
      >
        <span *ngIf="category === 'term' && products[category].length">
          <span *ngIf="!showAllTerm">
            <mat-icon>add_box</mat-icon> Show All
          </span>
          <span *ngIf="showAllTerm">
            <mat-icon>indeterminate_check_box</mat-icon> Show Less </span
          >Term Product Options
        </span>
        <span *ngIf="category === 'perm' && products[category].length">
          <span *ngIf="!showAllPerm">
            <mat-icon>add_box</mat-icon> Show All
          </span>
          <span *ngIf="showAllPerm">
            <mat-icon>indeterminate_check_box</mat-icon> Show Less </span
          >Permanent Product Options
        </span>
      </button>
      <ul
        class="products col-12 row"
        *ngIf="viewMode === 'list'"
        [ngClass]="{
          'perm-product-container':
            category === 'perm' ||
            (category === 'selected' && selectedProduct.length),
          'temp-product-container': category === 'term'
        }"
      >
        <ng-container *ngFor="let x of products[category]">
          <li
            *ngIf="
              (!x.hidden || (x.hidden && x.unhide)) &&
              checkSelected(x, category)
            "
            class="product col-12 {{ x.ScoreClass }}"
            [ngClass]="{ 'se-list-product': currentApp === 'se' }"
            [class.selected]="x.SelectedBridge"
          >
            @if (
              rights.includes('BridgeNotes') &&
              !moduleNotes.include &&
              x.SelectedBridge &&
              (x.vars['CurrentBridgeNote^Required'].raw[0] === 2 ||
                (x.vars['CurrentBridgeNote^Required'].raw[0] === 3 &&
                  x.negativeReason))
            ) {
              <div
                class="notes-notification"
                [ngClass]="{ valid: x.notesValid }"
              >
                @if (x.notesValid) {
                  <mat-icon>thumb_up</mat-icon>Required Notes Entered for This
                  Item
                } @else {
                  <mat-icon>error</mat-icon>Notes Required for This Item
                }
              </div>
            }
            <div class="w100 unified-content">
              <div
                class="type-indicator {{ x.ProductTypeHeader.toLowerCase() }}"
                *ngIf="x.ProductTypeHeader"
              >
                {{ x.ProductTypeHeader }}</div
              >
              <div class="score-icon {{ x.ScoreClass }}">
                <ng-container *hasPermission="'RelevanceBridgeIcon'">
                  <mat-icon *ngIf="x.ScoreClass === 'positive'"
                    >check_circle</mat-icon
                  >
                  <mat-icon *ngIf="x.ScoreClass === 'neutral'">error</mat-icon>
                  <mat-icon *ngIf="x.ScoreClass === 'negative'"
                    >remove_circle</mat-icon
                  >
                </ng-container>
              </div>
              <div class="actions">
                <button
                  mat-button
                  (click)="selectProduct($event, x); $event.stopPropagation()"
                  *ngIf="!x.SelectedBridge"
                >
                  Select
                </button>
                <button
                  mat-button
                  (click)="deselectProduct($event, x); $event.stopPropagation()"
                  *ngIf="x.SelectedBridge"
                >
                  Deselect
                </button>
              </div>

              <div class="content">
                <h4>
                  <span class="product-name">
                    {{ x.ShortName }}
                    <span
                      class="product-score"
                      *hasPermission="'!RelevanceHideBridgeScore'"
                    >
                      | {{ scoreFormat(x.score) }}</span
                    >
                  </span>
                </h4>
                <mat-checkbox
                  [(ngModel)]="compareChecked[x.name]"
                  [ngModelOptions]="{ standalone: true }"
                  (click)="
                    compareToggle($event, x.name, null, x.SelectedBridge);
                    $event.stopPropagation()
                  "
                  [disabled]="x.SelectedBridge"
                >
                  Compare
                </mat-checkbox>
              </div>

              <div class="details-button-container">
                <button mat-icon-button (click)="expand($event)">
                  <span class="collapsed-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                  <span class="expanded-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                </button>
              </div>
            </div>
            <div class="plan-grid-table col-11 mt-4" *ngIf="x.PlanGrid">
              <table class="plan-grid">
                <thead>
                  <tr>
                    <th *ngFor="let h of x.PlanGrid.Header">{{ h }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let t of x.PlanGrid.Data">
                    <td *ngFor="let z of t">{{ z }}</td>
                  </tr>
                </tbody></table
              >
            </div>
            <app-sub-item-chart
              class="mt-4"
              *ngIf="x.ComplianceChart"
              [data]="x.ComplianceChart"
              [chartTitle]="'Regulation Review'"
            ></app-sub-item-chart>

            <div class="details row w-100">
              <h5 class="w-100">Why this product scored this way:</h5>
              <app-reason-text
                class="col-12"
                [data]="x.reasons"
              ></app-reason-text>
              @if (rights.includes('BridgeNotes') && !moduleNotes.include) {
                <app-notes-entry
                  class="w-100 mt-2"
                  [bridgeVal]="x.reasons"
                  (noteEntered)="handleNotesValidation($event, x)"
                  [notesData]="{
                    app: 'li',
                    profile: id,
                    bridgeId: x.vars['CurrentBridgeNote^NoteBridgeID'].raw[0],
                    note: x.vars['CurrentBridgeNote^NoteText'].raw[0],
                    prompt: x.vars['CurrentBridgeNote^Prompt'].raw[0],
                    required:
                      (x.SelectedBridge &&
                        x.vars['CurrentBridgeNote^Required'].raw[0] === 2) ||
                      (x.vars['CurrentBridgeNote^Required'].raw[0] === 3 &&
                        x.negativeReason)
                  }"
                ></app-notes-entry>
              }
            </div>
            <div
              class="rating-line {{ x.ScoreClass }}"
              [style.width.%]="x.score"
            ></div>
          </li>
        </ng-container>
      </ul>
    </ng-container>

    @if (moduleNotes.include && !loading) {
      <app-notes-entry
        class="w-100 mt-2"
        [moduleNotes]="true"
        (noteEntered)="handleNotesValidation($event)"
        [notesData]="{
          app: 'li',
          profile: id,
          note: moduleNotes.note,
          prompt: moduleNotes.prompt,
          required: moduleNotes.required
        }"
        [moduleBridges]="products['selected']"
      ></app-notes-entry>
    }
  </div>
  <p class="disclaimer" *ngIf="productsCharts[0]">{{
    productsCharts[0].Disclaimer
  }}</p>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
