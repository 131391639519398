import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { ReportDialogComponent } from '../../report-dialog/report-dialog.component';
import { SharedReportDialogComponent } from '../report-dialog/report-dialog.component';
import { Chart } from 'angular-highcharts';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { SuggestionEngineService } from '../../suggestion-engine/suggestion-engine.service';
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';
import { TransferService } from '../../services/transfer.service';
import { LifeWizardService } from '../../life-wizard/life-wizard.service';
import { CompareDialogComponent } from '../../compare-dialog/compare-dialog.component';
import { SelectedFunds } from '../../models/li-models';
import { saveAs } from '@progress/kendo-file-saver';
import { PostbackService } from '../../services/postback.service';
import { Platform } from '@angular/cdk/platform';
import * as Highcharts from 'highcharts';
import { EnvironmentService } from '../../services/environment.service';
import { ConsolidatedReportModalComponent } from '../consolidated-report-modal/consolidated-report-modal.component';
import { UnifiedFlowService } from '../../unified-flow/unified.service';
import { map } from 'lodash';

@Component({
  selector: 'app-product-result',
  templateUrl: './product-result.component.html',
  styleUrls: ['./product-result.component.scss'],
})
export class ProductResultComponent implements OnInit, OnDestroy {
  private environment;
  private baseUrl: string;

  id: string;
  chartData;
  products;
  productsCharts = [];
  loading = false;
  name: string;
  hidePrint = false;
  externalLink;
  externalAudit;
  crossApp;
  needsList = null;
  selectedNeed: string;
  path: string;
  crumbType = 'product-results';
  currentApp: string;
  viewMode = 'list';
  seData;
  navigationSubscription;
  paramsSubscription;
  compareList: string[] = [];
  compareChecked = {};
  selectedProduct = [];
  compareTables;
  header = 'Categories';
  showAllPerm = false;
  showAllTerm = false;
  hiddenItems = false;
  calcNeed = false;
  permNeed;
  termNeed;
  showReportNotes = false;
  printPrompt;
  printNote;
  printText = 'Download Report';
  rights = [];
  globals;
  lifeCategories = ['selected', 'perm', 'term'];
  moduleNotes = {
    required: false,
    prompt: '',
    include: false,
    note: '',
  };

  constructor(
    public dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private route: Router,
    private ss: SessionStorageService,
    private tes: TagsEvaluationService,
    private ses: SuggestionEngineService,
    private ts: TransferService,
    private lws: LifeWizardService,
    private pstbk: PostbackService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private unfFlowSvc: UnifiedFlowService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.path = this.environment.assets ? this.environment.assets : '';
    this.rights = this.ss.get('rights');

    this.paramsSubscription = this.params.params.subscribe(params => {
      this.id = params.id;
      this.selectedNeed = params.idx ? params.idx : null;
    });

    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
    });

    this.globals = this.ss.get('globals') || {};

    this.externalLink = this.globals.standardPostBack || null;
    this.externalAudit = this.globals.dynamicPostBack || null;
    this.crossApp = this.globals.crossApp || null;
    this.currentApp = this.ss.get('currentApp');
    this.viewMode = this.currentApp === 'se' ? 'grid' : this.viewMode;
    this.getResults(this.id);
    this.compareTables =
      this.currentApp !== 'se' &&
      this.globals.tables &&
      Array.isArray(this.globals.tables)
        ? this.globals.tables.filter(
            x => x.id === 'compare3' || x.id === 'compare4'
          )
        : null;
    switch (this.currentApp) {
      case 'se':
        this.crumbType = 'se-products-results';
        break;
      case 'li':
        // modified after data retrieval
        this.crumbType = this.calcNeed
          ? 'li-products-results'
          : 'li-products-results-no-needs';
        this.header = 'Considerations';
        break;
      case 'cp':
        this.crumbType = 'cp-products-results';
        break;
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'product');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }

  setFiltering(list) {
    if (this.selectedNeed && list && this.selectedNeed != 'all') {
      return list.includes(this.selectedNeed);
    } else {
      return true;
    }
  }

  getResults(profile) {
    if (this.currentApp == 'se') {
      this.setLoading(true);
      this.loading = true;

      this.products = [];
      this.rbs.getClientSummary(profile).subscribe(data => {
        const parsed = data;
        const prodList = parsed.needs.filter(x => x.icon);
        this.crumbType = 'se-products-results';
        this.seData = { inCallList: data.inCallList, ...data.vars };
        this.hidePrint = true;
        this.name = parsed.FirstName + ' ' + parsed.LastName;
        this.products = prodList.map(el => {
          const x = el;
          if (!x.ShortName && !x.LongName) {
            x.ShortName = x.name;
          }
          x.reasons.forEach(e => {
            e.explanation = this.tes.evalTags(e.explanation);
            e.headline = this.tes.evalTags(e.headline);
          });
          if (x.PlanGrid && x.PlanGrid.length > 0) {
            x.PlanGrid = JSON.parse(x.PlanGrid);
          }
          x['formattedChart'] = x.ChartAttributes
            ? this.buildChart(x.ChartAttributes)
            : null;
          return x;
        });
        this.seData['needs'] = this.products;
        this.setLoading(false);
        this.loading = false;
      });
    } else if (this.currentApp == 'li') {
      this.setLoading(true);

      let permCount = 0;
      let termCount = 0;

      this.products = { selected: [], perm: [], term: [] };
      this.lws.getLifeProducts(profile).subscribe(data => {
        if (data.vars['LifeWizard^Note']) {
          this.moduleNotes.note = data.vars['LifeWizard^Note']['display'][0];
        }
        if (this.globals['GlobalsGroup']['UnitNotesSettings']) {
          this.moduleNotes.include =
            this.globals['GlobalsGroup'].UnitNotesSettings.IncludeSingleLWNote;
          this.moduleNotes.required =
            this.globals['GlobalsGroup'].UnitNotesSettings.SingleLWNoteRequired;
          this.moduleNotes.prompt =
            this.globals['GlobalsGroup'].UnitNotesSettings.SingleLWNotePrompt;
        }

        const prodList = data.LifeProducts.filter(x => {
          if (!x.ShortName && !x.LongName) {
            x.ShortName = x.name;
          }
          return !x.chart;
        });
        this.needsList = data.Needs && data.Needs.length ? data.Needs : null;
        // this.crumbType = this.needsList ? 'cp-products-results' : this.crumbType;

        this.name = data.FirstName + ' ' + data.LastName;

        this.calcNeed = data.vars['IsCalcNeed^Value']
          ? data.vars['IsCalcNeed^Value'].raw[0]
          : this.calcNeed;
        this.permNeed = data.vars['InsuranceNeedAmount^PermShortfall'].raw[0];
        this.termNeed = data.vars['InsuranceNeedAmount^TermShortfall'].raw[0];
        this.crumbType = this.calcNeed
          ? 'li-products-results'
          : 'li-products-results-no-needs';
        this.showReportNotes =
          data.vars['UnitSettings^ShowBeforePrintNote'].raw[0];
        this.printPrompt =
          data.vars['UnitSettings^BeforePrintPrompt'] &&
          data.vars['UnitSettings^BeforePrintPrompt'].display[0]
            ? data.vars['UnitSettings^BeforePrintPrompt'].display[0]
            : this.printPrompt;
        this.printNote =
          data.vars['LifeWizard^BeforePrintNote'] &&
          data.vars['LifeWizard^BeforePrintNote'].display[0]
            ? data.vars['LifeWizard^BeforePrintNote'].display[0]
            : this.printNote;
        this.printText =
          data.vars['UnitSettings^DownloadBtnTxt'] &&
          data.vars['UnitSettings^DownloadBtnTxt'].display[0] &&
          this.currentApp == 'li'
            ? data.vars['UnitSettings^DownloadBtnTxt'].display[0]
            : this.printText;

        prodList.forEach(x => {
          x.negativeReason = false;

          x.reasons.forEach(e => {
            e.explanation = this.tes.evalTags(e.explanation);
            e.headline = this.tes.evalTags(e.headline);
            x.negativeReason =
              e.reasonType == 'Negative' ? true : x.negativeReason;
          });

          x.name = x.LifeItemID;
          if (x.SelectedBridge && x.SelectedBridge.toLowerCase() == 'yes') {
            x.SelectedBridge = true;
            this.selectedProduct.push(x);
            // this.compareChecked[x.name] = true;
            this.compareToggle(null, x.name);
          } else if (
            x.SelectedBridge &&
            x.SelectedBridge.toLowerCase() == 'no'
          ) {
            x.SelectedBridge = false;
          } else {
            x.SelectedBridge = x.vars['IsSelected^SelectedProduct'].raw[0];
            // this.compareChecked[x.name] = true;
            if (x.SelectedBridge) {
              this.selectedProduct.push(x);
              this.compareToggle(null, x.name);
            }
          }

          if (x.isCompare === 'True' || x.isCompare === true) {
            this.compareList.push(x.name);
            this.compareChecked[x.name] = true;
          }

          const requiredNotes =
            x.vars['CurrentBridgeNote^Required'].raw[0] == 2 ||
            (x.vars['CurrentBridgeNote^Required'].raw[0] == 3 &&
              x.negativeReason);
          const notesEntered =
            x.vars['CurrentBridgeNote^NoteText'].raw[0] &&
            x.vars['CurrentBridgeNote^NoteText'].raw[0].length > 0;
          x.notesValid =
            (requiredNotes && notesEntered) || !requiredNotes ? true : false;

          if (!x.notesValid && x.SelectedBridge) {
            this.unfFlowSvc.setNoteValidity({ app: 'li', valid: false });
          }

          if (x.SelectedBridge) {
            this.products.selected.push(x);
          }

          if (x.BridgeType == 'perm') {
            x.hidden = permCount >= data.limit.perm;
            this.hiddenItems = permCount >= data.limit.perm;
            this.products.perm.push(x);
            permCount++;
          } else if (x.BridgeType == 'term') {
            x.hidden = termCount >= data.limit.term;
            this.hiddenItems = termCount >= data.limit.term;
            this.products.term.push(x);
            termCount++;
          }

          if (x.ComplianceChart && x.ComplianceChart.length > 0) {
            x.ComplianceChart = JSON.parse(x.ComplianceChart);
          }
        });

        this.setLoading(false);
      });
    } else {
      this.setLoading(true);

      this.products = [];
      this.rbs.processProducts(profile).subscribe(data => {
        const parsed = data;
        const prodList = parsed.products;
        this.needsList = data.Needs && data.Needs.length ? data.Needs : null;
        this.crumbType = this.needsList
          ? 'cp-products-results'
          : this.crumbType;
        this.hidePrint = prodList.find(
          x => x.name.toLowerCase() == 'dataentryvalidation'
        );
        this.name = parsed.FirstName + ' ' + parsed.LastName;

        this.products = prodList.filter(x => {
          if (!x.ShortName && !x.LongName) {
            x.ShortName = x.name;
          }
          return !x.chart;
        });

        this.products.forEach(x => {
          x.reasons.forEach(e => {
            e.explanation = this.tes.evalTags(e.explanation);
            e.headline = this.tes.evalTags(e.headline);
          });
          if (x.PlanGrid && x.PlanGrid.length > 0) {
            x.PlanGrid = JSON.parse(x.PlanGrid);
          }
        });

        this.productsCharts = prodList.filter(x => {
          return x.chart && x.chart.length;
        });
        this.productsCharts.forEach(x => {
          x.reasons.forEach(e => {
            e.explanation = this.tes.evalTags(e.explanation);
            e.headline = this.tes.evalTags(e.headline);
          });

          x.ChartLegend = x.ChartLegend
            ? JSON.parse(
                x.ChartLegend.replace('header: [', '"header": [').replace(
                  'data: [',
                  '"data": ['
                )
              )
            : null;
          if (x.ChartLegend && !x.ChartLegend.header) {
            x.ChartLegend = x.ChartLegend.filter(z => {
              if (z.label.length > 0) {
                return true;
              }
            });
          }

          x['formattedChart'] = x.ChartAttributes
            ? this.buildChart(x.ChartAttributes)
            : null;

          const chartTable = {};
          const query = 'ChartTable_';
          Object.keys(x).forEach(z => {
            if (
              z.substr(0, query.length).toLowerCase() == query.toLowerCase()
            ) {
              chartTable[z] = x[z];
            }
          });

          if (Object.keys(chartTable).length) {
            x['chartTable'] = chartTable;
          }
        });

        this.setLoading(false);
      });
    }
  }

  bridgeAction(type, id, bridge) {
    if (type === 'like' || type === 'followUp') {
      const dialogRef = this.dialog.open(ActionDialogComponent, {
        panelClass: 'action-dialog',
        data: { id: this.id, bridge: bridge, action: type },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data != 'cancel') {
          const actionDate = new Date(result.data.date);
          const action = {
            records: [
              {
                profileID: this.id,
                bridgeID: bridge,
                actionID: result.data.type,
                note: result.data.notes,
                followUpDate: actionDate.toLocaleDateString('en-US'),
              },
            ],
          };
          this.loading = true;
          this.ses.saveBridgeActions(action).subscribe(x => {
            this.loading = false;
            this.handleProfileChange(this.id);
          });
        }
      });
    } else {
      const action = {
        records: [
          {
            profileID: this.id,
            bridgeID: bridge,
            actionID: 2290,
          },
        ],
      };
      this.loading = true;
      this.ses.saveBridgeActions(action).subscribe(x => {
        this.loading = false;
        this.handleProfileChange(this.id);
      });
    }
  }

  handleProfileChange(ev) {
    this.route.navigate(['/se/client/' + ev]);
    this.id = ev;
    this.getResults(ev);
  }

  checkSelected(product, category) {
    if (
      (category === 'perm' || category === 'term') &&
      this.selectedProduct.find(prod => prod.LifeItemID === product.LifeItemID)
    ) {
      return false;
    }
    return true;
  }

  report() {
    let reportDialog;

    switch (this.currentApp) {
      case 'li':
        reportDialog = this.dialog.open(ReportDialogComponent, {
          panelClass: 'report-dialog',
          data: {
            hideDetails: true,
            showNotes: this.showReportNotes,
            beforePrintPrompt: this.printPrompt,
            printNote: this.printNote,
          },
        });

        reportDialog.afterClosed().subscribe(result => {
          this.setLoading(true);
          const iosSafari =
            this.pltfrm.IOS ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

          if (result && result != 'cancel') {
            const prods = { bridgeIds: [] };
            const selected = [];
            Object.keys(this.products).forEach(el => {
              const found = this.products[el].find(x => {
                return x.SelectedBridge;
              });
              if (found) {
                selected.push(found.vars['CurrentLifeItem^LifeItemID'].raw[0]);
              }
            });
            prods.bridgeIds = selected;
            prods.bridgeIds = prods.bridgeIds.concat(this.compareList);
            prods.bridgeIds = prods.bridgeIds.filter(
              (v, i, a) => a.indexOf(v) === i
            );

            if (result.notes) {
              const data = {};
              data['LifeWizard.BeforePrintNote'] = result.notes;
              this.rbs.saveProfile(this.id, data, false).subscribe(() => {
                this.lws.getReport(this.id, prods).subscribe(data => {
                  const date = new Date();
                  const filename =
                    result.filename && result.filename != null
                      ? result.filename + '.pdf'
                      : 'LifeWizard-' +
                        date.getMonth() +
                        date.getDay() +
                        date.getFullYear() +
                        '.pdf';

                  let fileData;
                  const reader = new FileReader();
                  reader.readAsDataURL(data);
                  reader.onloadend = () => {
                    fileData = reader.result;
                    saveAs(fileData, filename, {
                      proxyURL: `${this.baseUrl}/util/proxy`,
                      proxyTarget: '_self',
                      forceProxy: iosSafari,
                    });
                    this.setLoading(false);
                  };
                });
              });
            } else {
              this.lws.getReport(this.id, prods).subscribe(data => {
                const date = new Date();
                const filename =
                  result.filename && result.filename != null
                    ? result.filename + '.pdf'
                    : 'LifeWizard-' +
                      date.getMonth() +
                      date.getDay() +
                      date.getFullYear() +
                      '.pdf';

                let fileData;
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                  fileData = reader.result;
                  saveAs(fileData, filename, {
                    proxyURL: `${this.baseUrl}/util/proxy`,
                    proxyTarget: '_self',
                    forceProxy: iosSafari,
                  });
                  this.setLoading(false);
                };
              });
            }
          } else {
            this.setLoading(false);
          }
        });
        break;
      case 'cp':
        const needs = this.needsList.map(x => {
          return { name: x.BridgeID, ShortName: x.BridgeName };
        });

        reportDialog = this.dialog.open(SharedReportDialogComponent, {
          panelClass: 'report-dialog',
          data: { app: 'cp', bulk: false, needs: needs },
        });

        reportDialog.afterClosed().subscribe(result => {
          if (result && result.data != 'cancel') {
            const client = [this.id];
            const options = { ...result.data };
            this.setLoading(true);
            const iosSafari =
              this.pltfrm.IOS ||
              (navigator.platform === 'MacIntel' &&
                navigator.maxTouchPoints > 1);

            this.rbs.getClientReport(client, options).subscribe(x => {
              const filename = result.data.filename
                ? result.data.filename + '.pdf'
                : this.name + '-report.pdf';

              let fileData;
              const reader = new FileReader();
              reader.readAsDataURL(x);
              reader.onloadend = () => {
                fileData = reader.result;
                saveAs(fileData, filename, {
                  proxyURL: `${this.baseUrl}/util/proxy`,
                  proxyTarget: '_self',
                  forceProxy: iosSafari,
                });
                this.setLoading(false);
              };
            });
          } else {
            this.setLoading(false);
          }
        });
        break;
      default:
        const chartImages = {};
        this.productsCharts.forEach((z, i) => {
          chartImages['PPImages.Image' + i] =
            z.chartData.ref.container.children[0].outerHTML;
        });

        this.rbs.saveProfile(this.id, chartImages).subscribe(() => {
          reportDialog = this.dialog.open(ReportDialogComponent, {
            panelClass: 'report-dialog',
            data: { hideDetails: true },
          });

          reportDialog.afterClosed().subscribe(result => {
            this.setLoading(true);
            const iosSafari =
              this.pltfrm.IOS ||
              (navigator.platform === 'MacIntel' &&
                navigator.maxTouchPoints > 1);

            if (result && result != 'cancel') {
              this.rbs.getReport(this.id).subscribe(data => {
                const date = new Date();
                const filename =
                  result.filename && result.filename != null
                    ? result.filename + '.pdf'
                    : 'ProductProfiler-' +
                      date.getMonth() +
                      date.getDay() +
                      date.getFullYear() +
                      '.pdf';

                let fileData;
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                  fileData = reader.result;
                  saveAs(fileData, filename, {
                    proxyURL: `${this.baseUrl}/util/proxy`,
                    proxyTarget: '_self',
                    forceProxy: iosSafari,
                  });
                  this.setLoading(false);
                };
              });
            } else {
              this.setLoading(false);
            }
          });
        });
        break;
    }
  }

  selectProduct(ev, product) {
    let existIndex = [];
    this.setLoading(true);
    existIndex = this.selectedProduct.filter((el, i) => {
      if (el.BridgeType == product.BridgeType) {
        return el;
      }
    });
    if (existIndex && existIndex.length > 0) {
      existIndex.forEach(el => {
        this.compareToggle(null, el.name, true);
        this.selectedProduct = this.selectedProduct.filter(
          prod => prod.name != el.name
        );
        this.products['selected'] = this.selectedProduct.filter(
          prod => prod.name != el.name
        );
      });
      this.selectedProduct.push(product);
      this.products['selected'].push(product);
    } else {
      this.selectedProduct.push(product);
      this.products['selected'].push(product);
    }

    const prodList: SelectedFunds = {
      'LISelectedProduct.UniqueCode': [],
      'LISelectedProduct.CarrierCode': [],
      'LISelectedProduct.ProductCode': [],
      'LISelectedProduct.BridgeType': [],
      'LISelectedProduct.LifeItemID': [],
      'LISelectedProduct.Carrier': [],
      'LISelectedProduct.Contract': [],
      'LISelectedProduct.ProductType': [],
      'LISelectedProduct.ScoreClass': [],
      'LISelectedProduct.Amount': [],
      'LISelectedProduct.PPBridge': [],
      'LISelectedProduct.AnnualPremium': [],
    };

    this.selectedProduct.forEach(el => {
      prodList['LISelectedProduct.UniqueCode'].push(
        el.vars['CurrentLifeItem^UniqueCode'].raw[0]
      );
      prodList['LISelectedProduct.CarrierCode'].push(
        el.vars['CurrentLifeItem^CarrierCode'].raw[0]
      );
      prodList['LISelectedProduct.ProductCode'].push(
        el.vars['CurrentLifeItem^ProductCode'].raw[0]
      );
      prodList['LISelectedProduct.BridgeType'].push(el.BridgeType);
      prodList['LISelectedProduct.LifeItemID'].push(
        el.vars['CurrentLifeItem^LifeItemID'].raw[0]
      );
      prodList['LISelectedProduct.Carrier'].push(
        el.vars['CurrentLifeItem^Carrier'].raw[0]
      );
      prodList['LISelectedProduct.Contract'].push(
        el.vars['CurrentLifeItem^Contract'].raw[0]
      );
      prodList['LISelectedProduct.ProductType'].push(
        el.vars['CurrentLifeItem^ProductType'].raw[0]
      );
      prodList['LISelectedProduct.ScoreClass'].push(el.ScoreClass);
      prodList['LISelectedProduct.Amount'].push(
        el.BridgeType == 'perm' ? this.permNeed : this.termNeed
      );
      prodList['LISelectedProduct.PPBridge'].push(
        el.vars['ProfilerMapping^BridgeValue'].raw[0]
      );
    });

    prodList['LifeWizardCompare.CompareID'] = [];

    this.rbs.saveProfile(this.id, prodList, true).subscribe(() => {
      this.products[product.BridgeType.toLowerCase()].forEach(el => {
        el.SelectedBridge =
          el.vars['CurrentLifeItem^LifeItemID'].raw[0] ==
          product.vars['CurrentLifeItem^LifeItemID'].raw[0];
      });
      if (
        this.products[product.BridgeType.toLowerCase()].findIndex(
          el => !el.notesValid && el.SelectedBridge
        ) >= 0 ||
        this.products[product.BridgeType.toLowerCase()].findIndex(
          el => el.notesValid && el.SelectedBridge
        ) === -1
      ) {
        this.unfFlowSvc.setNoteValidity({ app: 'li', valid: false });
      } else {
        this.unfFlowSvc.setNoteValidity({ app: 'li', valid: true });
      }
      this.compareToggle(null, product.name);
      this.setLoading(false);
      this.lws.getLifeProducts(this.id).subscribe(() => {
        console.warn('Profile Updated on Select!');
      });
    });
  }

  deselectProduct(ev, product) {
    let existIndex = [];
    this.setLoading(true);

    this.products[product.BridgeType.toLowerCase()].forEach(el => {
      el.SelectedBridge =
        el.SelectedBridge &&
        el.vars['CurrentLifeItem^LifeItemID'].raw[0] ==
          product.vars['CurrentLifeItem^LifeItemID'].raw[0]
          ? false
          : el.SelectedBridge;
    });

    if (
      this.products[product.BridgeType.toLowerCase()].findIndex(
        el => el.SelectedBridge
      ) === -1
    ) {
      this.unfFlowSvc.setNoteValidity({ app: 'li', valid: true });
    } else if (
      this.products[product.BridgeType.toLowerCase()].findIndex(
        el => !el.notesValid && el.SelectedBridge
      ) >= 0 ||
      this.products[product.BridgeType.toLowerCase()].findIndex(
        el => el.notesValid && el.SelectedBridge
      ) === -1
    ) {
      this.unfFlowSvc.setNoteValidity({ app: 'li', valid: false });
    } else {
      this.unfFlowSvc.setNoteValidity({ app: 'li', valid: true });
    }

    existIndex = this.selectedProduct.filter(el => {
      if (el.BridgeType == product.BridgeType) {
        return el;
      }
    });

    if (existIndex && existIndex.length > 0) {
      existIndex.forEach(el => {
        this.compareToggle(null, el.name, true);
        this.selectedProduct = this.selectedProduct.filter(
          prod => prod.name != el.name
        );
        this.products['selected'] = this.products['selected'].filter(
          prod => prod.name != el.name
        );
      });

      const prodList: SelectedFunds = {
        'LISelectedProduct.UniqueCode': [],
        'LISelectedProduct.CarrierCode': [],
        'LISelectedProduct.ProductCode': [],
        'LISelectedProduct.BridgeType': [],
        'LISelectedProduct.LifeItemID': [],
        'LISelectedProduct.Carrier': [],
        'LISelectedProduct.Contract': [],
        'LISelectedProduct.ProductType': [],
        'LISelectedProduct.ScoreClass': [],
        'LISelectedProduct.Amount': [],
        'LISelectedProduct.PPBridge': [],
        'LISelectedProduct.AnnualPremium': [],
      };

      this.selectedProduct.forEach(el => {
        prodList['LISelectedProduct.UniqueCode'].push(
          el.vars['CurrentLifeItem^UniqueCode'].raw[0]
        );
        prodList['LISelectedProduct.CarrierCode'].push(
          el.vars['CurrentLifeItem^CarrierCode'].raw[0]
        );
        prodList['LISelectedProduct.ProductCode'].push(
          el.vars['CurrentLifeItem^ProductCode'].raw[0]
        );
        prodList['LISelectedProduct.BridgeType'].push(el.BridgeType);
        prodList['LISelectedProduct.LifeItemID'].push(
          el.vars['CurrentLifeItem^LifeItemID'].raw[0]
        );
        prodList['LISelectedProduct.Carrier'].push(
          el.vars['CurrentLifeItem^Carrier'].raw[0]
        );
        prodList['LISelectedProduct.Contract'].push(
          el.vars['CurrentLifeItem^Contract'].raw[0]
        );
        prodList['LISelectedProduct.ProductType'].push(
          el.vars['CurrentLifeItem^ProductType'].raw[0]
        );
        prodList['LISelectedProduct.ScoreClass'].push(el.ScoreClass);
        prodList['LISelectedProduct.Amount'].push(
          el.BridgeType == 'perm' ? this.permNeed : this.termNeed
        );
        prodList['LISelectedProduct.PPBridge'].push(
          el.vars['ProfilerMapping^BridgeValue'].raw[0]
        );
      });

      prodList['LifeWizardCompare.CompareID'] = [];

      this.rbs.saveProfile(this.id, prodList, true).subscribe(() => {
        this.setLoading(false);
        this.lws.getLifeProducts(this.id).subscribe(() => {
          console.warn('Profile Updated on Deselect!');
        });
      });
    } else {
      this.setLoading(false);
    }
  }

  compareToggle(e, fund, remove = false, disabled = false) {
    if (!disabled) {
      const inList = this.compareList.filter(x => {
        return x == fund;
      });
      if (inList && inList.length) {
        this.compareList = this.compareList.filter(x => {
          return x != fund;
        });
        if (!e) {
          this.compareChecked[fund] = false;
        } else {
          this.rbs
            .saveProfile(
              this.id,
              {
                'LifeWizardCompare.CompareID': this.compareList,
              },
              true
            )
            .subscribe();
        }
      } else if (remove) {
        this.compareChecked[fund] = false;
      } else {
        this.compareList.push(fund);

        if (!e) {
          this.compareChecked[fund] = true;
        } else {
          this.rbs
            .saveProfile(
              this.id,
              {
                'LifeWizardCompare.CompareID': this.compareList,
              },
              true
            )
            .subscribe();
        }
      }
    }
  }

  compare() {
    const compareList = [];
    1;
    let combined = [];
    Object.keys(this.products).forEach(el => {
      combined = combined.concat(this.products[el]);
    });
    Object.keys(this.compareChecked).forEach(el => {
      if (this.compareChecked[el]) {
        const prod = combined.find(z => {
          return z.name == el;
        });
        compareList.push(prod);
      }
    });
    const compareData = {
      tables: this.compareTables,
      funds: compareList,
      header: 'Compare Products',
    };

    const dialogRef = this.dialog.open(CompareDialogComponent, {
      panelClass: 'compare-dialog',
      height: '95vh',
      width: '95vw',
      maxHeight: '95vh',
      maxWidth: '95vw',
      data: compareData,
      autoFocus: false,
    });
    dialogRef.afterClosed();
  }

  canCompare() {
    return !Object.keys(this.compareChecked).find(x => this.compareChecked[x]);
  }

  returnToNeeds() {
    let url = '';
    if (this.currentApp == 'li') {
      url = `/li/compare/${this.id}`;
    } else {
      url = `/cp/results/${this.id}`;
    }
    this.route.navigate([url]);
  }

  externalAction() {
    let link = this.externalLink.link || null;
    const silentPrint = this.externalLink.silentPrint,
      pb = this.externalLink.includePostBack || null,
      includePID = this.externalLink.includePID,
      newTab = this.externalLink.RedirectSameTab ? '_self' : '_blank';

    if (includePID) {
      link += this.id;
    }

    if (silentPrint && pb) {
      this.setLoading(true);
      this.rbs.getReport(this.id, false, silentPrint).subscribe(() => {
        this.rbs.postback(this.id).subscribe(() => {
          this.setLoading(false);
          window.open(link, newTab);
        });
      });
    } else if (pb) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        this.setLoading(false);
        window.open(link, newTab);
      });
    } else if (silentPrint) {
      this.setLoading(true);
      this.rbs.getReport(this.id, false, silentPrint).subscribe(() => {
        this.setLoading(false);
        window.open(link, newTab);
      });
    } else {
      window.open(link, newTab);
    }
  }

  externalAuditAction() {
    const silentPrint = this.externalAudit.silentPrint,
      newTab = this.externalLink.RedirectSameTab ? '_self' : '_blank',
      silentPrintPp = this.externalAudit.silentPrintPP;

    this.setLoading(true);

    if (
      this.externalAudit.postBackModalName &&
      this.externalAudit.postBackModalName.length > 0
    ) {
      let dialogRef = this.dialog.open(ConsolidatedReportModalComponent, {
        panelClass: 'consolidated-report',
        data: { profile: this.id, silentPrint: true },
        minWidth: '80vw',
        minHeight: '95vh',
        maxHeight: '95vh',
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        this.rbs.extPostbackData(this.id, result).subscribe(x => {
          if (x && x.results != null) {
            const link = x.results;
            this.setLoading(false);
            window.open(link, newTab);
          } else {
            let dialogRef = this.dialog.open(WarnDialogComponent, {
              panelClass: 'warn-dialog',
              data: {
                headline: 'Warning',
                content:
                  'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                confirm: 'OK',
                hideCancel: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              dialogRef = null;
              this.setLoading(false);
            });
          }
        });
      });
    } else {
      if (silentPrintPp) {
        this.rbs.silentPrintPpReport(this.id).subscribe();
      }

      if (silentPrint) {
        this.setLoading(true);
        this.rbs.getReport(this.id, false, silentPrint).subscribe(() => {
          this.rbs.extPostback(this.id).subscribe(x => {
            if (x && x.results != null) {
              const link = x.results;
              const outsideLink = link.includes('http');

              if (outsideLink) {
                window.open(link, newTab);
              } else {
                this.route.navigateByUrl(link);
              }

              this.setLoading(false);
            } else {
              const message = x.errorMessage
                ? x.errorMessage
                : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

              let dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content: message,
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
                this.setLoading(false);
              });
            }
          });
        });
      } else {
        this.rbs.extPostback(this.id).subscribe(x => {
          if (x && x.results != null) {
            const link = x.results;
            const outsideLink = link.includes('http');

            if (outsideLink) {
              window.open(link, newTab);
            } else {
              this.route.navigateByUrl(link);
            }

            this.setLoading(false);
          } else {
            const message = x.errorMessage
              ? x.errorMessage
              : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

            let dialogRef = this.dialog.open(WarnDialogComponent, {
              panelClass: 'warn-dialog',
              data: {
                headline: 'Warning',
                content: message,
                confirm: 'OK',
                hideCancel: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              dialogRef = null;
              this.setLoading(false);
            });
          }
        });
      }
    }
  }

  scoreFormat(scr) {
    if (this.currentApp == 'cp') {
      const score = scr / 10;
      return score > 100 ? 100 : score;
    }
    return scr > 100 ? 100 : scr;
  }

  buildChart(data) {
    const colorPalette = data.ColorPalette
      ? data.ColorPalette
      : ['#ffcb21', '#f77c00', '#89c540', '#6734ba', '#1194f6'];
    let chartOutput;
    const tableData = [];
    switch (data.ChartType) {
      case '3ddonut':
        data.data.forEach(e => {
          tableData.push([e.name, e.y]);
        });

        chartOutput = new Chart({
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
            },
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: null,
          },
          legend: {
            enabled: true,
            margin: 40,
            itemStyle: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
            itemMarginBottom: 10,
          },
          plotOptions: {
            pie: {
              size: '150%',
              // innerSize: '100%',
              depth: 45,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
          },
          colors: colorPalette,
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>',
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: data.data.name,
              innerSize: '40%',
              data: tableData,
              type: 'pie',
            },
          ],
        });
        break;

      case 'halfDonut':
        data.data.forEach(e => {
          tableData.push([e.name, e.y]);
        });

        chartOutput = new Chart({
          chart: {
            type: 'pie',
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: null,
          },
          legend: {
            enabled: true,
            margin: 40,
            itemStyle: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
            itemMarginBottom: 10,
          },
          plotOptions: {
            pie: {
              size: '150%',
              // innerSize: '100%',
              depth: 45,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
              },
              startAngle: -90,
              endAngle: 90,
              center: ['50%', '75%'],
              showInLegend: true,
            },
          },
          colors: colorPalette,
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>',
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: data.data.name,
              innerSize: '40%',
              data: tableData,
              type: 'pie',
            },
          ],
        });

        // x['chartImage'] = x.chartData.ref.container.children[0].outerHTML;
        break;

      case 'bar':
        chartOutput = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 5,
            },
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: data.title,
          },
          legend: {
            enabled: false,
          },
          xAxis: {
            gridLineColor: '#a9bfda',
            categories: data.XAxisLabel,
            labels: {
              // skew3d: true,
              style: {
                fontSize: '16px',
              },
            },
          },
          yAxis: {
            gridLineColor: '#a9bfda',
            allowDecimals: false,
            min: 0,
            title: {
              text: '',
            },
            stackLabels: {
              enabled: true,
              // borderWidth: 0,
              // format: '{total}',
              formatter: function () {
                return '$' + Highcharts.numberFormat(this.total, 0);
              },
              style: {
                fontWeight: 'bold',
                color: 'black',
                // borderWidth: 0,
                textOutline: null,
              },
            },
          },
          colors: colorPalette,
          tooltip: {
            headerFormat: '',
            pointFormat: '{series.name}: ${point.y}',
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              depth: 40,
            },
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: data.data,
        });
        break;
    }
    return chartOutput;
  }

  details(need) {
    this.ts.setNeedsData(need);
    this.route.navigate([
      `/${this.currentApp}/client/${this.id}/need-detail/${need.name}`,
    ]);
  }

  showLi(key) {
    this.products[key].forEach(el => {
      el.unhide = !el.unhide;
    });
    if (key == 'perm') {
      this.showAllPerm = !this.showAllPerm;
    } else if (key == 'term') {
      this.showAllTerm = !this.showAllTerm;
    }
  }

  goCrossApp(app) {
    const currentApp = this.ss.get('currentApp');
    if (
      !this.crossApp.silentPrint &&
      !this.crossApp.includePostBack &&
      currentApp == 'se'
    ) {
      this.route.navigate([`/${app}/questionnaire/${this.id}`]);
    } else {
      this.setLoading(true);
      this.pstbk
        .crossAppLink(
          this.id,
          currentApp,
          this.crossApp.includePostBack,
          this.crossApp.silentPrint
        )
        .subscribe(x => {
          if (!this.crossApp.silentPrint && !this.crossApp.includePostBack) {
            const blob = x;
            const date = new Date();
            let app;
            if (currentApp === 'cp') {
              app = 'ClientProfiler';
            } else if (currentApp === 'li') {
              app = 'LifeInsuranceWizard';
            } else if (currentApp === 'iw') {
              app = 'InvestmentWizard';
            } else if (currentApp === 'pp') {
              app = 'ProductProfiler';
            } else if (currentApp === 'aw') {
              app = 'AnnuityWizard';
            } else if (currentApp === 'rw') {
              app = 'RolloverWizard';
            } else if (currentApp === 'se') {
              app = 'SuggestionEngine';
            } else {
              app = 'RightBridge';
            }
            const filename = `${currentApp}-${date.getMonth()}${date.getDay()}${date.getFullYear()}.pdf`;
            saveAs(blob, filename);
            this.setLoading(false);
            this.route.navigate([`/${app}/questionnaire/${this.id}`]);
          } else {
            this.setLoading(false);
            this.route.navigate([`/${app}/questionnaire/${this.id}`]);
          }
        });
    }
  }

  handleNotesValidation(ev, item) {
    if (!this.moduleNotes.include) {
      const currentApp = this.ss.get('currentApp');
      const invalidNotes = 0;
      if (currentApp === 'li') {
        map(this.products, product => {
          map(product, bridge => {
            if (bridge.SelectedBridge && !bridge.notesValid) {
              invalidNotes + 1;
            }
          });
        });
      }
      item.notesValid = ev;
      this.unfFlowSvc.setNoteValidity({ app: 'li', valid: invalidNotes === 0 });
    } else {
      this.unfFlowSvc.setNoteValidity({
        app: 'li',
        valid: ev,
      });
    }
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }
}
