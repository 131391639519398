import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../admin.service';
import { isEqual } from 'lodash';
import { VERSION } from '../../../environments/version';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-build-information',
  templateUrl: './build-information.component.html',
  styleUrls: ['./build-information.component.scss'],
})
export class BuildInformationComponent implements OnInit {
  @Input() updateVersions;
  @Input() modalView;

  appFiles = [
    {
      appName: 'pp',
      files: [],
    },
    {
      appName: 'aw',
      files: [],
    },
    {
      appName: 'iw',
      files: [],
    },
    {
      appName: 'li',
      files: [],
    },
    {
      appName: 'rb',
      files: [],
    },
    {
      appName: 'cm',
      files: [],
    },
    {
      appName: 'pm',
      files: [],
    },
  ];
  loadingAppFiles = true;
  newFileDetected = '';
  date = new Date();
  uiVersion: string = VERSION.version + '-' + this.date.toISOString();
  engine: string;
  globals: any;

  constructor(
    private admnSrvc: AdminService,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.getFileVersions();

    const localGlobals = this.ss.get('globals');
    if (localGlobals) {
      this.globals = localGlobals ? localGlobals : {};
      this.engine = this.globals ? this.globals.Engine : null;
    }
  }

  getFileVersions() {
    this.newFileDetected = '';
    this.admnSrvc
      .getCheckSolutions()
      .subscribe(({ Server: { Solutions, SericeName } }) => {
        Solutions.map(solution => {
          const nonSolutionTerms = ['CP', 'Admin'];
          const solutionName = Object.keys(solution)[0];
          const serviceName = SericeName.includes('_')
            ? SericeName.split('_')[1]
            : SericeName;
          const baseSolution = solutionName.replace(serviceName, '');
          if (
            !nonSolutionTerms.includes(baseSolution) &&
            !baseSolution.includes('2')
          ) {
            const appFileIndex = this.appFiles.findIndex(
              x => x.appName === baseSolution.toLowerCase()
            );
            this.appFiles[appFileIndex].files = [];
            if (
              !solution[solutionName].Engines.length ||
              !solution[solutionName].UIConfigs.length
            ) {
              this.newFileDetected = baseSolution.toLowerCase();
            }
            this.appFiles[appFileIndex].files.push(
              {
                label: 'KB',
                fileStatus: solution[solutionName].Engines[0]?.Version,
              },
              {
                label: 'UIConfig',
                fileStatus: solution[solutionName].UIConfigs.find(
                  x =>
                    x.Format === 'JSON' &&
                    x.Type === baseSolution.toLowerCase() + '-json'
                )?.Version,
              },
              {
                label: 'Unit Profile UIConfig',
                fileStatus: solution[solutionName].UIConfigs.find(
                  x =>
                    x.Format === 'JSON' &&
                    x.Type === baseSolution.toLowerCase() + '-unit'
                )?.Version,
              },
              {
                label: 'Shared UIConfig',
                fileStatus: solution[solutionName].UIConfigs.find(
                  x => x.Format === 'JSON' && x.Type === 'shared-json'
                )?.Version,
              }
            );
          }
        });
        this.loadingAppFiles = false;
      });
  }
}
