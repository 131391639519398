import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { OutsideAuthService } from '../services/outside-auth-service.service';
import { FiaComponent } from './fia/fia.component';
import { ViaComponent } from './via/via.component';
import { FaComponent } from './fa/fa.component';
import { SearchComponent } from '../search/search.component';
import { AnnuitiesResultComponent } from './annuities-result/annuities-result.component';
import { AwComparisonComponent } from '../unified-flow/aw-comparison/aw-comparison.component';
import { FormlyComponent } from '../conditional-form/formly.component';
import { ProxyComponent } from '../proxy/proxy.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { AppGlobalsService } from '../services/app-globals.service';
import { HelpComponent } from '../shared-components/help/help.component';
import { RmdCalculationComponent } from '../rmd-calculation/rmd-calculation/rmd-calculation.component';
import { ProductShelfComponent } from './product-shelf/product-shelf.component';

const routes: Routes = [
  {
    path: 'aw',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        redirectTo: '/unified',
        pathMatch: 'full',
        data: {
          breadcrumb: 'Landing',
        },
      },
      {
        path: 'product-shelf',
        component: ProductShelfComponent,
        canActivate: [AuthService],
      },
      {
        path: 'product-shelf/:viewOption',
        component: ProductShelfComponent,
        canActivate: [AuthService],
      },
      {
        path: 'product-shelf/:viewOption/:cusip',
        component: ProductShelfComponent,
        canActivate: [AuthService],
      },
      {
        path: 'product-shelf/:viewOption/:session/:org',
        component: ProductShelfComponent,
        canActivate: [AuthService],
      },
      {
        path: 'product-shelf/:viewOption/:session/:org/:cusip',
        component: ProductShelfComponent,
        canActivate: [AuthService],
      },
      {
        path: 'rila',
        redirectTo: '/aw/product-shelf/rila',
        pathMatch: 'full',
      },
      {
        path: 'rila/:cusip',
        redirectTo: '/aw/product-shelf/rila/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'rila/:session/:org',
        redirectTo: '/aw/product-shelf/rila',
        pathMatch: 'full',
      },
      {
        path: 'rila/:session/:org/:cusip',
        redirectTo: '/aw/product-shelf/rila/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fia',
        redirectTo: '/aw/product-shelf/fia',
        pathMatch: 'full',
      },
      {
        path: 'fia/:cusip',
        redirectTo: '/aw/product-shelf/fia/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fia/:session/:org',
        redirectTo: '/aw/product-shelf/fia',
        pathMatch: 'full',
      },
      {
        path: 'fia/:session/:org/:cusip',
        redirectTo: '/aw/product-shelf/fia/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fa',
        redirectTo: '/aw/product-shelf/fa',
        pathMatch: 'full',
      },
      {
        path: 'fa/:cusip',
        redirectTo: '/aw/product-shelf/fa/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fa/:session/:org',
        redirectTo: '/aw/product-shelf/fa',
        pathMatch: 'full',
      },
      {
        path: 'fa/:session/:org/:cusip',
        redirectTo: '/aw/product-shelf/fa/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'questionnaire',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'questionnaire/:id',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'results/:id',
        component: AnnuitiesResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Results',
        },
      },
      {
        path: 'compare-results',
        component: AwComparisonComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Compare Results',
        },
      },
      {
        path: 'lookup',
        component: ProductSearchComponent,
        canActivate: [AuthService],
      },
      {
        path: 'factsheets',
        component: ProductSearchComponent,
        canActivate: [AuthService],
      },
      {
        path: 'rmd-calculation',
        component: RmdCalculationComponent,
        canActivate: [AuthService],
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthService],
      },
      {
        path: 'proxy',
        component: ProxyComponent,
        canActivate: [AuthService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AwRouting {}
