import { Component, HostBinding, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeat-section',
  styleUrls: ['./form-repeating.component.scss'],
  templateUrl: `./form-repeating.component.html`,
})
export class FormlyRepeatingComponent extends FieldArrayType implements OnInit {
  // TODO: add explicit constructor

  @HostBinding('class') classes = 'w-100 form-section';

  needsRowId = false;
  rowIdPrefix: string;
  tspRowIdPrefix: string;
  idVar: string;
  tspIdVar: string;
  parentIdVar: string;
  tspParentIdVar: string;
  disableAdd = false;

  ngOnInit() {
    this.onPopulate(this.field);

    if (
      this.field.fieldArray['props'].defaultOpen &&
      this.formControl.controls.length == 0
    ) {
      this.add();
    }
    // let fields = this.field.fieldArray.map(x => {console.log(x) ;return x});
    const vars = this.field.fieldArray['fieldGroup'].map(x => x.key);
    const parentVars = this.field.parent.fieldGroup.map(x => x.key);
    this.parentIdVar = parentVars.find((x: string) =>
      x.includes('SourceBridgeID')
    ) as string;

    this.tspParentIdVar = parentVars.find((x: string) =>
      x.includes('TSPSourceID')
    ) as string;

    this.idVar = vars.find((x: string) =>
      x.includes('SourceBridgeID')
    ) as string;
    this.tspIdVar = vars.find((x: string) =>
      x.includes('TSPSourceID')
    ) as string;

    if (this.idVar || this.tspIdVar) {
      this.needsRowId = true;
      this.rowIdPrefix = this.idVar ? this.idVar.split('_')[0] : this.idVar;
      this.tspRowIdPrefix = this.tspIdVar
        ? this.tspIdVar.split('_')[0]
        : this.tspIdVar;
    }

    if (this.field.fieldArray['props'].disableCheck) {
      this.disableAdd = true;
    }

    if (this.needsRowId || this.field.key === 'exchange') {
      this.field.fieldGroup.forEach((group, idx) => {
        group.fieldGroup.forEach(x => {
          if (x.key === 'CurrentAnnuityDetailsArray_IsExchange') {
            group['isExchange'] = x.formControl.value;
          }

          const fieldKey = x.key as string;

          if (fieldKey.includes('SourceBridgeID')) {
            if (!x.formControl.value) {
              x.formControl.setValue(`${this.rowIdPrefix}-${idx}`);
            }
          }

          if (fieldKey.includes('TSPSourceID')) {
            if (!x.formControl.value) {
              x.formControl.setValue(`TSPFundDetails-${idx}`);
            }
          }
        });
      });
    }
  }

  toggleControlSwitch(index) {
    const controlField = this.getField(
      this.field,
      'CurrentAnnuityDetailsArray_IsExchange',
      index
    );
    const theControl = controlField.formControl.value;
    controlField.formControl.setValue(!theControl);
    this.field.fieldGroup[index]['isExchange'] =
      !this.field.fieldGroup[index]['isExchange'];
  }

  add() {
    super.add();

    const row = this.model.length > 0 ? this.model.length - 1 : 0;
    if (this.field.key === 'exchange' && this.field.fieldGroup.length > 0) {
      this.field.fieldGroup[row]['isExchange'] = true;
    }

    if (this.needsRowId) {
      let existingRowValues = this.model.map(x => {
        try {
          if (this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y') {
            return +x[this.tspIdVar].split('_')[1];
          } else {
            return +x[this.idVar].split('_')[1];
          }
        } catch (error) {
          return null;
        }
      });

      existingRowValues = existingRowValues.filter(
        x => x != null || x != undefined
      );

      const rowPrefix =
        this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y'
          ? this.tspRowIdPrefix
          : this.rowIdPrefix;
      const highestIdx =
        existingRowValues.length > 0 ? Math.max(...existingRowValues) : null;
      let newIdx = 0;

      if (this.parentIdVar || this.tspParentIdVar) {
        newIdx = +this.field.parent.key;
      } else {
        newIdx = !highestIdx || row > highestIdx ? row : highestIdx + 1;
      }
      // console.log(row);
      // const parentIdField =
      // this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y'
      //   ? this.field.parent.get(this.tspIdVar)
      //   : this.field.parent.get(this.idVar);

      const parentIdField =
        this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y'
          ? this.getField(this.field, this.tspIdVar, row)
          : this.getField(this.field, this.idVar, row);

      let idValue;

      if (this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y') {
        if (!this.field.parent.model[this.tspParentIdVar]) {
          this.field.parent.model[this.tspParentIdVar] =
            `${rowPrefix}-${newIdx}`;
        }
        idValue = this.tspParentIdVar
          ? `${this.field.parent.model[this.tspParentIdVar]}`
          : `${rowPrefix}-${newIdx}`;
        parentIdField.formControl.setValue(`${rowPrefix}-${newIdx}`);
      } else {
        if (!this.field.parent.model[this.parentIdVar]) {
          this.field.parent.model[this.parentIdVar] = `${rowPrefix}-${newIdx}`;
          parentIdField.formControl.setValue(`${rowPrefix}-${newIdx}`);
        }

        idValue = this.parentIdVar
          ? `${this.field.parent.model[this.parentIdVar]}`
          : `${rowPrefix}-${newIdx}`;
      }

      if (this.needsRowId || this.field.key === 'exchange') {
        this.field.fieldGroup.forEach((group, idx) => {
          group.fieldGroup.forEach(x => {
            const fieldKey = x.key as string;

            if (fieldKey.includes('SourceBridgeID')) {
              if (!x.formControl.value) {
                x.formControl.setValue(`${this.rowIdPrefix}-${idx}`);
              }
            }

            if (fieldKey.includes('TSPSourceID')) {
              if (!x.formControl.value) {
                x.formControl.setValue(`TSPFundDetails-${idx}`);
              }
            }
          });
        });
      }
    }
  }

  remove(index) {
    Object.keys(this.model[index]).forEach(x => {
      if (Array.isArray(this.model[index][x])) {
        this.model[index][x] = [];
      }
    });

    super.remove(index);
  }

  getField(field: FormlyFieldConfig, key, rowIndex: number): FormlyFieldConfig {
    return field.fieldGroup[rowIndex]
      ? field.fieldGroup[rowIndex].fieldGroup.find(f => f.key === key)
      : null;
  }

  containsCurrency(value) {
    if (!isNaN(value)) {
      return false;
    } else {
      return value?.includes('$');
    }
  }
}
