<div class="policy-details-container">
  <button
    mat-flat-button
    color="primary"
    class="launch-aw-button"
    (click)="launchToAW()"
  >
    <mat-icon>rocket_launch</mat-icon>Launch to Annuity Wizard
  </button>
  <mat-tab-group>
    <mat-tab>
      <ng-template matTabLabel>
        <mat-icon class="mr-2">assignment_ind</mat-icon>Policy Summary
      </ng-template>

      <ng-container *ngFor="let policyRow of policyDetails">
        <div class="policy-row mb-4">
          <h4>{{ policyRow.title }}</h4>

          <div class="policy-tables">
            <div
              class="policy-table-container"
              *ngFor="let policyTable of policyRow.tables"
              [class]="getRowWidth(policyRow.tables.length)"
            >
              <ng-container *ngIf="policyTable.horizontal">
                <table class="horizontal">
                  <thead>
                    <th *ngFor="let tableHeader of policyTable.headers">
                      {{ tableHeader.label }}
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tableRow of policyTable.data">
                      <td *ngFor="let tableCell of policyTable.headers">
                        {{ tableRow[tableCell.key] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>

              <ng-container *ngIf="!policyTable.horizontal">
                <table class="vertical">
                  <tbody>
                    <tr *ngFor="let tableRow of policyTable.headers">
                      <th>{{ tableRow.label }}</th>
                      <td>{{
                        policyTable.data[0]
                          ? policyTable.data[0][tableRow.key]
                          : ''
                      }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <mat-icon class="mr-2">account_balance</mat-icon>Policy Review
        Opportunities
      </ng-template>

      <ul class="policies">
        <ng-container *ngFor="let policy of policies">
          <li class="w-100 {{ policy.ScoreClass }} policy">
            <div class="policy-header">
              <span class="score-icon {{ policy.ScoreClass }}">
                <mat-icon *ngIf="policy.ScoreClass === 'positive'">
                  check_circle
                </mat-icon>
                <mat-icon *ngIf="policy.ScoreClass === 'neutral'">
                  error
                </mat-icon>
                <mat-icon *ngIf="policy.ScoreClass === 'negative'">
                  remove_circle
                </mat-icon>
              </span>

              <h4>
                <span>{{
                  policy.outline?.sections?.ShortName || policy.name
                }}</span>
                <span *hasPermission="'!RelevanceHideBridgeScore'">
                  | {{ policy.score }}</span
                >
              </h4>

              <div class="expand-button">
                <button mat-icon-button (click)="expand($event)">
                  <span class="collapsed-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                </button>
              </div>
            </div>

            <div class="policy-body">
              <div class="row">
                <app-reason-text
                  class="col-12"
                  [data]="policy.trueRules"
                ></app-reason-text>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </mat-tab>
  </mat-tab-group>
</div>

<ngx-loading [show]="loading"></ngx-loading>
<span class="policy-loading-text" *ngIf="loading">
  Updating with latest policy information...
</span>
