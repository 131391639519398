import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService } from '../services/session-storage.service';
import { Subject } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.org = this.environment.org;
  }

  private environment;

  private hashFinished = new Subject();
  private org: string;

  hashingDone$ = this.hashFinished.asObservable();

  private clearIwFunds = new Subject();
  clearIWFunds$ = this.hashFinished.asObservable();

  private clear529PickerData = new Subject();
  clear529PickerData$ = this.clear529PickerData.asObservable();

  hashInput(data, hashtype?) {
    if (hashtype && hashtype.toLowerCase() === 'md5') {
      this.hashComplete(true);
      return this.rbs.postRequest(`${this.rbs.baseUrl}/Util/MD5Hash`, {
        value: data,
      });
    } else {
      this.hashComplete(true);
      return this.rbs.postRequest(`${this.rbs.baseUrl}/Util/Hash`, {
        value: data,
      });
    }
  }

  getLinkedCases(profile) {
    return this.rbs.getRequest(`${this.rbs.baseUrl}/Profile/Linked/${profile}`);
  }

  swapInsured(profile, description = '') {
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Profile/Swap/${profile}?insuredSwap=true&profileDescription=${description}`
    );
  }

  hashComplete(cmplt) {
    this.hashFinished.next(cmplt);
  }

  clearIwFundData() {
    this.clearIwFunds.next('clear');
  }

  clear529Picker() {
    const random = Math.floor(Math.random() * 10);
    this.clear529PickerData.next(random);
  }

  saveUnitProfile(unit, data, app, note?) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);
    let url = `${this.rbs.baseUrl}/UnitProfile/UnitProfile?unitId=${unit}`;

    if (note) {
      url += `&note=${note}`;
    }

    return this.rbs.patchRequest(url, data, reqHeaders);
  }
}
