import { Injectable } from '@angular/core';
import { of, Observable, throwError as observableThrowError, } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RightBridgeApiService } from './right-bridge-api.service';

@Injectable({
  providedIn: 'root'
})
export class PostbackService {

  constructor(private rbs: RightBridgeApiService) { }

  crossAppLink(id, app, includePostBack, silentPrint): Observable<any> {
    console.log(id, app, includePostBack, silentPrint);
    const options = {
      threshold: -1000,
      annReview: false,
      needSummary: true,
      prodSummary: true,
      needDetail: true,
      prodDetail: true
    };
    
    if (silentPrint && includePostBack) {
      if(app == 'cp') {
        const profile = [id];
        return this.rbs.getClientReport(profile, options, silentPrint).pipe(
          map(z => {
            this.rbs.postback(id).subscribe();
            return z;
          }),
          catchError((error: any) => {
            return observableThrowError(error.error || 'Server error');
          })
        );
      } else {
        return this.rbs.getReport(id, null, silentPrint).pipe(
          map(z => {
            this.rbs.postback(id).subscribe();
            return z;
          }),
            catchError((error: any) => {
              return observableThrowError(error.error || 'Server error');
            })
        );
      }
    } else if (includePostBack && !silentPrint) {
      return this.rbs.postback(id).pipe(
        map(x => {
          return x;
        }),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        })
      );
    } else if (silentPrint && !includePostBack) {
      if(app == 'cp') {
        const profile = [id];
        return this.rbs.getClientReport(profile, options, silentPrint).pipe(
          map(z => {
          return z;
          }),
          catchError((error: any) => {
            return observableThrowError(error.error || 'Server error');
          })
        );
      } else {
        return this.rbs.getReport(id, null, silentPrint).pipe(
          map(z => {
          return z;
          }),
          catchError((error: any) => {
            return observableThrowError(error.error || 'Server error');
          })
        );
      }
    } else {
      if (app == 'cp') {
        const profile = [id];
        return this.rbs.getClientReport(profile, options, silentPrint).pipe(
          map(z => {
            return z;
          }),
          catchError((error: any) => {
            return observableThrowError(error.error || 'Server error');
          })
        );
      } else {
        return this.rbs.getReport(id, null, silentPrint).pipe(
          map(z => {
            return z;
          }),
          catchError((error: any) => {
            return observableThrowError(error.error || 'Server error');
          })
        );
      }
    }

    return of('failure');
  }
}
