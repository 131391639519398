<div mat-dialog-header>
  <h1>Hierarchy Search</h1>
</div>
<div mat-dialog-content>
  <form>
    <div class="row w-100">
      <ng-container *ngIf="this.rights?.includes('DisplayAndSearchOnUnitId')">
        <div
          class="unit"
          [ngClass]="{
            'col-4': data.mode != 'caseExplorer',
            'col-6': data.mode == 'caseExplorer'
          }"
        >
          <!-- <mat-checkbox [(ngModel)]="unitSearch" (change)="criteriaCheck($event, 'unit')">Unit</mat-checkbox> -->
          <mat-form-field class="col-12">
            <input
              matInput
              placeholder="Select a Unit"
              [matAutocomplete]="unitAuto"
              [formControl]="unitCtrl"
            />
            <mat-autocomplete
              #unitAuto="matAutocomplete"
              (optionSelected)="selectUnit($event)"
              [displayWith]="displayFn"
            >
              <mat-option
                *ngFor="let unit of filteredUnits | async"
                [value]="unit"
                >{{ unit.name }}</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="this.rights?.includes('DisplayAndSearchOnUserId')">
        <div
          class="user"
          [ngClass]="{
            'col-3': data.mode != 'caseExplorer',
            'col-6': data.mode == 'caseExplorer'
          }"
        >
          <!-- <mat-checkbox [(ngModel)]="userSearch" (change)="criteriaCheck($event, 'user')">User</mat-checkbox> -->
          <mat-form-field class="col-12">
            <input
              matInput
              placeholder="Select a User"
              [matAutocomplete]="userAuto"
              [formControl]="userCtrl"
            />
            <mat-autocomplete
              #userAuto="matAutocomplete"
              (optionSelected)="selectUser($event)"
              [displayWith]="displayFn"
            >
              <mat-option
                *ngFor="let user of filteredUsers | async"
                [value]="user"
                >{{ user.name }}</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="this.rights?.includes('DisplayAndSearchOnRepID')">
        <div class="rep col-5" *ngIf="data.mode != 'caseExplorer'">
          <!-- <mat-checkbox [(ngModel)]="repCodeSearch" (change)="criteriaCheck($event, 'rep')">Rep Code</mat-checkbox> -->
          <mat-form-field class="col-12">
            <input
              matInput
              placeholder="Select a Rep"
              [matAutocomplete]="repAuto"
              [formControl]="repCtrl"
            />
            <mat-autocomplete
              #repAuto="matAutocomplete"
              (optionSelected)="selectRep($event)"
              [displayWith]="displayReps"
            >
              <mat-option
                *ngFor="let rep of filteredRepCodes | async"
                [value]="rep"
                >{{ rep.repCode }} - {{ rep.name }} ({{
                  rep.unitName
                }})</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </form>
</div>
<div mat-dialog-actions class="row buttons">
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="accent" (click)="save()">Save</button>
</div>
