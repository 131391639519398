<div class="w-100 file-information" [ngClass]="{ 'modal-view': modalView }">
  <div *ngIf="modalView" class="general-build-info">
    <div class="app-files-container">
      <div class="app-name">UI</div>
      <div class="app-files"> <strong>Version:</strong> {{ uiVersion }} </div>
    </div>
    <div class="app-files-container">
      <div class="app-name">Engine</div>
      <div class="app-files"> <strong>Version:</strong> {{ engine }} </div>
    </div>
  </div>
  <ngx-loading
    [show]="loadingAppFiles && modalView"
    [config]="{
      fullScreenBackdrop: false,
      backdropBackgroundColour: 'rgba(0,0,0,0)'
    }"
  ></ngx-loading>
  <ng-container *ngIf="!loadingAppFiles">
    <div *ngFor="let app of appFiles" class="app-files-container">
      <div class="app-name">
        <span>{{ app.appName.toUpperCase() }}</span>
      </div>
      <div *ngFor="let appFile of app.files" class="app-files">
        <strong>{{ appFile.label }}:</strong> {{ appFile.fileStatus }}
      </div>
    </div>
  </ng-container>
</div>
