<div class="page-container book-management-container container">
  <div class="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    /
    <button mat-button (click)="nav('book-management')" [disabled]="!policyId">
      <span class="text-container">Book Management</span>
    </button>
    <span *ngIf="policyId">/</span>
    <button
      mat-button
      *ngIf="policyId"
      (click)="nav('book-management')"
      [disabled]="policyId"
    >
      <span class="text-container">Policy Details</span>
    </button>
  </div>

  <h1 class="header col-12" [ngClass]="{ 'mb-0': policyId }">
    Annuity Book Management

    <!-- <button
      class="pull-right"
      mat-button
      (click)="nav('unified')"
      color="accent"
      *ngIf="policyId"
      >
      <mat-icon class="mr-2">insert_drive_file</mat-icon> Download Report
    </button> -->
  </h1>
  <ng-container *ngIf="policyId">
    <button
      class="nav-back-button mb-4"
      mat-button
      (click)="nav('book-management')"
      color="primary"
      *ngIf="policyId"
    >
      <mat-icon>arrow_back_ios</mat-icon> Back to Book Management
    </button>
  </ng-container>

  <ng-container *ngIf="!policyId">
    <app-book-management-grid></app-book-management-grid>
  </ng-container>

  <ng-container *ngIf="policyId">
    <app-policy-details [policyId]="policyId"></app-policy-details>
  </ng-container>
</div>
