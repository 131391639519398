<div class="summary-container">
  <ul class="apps col-12 mb-5">
    <ng-container *ngFor="let app of applications; let idx = index">
      <li
        class="app col-12"
        [ngClass]="{
          expanded: app.expanded,
          positive: app.vars.ModuleStatus?.FlowStatus === 'C',
          neutral:
            app.vars.ModuleStatus?.FlowStatus === 'PPF' ||
            app.vars.ModuleStatus?.FlowStatus === 'OR' ||
            app.vars.ModuleStatus?.FlowStatus === 'O',
          negative: app.vars.ModuleStatus?.FlowStatus === 'R'
        }"
        *ngIf="
          app.name !== 'Actions' &&
          app.name !== 'ClientInfo' &&
          app.name !== 'SummaryPage'
        "
      >
        <div class="col-12 row">
          <div class="col-11 content">
            <h4>
              <ng-container *ngIf="app.name.toLowerCase() === 'pp'">
                <mat-icon class="mr-1" svgIcon="pp_icon"></mat-icon>
              </ng-container>
              <ng-container *ngIf="app.name.toLowerCase() === 'aw'">
                <mat-icon class="mr-1" svgIcon="aw_icon"></mat-icon>
              </ng-container>
              <ng-container *ngIf="app.name.toLowerCase() === 'iw'">
                <mat-icon class="mr-1" svgIcon="iw_icon"></mat-icon>
              </ng-container>
              <ng-container *ngIf="app.name.toLowerCase() === 'li'">
                <mat-icon class="mr-1" svgIcon="lw_icon"></mat-icon>
              </ng-container>
              {{ app.outline.sections.ShortName }}
            </h4>
            <span
              class="status right-justify d-flex align-items-center"
              [ngClass]="{
                positive: app.vars.ModuleStatus?.FlowStatus === 'C',
                neutral:
                  app.vars.ModuleStatus?.FlowStatus === 'PPF' ||
                  app.vars.ModuleStatus?.FlowStatus === 'OR' ||
                  app.vars.ModuleStatus?.FlowStatus === 'O',
                negative: app.vars.ModuleStatus?.FlowStatus === 'R'
              }"
            >
              <ng-container *ngIf="app.vars.ModuleStatus?.FlowStatus === 'X'"
                ><mat-icon class="mr-1">check_circle</mat-icon> Not
                Required</ng-container
              >
              <!-- Not Opened -->
              <ng-container *ngIf="app.vars.ModuleStatus?.FlowStatus === 'R'"
                ><mat-icon class="mr-1">remove_circle</mat-icon> Required, Not
                Opened</ng-container
              >
              <!-- Required Not Opened -->
              <ng-container *ngIf="app.vars.ModuleStatus?.FlowStatus === 'PPF'"
                ><mat-icon class="mr-1">error</mat-icon> Run Product Profiler
                First</ng-container
              >
              <!-- Product Profiler First -->
              <ng-container *ngIf="app.vars.ModuleStatus?.FlowStatus === 'C'"
                ><mat-icon class="mr-1">check_circle</mat-icon>
                Complete</ng-container
              >
              <!-- Completed -->
              <ng-container *ngIf="app.vars.ModuleStatus?.FlowStatus === 'OR'"
                ><mat-icon class="mr-1">error</mat-icon> Required, In
                Progress</ng-container
              >
              <!-- Open Required -->
              <ng-container *ngIf="app.vars.ModuleStatus?.FlowStatus === 'O'"
                ><mat-icon class="mr-1">error</mat-icon> In
                Progress</ng-container
              >
              <!-- Opened -->
            </span>
          </div>
          <div
            class="col-1 button d-flex justify-content-center align-items-center"
          >
            <button
              mat-icon-button
              (click)="toggleExpand($event, idx)"
              class="mb-0"
            >
              <span
                class="content-toggle-arrow d-flex justify-content-center align-items-center"
              >
                <mat-icon>expand_circle_down</mat-icon>
              </span>
            </button>
          </div>
        </div>

        <div class="details w-95 clearfix">
          <ng-container
            *ngIf="!app.expanded && app.name.toLowerCase() !== 'iw'"
          >
            <span class="caseCount">
              {{ app.vars.ProductSelected?.length }} Product<ng-container
                *ngIf="
                  app.vars.ProductSelected?.length > 1 ||
                  app.vars.ProductSelected?.length === 0
                "
                >s</ng-container
              >
              Selected
            </span>
            <button
              mat-button
              (click)="completeCase(app.name.toLowerCase())"
              class="pull-right"
              color="primary"
              *ngIf="
                app.vars.ModuleStatus?.FlowStatus === 'OR' ||
                app.vars.ModuleStatus?.FlowStatus === 'O'
              "
              ><mat-icon>arrow_back</mat-icon> Complete Case</button
            >
          </ng-container>
          <ng-container *ngIf="app.expanded">
            <div class="funds-container clearfix">
              <ng-container *ngIf="app.vars.ProductSelected?.length > 0">
                <div
                  *ngFor="let type of app.vars.ProductType; index as i"
                  class="sub-items"
                  [ngClass]="{ 'mb-4': i !== app.vars.ProductType.length - 1 }"
                >
                  <h5 class="mb-1">{{ type.Header }}</h5>
                  <ul class="items-list">
                    <ng-container
                      *ngFor="let item of app.vars[checkProductType(type.Type)]"
                    >
                      <li
                        *ngIf="
                          (checkProductType(type.Type) === 'ProductSelected' &&
                            item.Type.toLowerCase() ===
                              type.Type.toLowerCase()) ||
                          checkProductType(type.Type) !== 'ProductSelected'
                        "
                      >
                        <span
                          class="score-indicator {{ item.ScoreClass }}"
                        ></span
                        >{{ item.Name }}
                        <ng-container
                          *ngIf="item.Amount && item.Amount.length > 0"
                          >&mdash; {{ item.Amount }}</ng-container
                        >
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </ng-container>
            </div>
            <div class="actions-container">
              <button
                mat-button
                color="primary"
                (click)="editInfo(app.name.toLowerCase())"
                *ngIf="app.vars.GeneralSettings.EditInfoShow"
              >
                <mat-icon class="mr-1">edit</mat-icon>
                <ng-container
                  *ngIf="
                    app.vars.GeneralSettings.EditInfoText;
                    else editButtonText
                  "
                  >{{ app.vars.GeneralSettings.EditInfoText }}</ng-container
                >
                <ng-template #editButtonText>Edit Information</ng-template>
              </button>
              <button
                mat-button
                color="primary"
                (click)="viewResults(app.name.toLowerCase())"
                *ngIf="app.vars.GeneralSettings.ViewResultsShow"
              >
                <mat-icon class="mr-1">view_list</mat-icon>
                <ng-container
                  *ngIf="
                    app.vars.GeneralSettings.ViewResultsText;
                    else editButtonText
                  "
                  >{{ app.vars.GeneralSettings.ViewResultsText }}</ng-container
                >
                <ng-template #resultsButtonText>View Results</ng-template>
              </button>
              <button
                mat-button
                color="primary"
                (click)="getReport($event, app.name.toLowerCase())"
                *ngIf="
                  app.name.toLowerCase() === 'aw'
                    ? showPrint[app.name.toLowerCase() + '1'].show
                    : showPrint[app.name.toLowerCase()].show
                "
                [disabled]="
                  app.name.toLowerCase() === 'aw'
                    ? showPrint[app.name.toLowerCase() + '1'].disable
                    : showPrint[app.name.toLowerCase()].disable
                "
              >
                <mat-icon>insert_drive_file</mat-icon>
                {{
                  app.name.toLowerCase() === 'aw'
                    ? showPrint[app.name.toLowerCase() + '1'].text
                    : showPrint[app.name.toLowerCase()].text
                }}
              </button>
              <button
                mat-button
                color="primary"
                (click)="getReport($event, app.name.toLowerCase(), 'awClient')"
                *ngIf="
                  app.name.toLowerCase() === 'aw' &&
                  showPrint[app.name.toLowerCase() + '2'].show
                "
                [disabled]="showPrint[app.name.toLowerCase() + '2'].disable"
              >
                <mat-icon>insert_drive_file</mat-icon>
                {{
                  app.name.toLowerCase() === 'aw'
                    ? showPrint[app.name.toLowerCase() + '2'].text
                    : showPrint[app.name.toLowerCase() + '2'].text
                }}
              </button>
              <button
                mat-button
                color="primary"
                (click)="getReport($event, app.name.toLowerCase(), 'exchange')"
                *ngIf="
                  app.name.toLowerCase() === 'aw' &&
                  showPrint[app.name.toLowerCase() + '3'].show
                "
                [disabled]="showPrint[app.name.toLowerCase() + '3'].disable"
              >
                <mat-icon>insert_drive_file</mat-icon>
                {{
                  app.name.toLowerCase() === 'aw'
                    ? showPrint[app.name.toLowerCase() + '3'].text
                    : showPrint[app.name.toLowerCase() + '3'].text
                }}
              </button>
              <div
                class="action-button-container pt-3 ml-3"
                *ngIf="
                  app.vars.ButtonSettings.ButtonShow ||
                  app.vars.ButtonSettings.ButtonShow2 ||
                  app.vars.ButtonSettings.ButtonShow3
                "
              >
                <button
                  class="mr-2"
                  mat-stroked-button
                  color="primary"
                  (click)="
                    submitCase(
                      $event,
                      app.name.toLowerCase(),
                      app.vars.ButtonSettings
                    )
                  "
                  *ngIf="app.vars.ButtonSettings.ButtonShow"
                  [disabled]="!app.vars.ButtonSettings.ButtonActive"
                >
                  {{ app.vars.ButtonSettings.ButtonText
                  }}<mat-icon>navigate_next</mat-icon>
                </button>
                <button
                  class="mr-2"
                  mat-stroked-button
                  color="primary"
                  (click)="
                    submitCase(
                      $event,
                      app.name.toLowerCase(),
                      app.vars.ButtonSettings,
                      2
                    )
                  "
                  *ngIf="app.vars.ButtonSettings.ButtonShow2"
                  [disabled]="!app.vars.ButtonSettings.ButtonActive2"
                >
                  {{ app.vars.ButtonSettings.ButtonText2
                  }}<mat-icon>navigate_next</mat-icon>
                </button>
                <button
                  class="mr-2"
                  mat-stroked-button
                  color="primary"
                  (click)="
                    submitCase(
                      $event,
                      app.name.toLowerCase(),
                      app.vars.ButtonSettings,
                      3
                    )
                  "
                  *ngIf="app.vars.ButtonSettings.ButtonShow3"
                  [disabled]="!app.vars.ButtonSettings.ButtonActive3"
                >
                  {{ app.vars.ButtonSettings.ButtonText3
                  }}<mat-icon>navigate_next</mat-icon>
                </button>
              </div>
              <div
                class="px-4 instruction-text"
                *ngIf="app.vars.GeneralSettings.ShowInstructionText"
              >
                {{ app.vars.GeneralSettings.InstructionText }}
              </div>
            </div>
          </ng-container>
        </div>
        <div
          class="reasons-container px-5 mb-5"
          *ngIf="app.expanded && app.vars.GeneralSettings.ReasonTextShow"
        >
          <ul class="reason-list">
            <ng-container *ngFor="let reason of app.trueRules">
              <li *ngIf="reason.headline">
                {{ reason.headline }}
              </li>
            </ng-container>
          </ul>
        </div>
      </li>
    </ng-container>
  </ul>
  <div
    class="mb-3 instruction-text"
    *ngIf="buttonInstructions?.ShowInstructionText"
  >
    {{ buttonInstructions.InstructionText }}
  </div>
  <div class="buttons" *ngIf="buttons">
    <button
      mat-raised-button
      class="mr-3"
      color="primary"
      (click)="customReport()"
      *ngIf="buttons.CustomPrintButtonShow"
      [disabled]="!buttons.CustomPrintButtonActive"
    >
      {{ buttons.CustomPrintButtonText }}
    </button>
    <button
      mat-raised-button
      class="mr-3"
      color="primary"
      (click)="consolidatedReport()"
      *ngIf="buttons.ConsolidatedPrintButtonShow"
      [disabled]="!buttons.ConsolidatedPrintButtonActive"
    >
      {{ buttons.ConsolidatedPrintButtonText }}
    </button>
    <button
      mat-raised-button
      class="mr-3"
      color="primary"
      (click)="submitCase($event, 'Actions', buttons)"
      *ngIf="buttons.ButtonShow"
      [disabled]="!buttons.ButtonActive"
    >
      {{ buttons.ButtonText }}
    </button>
    <button
      mat-raised-button
      class="mr-3"
      color="primary"
      (click)="submitCase($event, 'Actions', buttons, 2)"
      *ngIf="buttons.ButtonShow2"
      [disabled]="!buttons.ButtonActive2"
    >
      {{ buttons.ButtonText2 }}
    </button>
    <button
      mat-raised-button
      class="mr-3"
      color="primary"
      (click)="submitCaseManagement()"
      *ngIf="buttons.CMCreateButtonShow"
      [disabled]="!buttons.CMCreateButtonActive || disableCreateCm"
    >
      {{ buttons.CMCreateButtonText }}
    </button>
    <button
      mat-raised-button
      class="mr-3"
      color="primary"
      (click)="launchCaseManagement()"
      *ngIf="buttons.CMLaunchButtonShow || profileCRID"
      [disabled]="!buttons.CMLaunchButtonActive && !profileCRID"
    >
      {{ buttons.CMLaunchButtonText }}
    </button>
  </div>
</div>

<form #samlForm ngNoForm method="POST">
  <input type="hidden" name="SAMLResponse" />
</form>
