import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpParams,
} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
// import { environment } from '../../../environments/environment';
import { SessionStorageService } from '../../services/session-storage.service';
import { EnvironmentService } from '../../services/environment.service';
import { Session } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private environment;
  private baseUrl: string;
  private app: string = this.ss.get('currentApp') || 'pp';
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
  }

  getTemplates(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/Templates`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  updateTemplates(templates): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/Template`;
    // const data = (templates);

    return this.http.patch(url, templates, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  saveNewTemplate(templates): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/Template`;
    // const data = (templates);

    console.log(templates, url, reqHeaders);

    return this.http.post(url, templates, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  deleteTemplate(id): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/Template?templateID=${id}`;
    // const data = (templates);

    return this.http.delete(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  programSearch(nonAdvisory = false): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    let url = `${this.baseUrl}/Investment/AvailablePrograms`;

    if (nonAdvisory) {
      url += '?nonAdvisoryOnly=true';
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  fundSearch(name, program): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const globalFirm = this.ss.get('globals').firmId;
    // We're forcing the firm to the root firm for this call because it broke allstate.
    // The method for setting the firm in rbs was built around the security mutual SSO
    // and works except for producing home office users that have their rep code attached
    // to another unit.
    let url = `${this.baseUrl}/Investment/Symbol?firmId=${globalFirm}&searchString=${name}`;

    if (program) {
      url += `&programId=${program}`;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  copyTemplate(templateId): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/CopyTemplate?templateID=${templateId}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    );
  }
}
