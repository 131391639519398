import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../../services/session-storage.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { EnvironmentService } from '../../services/environment.service';
import { ErrorReportingService } from '../../services/error-reporting.service';

@Injectable({
  providedIn: 'root',
})
export class InvestmentWizardService {
  private environment;
  private baseUrl: string;
  private firm: string = this.ss.get('firm') || null;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
  }

  getTemplates(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/Templates`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  programSearch(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/AvailablePrograms`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  fundFamilyList(programId?): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    this.firm = this.rbs.setFirmId(null, true);
    let url = `${this.baseUrl}/Investment/FundFamilies?firmId=${this.firm}`;

    if (programId) {
      url = url + `&programId=${programId}`;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  fundSearch(
    name,
    program?,
    fams?,
    share?,
    selectionType?,
    fiveTwoNine?
  ): Observable<any> {
    const sess = this.ss.get('session'),
      reqHeaders = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', 'iw')
        .set('X-RB-O', this.org);

    this.firm = this.rbs.setFirmId(null, true);

    let url = `${this.baseUrl}/Investment/Symbol?firmId=${this.firm}`;

    if (name) {
      url += `&searchString=${name}`;
    }

    if (program) {
      url += `&programId=${program}`;
    }

    if (fams) {
      url += `&fundFamilyIds={'fundFamilyIds': [${fams}] }`;
    }

    if (share) {
      url += `&shareClass=${share}`;
    }

    if (fiveTwoNine) {
      url += `&planID=${fiveTwoNine}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  fundInfo(funds): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    this.firm = this.rbs.setFirmId(null, true);
    const url = `${this.baseUrl}/Investment/FundInfo?firmId=${this.firm}&symbols=${funds}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  fundDetails(symbol): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Investment/FundDetails?symbol=${symbol}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getMultiFunds(program, fams, share): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    this.firm = this.rbs.setFirmId(null, true);
    let url = `${this.baseUrl}/Investment/AssetAllocationFunds?firmId=${this.firm}&programId=${program}`;

    if (fams) {
      url += `&fundFamilyIds={'fundFamilyIds': [${fams}] }`;
    }

    if (share) {
      url += `&shareClass=${share}`;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  get529(firmId = false, programId = null): Observable<any> {
    const sess = this.ss.get('session'),
      reqHeaders = new HttpHeaders()
        .set('X-RB-S', sess)
        .set('X-RB-I', 'iw')
        .set('X-RB-O', this.org);
    let url = `${this.baseUrl}/Investment/Get529Plans`;

    if (firmId && !this.firm) {
      this.firm =
        this.firm && this.firm.length ? this.firm : this.rbs.setFirmId(null, true);
    }

    if (firmId && programId) {
      url = url + `?FirmID=${this.firm}&ProgramID=${programId}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  getReport(
    profile,
    details,
    alternatives?,
    alternativesChart?,
    silent = false
  ): Observable<any> {
    const id = this.ss.get('session');
    this.firm =
      this.firm && this.firm.length ? this.firm : this.rbs.setFirmId();
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');

    let url = `${this.baseUrl}/reports/iw/${profile}?firmId=${this.firm}`;

    if (details && details == true) {
      url += '&includeDetail=true';
    }

    if (alternatives && alternatives == true) {
      url += '&includeAlternatives=true';
    }

    if (alternativesChart && alternativesChart == true) {
      url += '&includeFundAlternativesChart=true';
    }

    if (silent) {
      url += '&returnReport=false';
    }

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getDashFundCompareReport(symbol, program, number?): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    this.firm = this.rbs.setFirmId(null, true);
    let url = `${this.baseUrl}/Reports/FundCompare?firmID=${this.firm}&program=${program}&symbol=${symbol}`;

    if (number) {
      url += `&numFunds=${number}`;
    }

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getFundCompareReport(
    symbol,
    program,
    horizon,
    amount,
    alts?,
    family?
  ): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    this.firm = this.rbs.setFirmId(null, true);
    let url = `${this.baseUrl}/Reports/FundCompare?firmID=${this.firm}&program=${program}&symbol=${symbol}&InvestmentAmount=${amount}&TimeHorizon=${horizon}`;

    if (alts) {
      url += `&numFunds=${alts}`;
    }

    if (family) {
      url += `&CurrentlyOwnedFundFamilies=${family}`;
    }

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getPrograms(profile): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/GroupProfile/${profile}?groupName=ProcessFundCategories`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getModels(profile): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/GroupProfile/${profile}?groupName=ProcessModel`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getFundCats(profile): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/GroupProfile/${profile}?groupName=SelectFunds`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  processPortfolio(profile): Observable<any> {
    const globalFirm = this.ss.get('globals').firmId;
    // We're forcing the firm to the root firm for this call because it broke allstate.
    // The method for setting the firm in rbs was built around the security mutual SSO
    // and works except for producing home office users that have their rep code attached
    // to another unit.
    this.rbs.setApp('iw');
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Process/Portfolio/${profile}?firmId=${globalFirm}`,
      null,
      'iw'
    );
  }

  getProductGridSetup(firmId) {
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/investment/GridSetup?firmid=${firmId}`,
      null,
      'iw'
    );
  }

  getProductGrid(queryData?) {
    let url = `${this.rbs.baseUrl}/investment/ProductGrid`;
    if (queryData) {
      url = url + `?query=${queryData}`;
    }
    return this.rbs.getRequest(url, null, 'iw');
  }

  processAlternative(data) {
    return this.rbs.patchRequest(
      `${this.rbs.baseUrl}/GroupProfile?groupName=SelectAlternative`,
      data
    );
  }

  uploadHoldingsFile(fileData) {
    const firmId = this.ss.get('globals').firmId;

    return this.rbs.postRequest(
      `${this.rbs.baseUrl}/Investment/GetBulkSymbol?firmid=${firmId}`,
      fileData
    );
  }
}
