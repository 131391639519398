import { Title } from '@angular/platform-browser';
import {
  NgModule,
  NO_ERRORS_SCHEMA,
  Injectable,
  APP_INITIALIZER,
} from '@angular/core';
import {
  CommonModule,
  PercentPipe,
  DecimalPipe,
  CurrencyPipe,
} from '@angular/common';
import {
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

//Material Imports
// import 'hammerjs';

import { InvestmentWizardModule } from './investment-wizard/investment-wizard.module';
import { AnnuityWizardModule } from './annuity-wizard/annuity-wizard.module';
import { ProductProfilerModule } from './product-profiler/product-profiler.module';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { StrongPasswordModule } from './strong-password/strong-password.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LifeWizardModule } from './life-wizard/life-wizard.module';
import { CaseManagementModule } from './case-management/case-management.module';
import { BookManagementModule } from './book-management/book-management.module';
import { UniversalSearchModule } from './universal-search/universal-search.module';
import { SuggestionEngineModule } from './suggestion-engine/suggestion-engine.module';
import { ReportingModule } from './reporting/reporting.module';
import { DownloadsModule } from './downloads/downloads.module';
import { UnifiedFlowModule } from './unified-flow/unified-flow.module';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { LoginComponent } from './login/login.component';
import { CompareDialogComponent } from './compare-dialog/compare-dialog.component';
import { ModelDialogComponent } from './model-dialog/model-dialog.component';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { PasswordDialogComponent } from './password-dialog/password-dialog.component';
import { CopyDialogComponent } from './copy-dialog/copy-dialog.component';
import { AttestDialogComponent } from './attest-dialog/attest-dialog.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { PostbackThankYouComponent } from './shared-components/postback-thank-you/postback-thank-you.component';
import { ToolsPageComponent } from './tools-page/tools-page.component';

//Services
import { AuthService } from './services/auth-service.service';
import { OutsideAuthService } from './services/outside-auth-service.service';
import { RightBridgeApiService } from './services/right-bridge-api.service';
import { TagsEvaluationService } from './services/tags-evaluation.service';
import { PermissionsService } from './services/permissions.service';
import { AppGlobalsService } from './services/app-globals.service';
import { SsoVerifyService } from './services/sso-verify.service';
import { AppAccessService } from './services/app-access.service';
import { TransferService } from './services/transfer.service';
import { JunkService } from './services/junk.service';
import { ErrorReportingService } from './services/error-reporting.service';

import { AppRouting } from './app.routing';
import { SsoComponent } from './sso/sso.component';
import { EnvironmentService } from './services/environment.service';
import { UploadModule } from '@progress/kendo-angular-upload';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { F5500ProductShelfComponent } from './f5500-product-shelf/f5500-product-shelf.component';
import { FailedSsoComponent } from './failed-sso/failed-sso.component';
import { UserPreferencesService } from './services/user-preferences.service';
import { CustomFiltersService } from './services/custom-filters.service';
import { NavHistoryService } from './services/nav-history.service';
import { ClipboardModule } from 'ngx-clipboard';
import { MatDialogModule } from '@angular/material/dialog';
import {
  provideEnvironmentNgxMask,
  NgxMaskPipe,
  NgxMaskDirective,
} from 'ngx-mask';
import { CaseExplorerComponent } from './case-explorer/case-explorer.component';
import { ProfileHistoryComponent } from './admin/profile-history/profile-history.component';
import { LegacyProfileHistoryComponent } from './admin/legacy-profile-history/legacy-profile-history.component';

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    });
    return next.handle(authReq);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    LoginComponent,
    CompareDialogComponent,
    ModelDialogComponent,
    ReportDialogComponent,
    PasswordDialogComponent,
    SsoComponent,
    CopyDialogComponent,
    AttestDialogComponent,
    F5500ProductShelfComponent,
    FailedSsoComponent,
    PostbackThankYouComponent,
    ToolsPageComponent,
    CaseExplorerComponent,
    ProfileHistoryComponent,
    LegacyProfileHistoryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRouting,
    InvestmentWizardModule,
    AnnuityWizardModule,
    ProductProfilerModule,
    DashboardModule,
    LifeWizardModule,
    CaseManagementModule,
    BookManagementModule,
    UniversalSearchModule,
    SuggestionEngineModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    GridModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '0',
      primaryColour: 'rgb(38, 91, 151)',
      secondaryColour: 'rgba(38, 91, 151, 0.2)',
      tertiaryColour: '#265b97',
    }),
    SharedComponentsModule,
    StrongPasswordModule,
    HighchartsChartModule,
    UploadModule,
    ReportingModule,
    DownloadsModule,
    UnifiedFlowModule,
    ClipboardModule,
    MatToolbarModule,
    MatDialogModule,
    NgxMaskPipe,
    NgxMaskDirective,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [EnvironmentService],
      useFactory: (environmentService: EnvironmentService) => {
        return () => {
          //Make sure to return a promise!
          // console.log('getting env in app');
          return environmentService.loadSettings();
        };
      },
    },
    RightBridgeApiService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'auto',
      },
    },
    AuthService,
    OutsideAuthService,
    TagsEvaluationService,
    Title,
    PermissionsService,
    AppGlobalsService,
    SsoVerifyService,
    AppAccessService,
    TransferService,
    JunkService,
    ErrorReportingService,
    UserPreferencesService,
    CustomFiltersService,
    NavHistoryService,
    PercentPipe,
    DecimalPipe,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
    provideEnvironmentNgxMask(),
    { provide: 'googleTagManagerId', useValue: 'GTM-MDPDKCJ9' },
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
