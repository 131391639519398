import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { SearchComponent } from '../search/search.component';
import { FormlyComponent } from '../conditional-form/formly.component';
import { ProxyComponent } from '../proxy/proxy.component';
import { AppGlobalsService } from '../services/app-globals.service';
import { ResultComponent } from './results/results.component';
import { ScenariosComponent } from './scenarios/scenarios.component';
import { ValidateComponent } from './validate/validate.component';
import { ProductResultComponent } from '../shared-components/product-result/product-result.component';
import { HelpComponent } from '../shared-components/help/help.component';

const routes: Routes = [
  {
    path: 'li',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        redirectTo: '/unified',
        pathMatch: 'full',
        data: {
          breadcrumb: 'Landing',
        },
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'questionnaire',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'questionnaire/:id',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Questionnaire',
        },
      },
      {
        path: 'results',
        component: ResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Results',
        },
      },
      {
        path: 'results/:id',
        component: ResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Results',
        },
      },
      {
        path: 'needs',
        component: ResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Results',
        },
      },
      {
        path: 'needs/:id',
        component: ResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Results',
        },
      },
      {
        path: 'compare',
        component: ScenariosComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Detail',
        },
      },
      {
        path: 'compare/:id',
        component: ScenariosComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Detail',
        },
      },
      {
        path: 'validate',
        component: ValidateComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Detail',
        },
      },
      {
        path: 'validate/:id',
        component: ValidateComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'Detail',
        },
      },
      {
        path: 'products/:id',
        component: ProductResultComponent,
        canActivate: [AuthService],
        data: {
          breadcrumb: 'products',
        },
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthService],
      },
      {
        path: 'proxy',
        component: ProxyComponent,
        canActivate: [AuthService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LiRouting {}
