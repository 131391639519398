import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { MaskService } from '../../services/mask.service';

@Component({
  selector: 'fund-lookup-dialog',
  templateUrl: './fund-lookup-dialog.component.html',
  styleUrls: ['./fund-lookup-dialog.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class FundLookupDialogComponent implements OnInit {
  dollarMask = this.mask.dollarMaskSpecs();
  percentMask = this.mask.percentMaskSpecs();
  percentAllocated: number = 100;
  header;
  grids;
  // allocation;
  // allocationPerc;
  dollarControl = new FormControl();
  percControl = new FormControl();
  investTotal;
  informationOnly = false;

  constructor(public dialogRef: MatDialogRef<FundLookupDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private iws: InvestmentWizardService, private mask: MaskService) {}

  ngOnInit() {
    this.data = this.data.fund ? this.data : {fund: {FundName: null, FundID: null}}
    this.header = `${this.data.fund.FundName} | ${this.data.fund.FundID}`;
    // console.log(this.data);
    this.investTotal = this.data.amount;
    this.iws.fundDetails(this.data.fund.FundID).subscribe(x => {
      // console.log(x);
      this.grids = x.results;
    });
    this.informationOnly = this.data.mode == 'info';

  }


  cancel() {
    this.dialogRef.close({ data: 'cancel' });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

  save() {
    // if(this.allocation) {
    this.data.fund.allocation = this.removePercentMask(this.percControl.value) / 100;
      this.dialogRef.close({ data: {fund: this.data.fund} });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    // }
  }

  updateValues(ev, type, field) {
    const input = field.value;
    let val;
    switch (type) {
      case 'dollar':
        val = (this.removeMoneyMask(input) / this.investTotal) * 100;

        // if (!ev) {
        //   this.dollarControl.setValue(0);
        // }

        if (input === 0 && this.percControl.value != input) {
          this.percControl.setValue(input);
        } else if (val && this.percControl.value != val && !isNaN(val)) {
          this.percControl.setValue(val);
        } else if (!val && this.percControl.value != val) {
          this.percControl.setValue(0);
        }
        // else if (this.percControl.value != 0) {
        //   this.percControl.setValue(0);
        // }
        break;

      case 'perc':
        val = (this.removePercentMask(input) / 100) * this.investTotal;

        // if (!input) {
        //   this.percControl.setValue(0);
        // }

        if (input === 0 && this.dollarControl.value != input) {
          this.dollarControl.setValue(input);
        } else if (val && this.dollarControl.value != val && !isNaN(val)) {
          this.dollarControl.setValue(val);
        } else if (!val && this.dollarControl.value != val) {
          this.dollarControl.setValue(0);
        }
        // else if (this.dollarControl.value != 0) {
        //   this.dollarControl.setValue(0);
        // }
    }
  }


  validateTotal() {
    // let total = this.addEntries();
    // let valid = false;

    // valid = 100 == total;

    // if(valid) {
    //   Object.keys(this.form.controls).forEach(x => {
    //     this.form.controls[x].setErrors(null);
    //     this.form.controls[x].markAsTouched();
    //   });

    //   return { 'totalValid': valid };
    // } else {
    //   Object.keys(this.form.controls).forEach(x => {
    //     this.form.controls[x].setErrors({ 'notEqual': true });
    //     this.form.controls[x].markAsTouched();
    //   });
    // }
  }

  removeMoneyMask(obj) {
    if (obj && typeof obj == 'string') {
      return Number(obj.replace(/\,/gi, '').replace('$', ''));
    } else if (obj && typeof obj == 'number') {
      return obj;
    }
  }

  removePercentMask(obj) {
    if (obj && typeof obj == 'string') {
      return Number(obj.replace(/\,/gi, '').replace('%', ''));
    } else {
      return obj;
    }
  }

}
