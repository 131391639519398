import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { LifeWizardService } from '../life-wizard.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { UnifiedFlowService } from '../../unified-flow/unified.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MaskService } from '../../services/mask.service';
@Component({
  selector: 'results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultComponent implements OnInit {
  rawData = null;
  data = { vars: null };
  needs;
  need;
  dataSet;
  editAmount = true;
  dollarMask = this.mask.dollarMaskSpecs();
  breadcrumb;
  validating;
  id;
  loading = false;
  insuranceNeed;
  continueDisabled = false;
  saveValue: any;

  constructor(
    private params: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private tes: TagsEvaluationService,
    private route: Router,
    private lws: LifeWizardService,
    private unfFlowSvc: UnifiedFlowService,
    private mask: MaskService
  ) {}

  ngOnInit() {
    this.setLoading(true);
    this.params.params.subscribe(params => {
      this.id = params.id;
      this.dataSet = params.dataSet || 'default';

      this.breadcrumb =
        params.validate && params.validate == 'true'
          ? 'validate-results'
          : 'need-results';
      this.validating =
        params.validate && params.validate == 'true' ? params.validate : null;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getNeeds();
    }, 250);
  }

  getNeeds() {
    const saving = this.unfFlowSvc.getSaving();
    if (saving) {
      setTimeout(() => {
        this.getNeeds();
      }, 250);
    } else {
      this.lws.getLifeNeeds(this.id).subscribe(data => {
        const that = this;
        this.data = data;

        this.continueDisabled =
          this.data && this.data.vars
            ? this.removeMoneyMask(
                this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]
              ) < 1
            : false;
        this.insuranceNeed =
          this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0];
        this.continueDisabled =
          +this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0] < 1;

        this.needs = data.Needs;
        this.needs.forEach(x => {
          x.reasons.forEach(y => {
            y.headline = this.tes.evalTags(y.headline);
            y.explanation = this.tes.evalTags(y.explanation);
          });
          if (x.charts) {
            x['formattedCharts'] = [];
            (x.charts as any).forEach((c, i) => {
              if (c.Data.chart.type == 'pie') {
                c.Data.tooltip.formatter = function () {
                  console.log(this);
                  return `${this.key}: <b>${Number(
                    Math.round(Number(this.point.percentage + 'e2')) + 'e-2'
                  )}% | $${that.formatMoney(
                    +that.insuranceNeed * +this.point.y
                  )}</b>`;
                };
              }
              x['formattedCharts'].push(new Chart(c.Data));
            });
          }
        });
        this.insuranceNeed =
          this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0];
        this.continueDisabled =
          +this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0] < 1;
        // console.log(this.data);
        this.setLoading(false);
      });
    }
  }

  compare() {
    console.warn(
      `Edited need, InsuranceNeedAmount.EditedInsuranceNeed: ${this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]}, Calculated by backend, InsuranceNeedAmount.CalculatedShortfall: ${this.data.vars['InsuranceNeedAmount^CalculatedShortfall'].raw[0]}`,
      this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0] !=
        this.data.vars['InsuranceNeedAmount^CalculatedShortfall'].raw[0]
    );
    if (
      this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0] !=
      this.data.vars['InsuranceNeedAmount^CalculatedShortfall'].raw[0]
    ) {
      const saveData = {
        'InsuranceNeedAmount.EditedInsuranceNeed': this.removeMoneyMask(
          this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]
        ),
        'InsuranceNeedAmount.InsuranceNeedEdit': true,
      };
      this.rbs.saveProfile(this.id, saveData).subscribe(x => {
        this.setLoading(true);
        // console.log(x);
        if (this.validating) {
          this.route.navigate(['/li/compare/' + this.id, { validate: true }]);
          this.setLoading(false);
        } else {
          this.route.navigate(['/li/compare/' + this.id]);
          this.setLoading(false);
        }
      });
    } else {
      if (this.validating) {
        this.route.navigate(['/li/compare/' + this.id, { validate: true }]);
      } else {
        this.route.navigate(['/li/compare/' + this.id]);
      }
    }
  }

  editNeedAmount() {
    // console.log(this.insuranceNeed, this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed']);
    this.editAmount = false;
  }

  clearEdit() {
    this.editAmount = true;
    this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0] =
      this.insuranceNeed;

    // this.continueDisabled =
    //   this.removeMoneyMask(
    //     this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]
    //   ) < 1;
    // if (!this.continueDisabled) {
    //   this.saveUpdatedNeed();
    // }
  }

  updateValue(ev) {
    this.continueDisabled =
      this.removeMoneyMask(
        this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]
      ) < 1;

    if (!this.continueDisabled) {
      this.saveUpdatedNeed();
    }
    this.editAmount = !this.editAmount;
  }

  saveUpdatedNeed() {
    this.setLoading(true);
    const saveData = {
      'InsuranceNeedAmount.EditedInsuranceNeed': this.removeMoneyMask(
        this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]
      ),
      'InsuranceNeedAmount.InsuranceNeedEdit': true,
    };
    this.rbs.saveProfile(this.id, saveData).subscribe(x => {
      this.setLoading(false);
    });
    setTimeout(() => {
      this.getNeeds();
    }, 250);
  }

  removeMoneyMask(obj) {
    if (obj) {
      return Number(obj.toString().replace(/\,/gi, '').replace('$', ''));
    }
  }

  scoreFormat(scr) {
    return scr > 100 ? 100 : scr;
  }

  formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(+amount - +i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e);
    }
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }
}
