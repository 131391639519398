import { Component, OnInit, ViewChild } from '@angular/core';
import { switchMap, distinctUntilChanged, map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FundFeeLookupService } from '../fund-fee-lookup.service';
import { FormControl } from '@angular/forms';

import { MaskService } from '../../services/mask.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-fund-fee-dialog',
  templateUrl: './fund-fee-dialog.component.html',
  styleUrls: ['./fund-fee-dialog.component.scss'],
})
export class FundFeeDialogComponent implements OnInit {
  @ViewChild('symbolLookup', { static: false })
  symbolComplete: MatAutocompleteTrigger;
  searchControl = new FormControl();

  loading = false;
  buttonOption;
  options;
  filteredSymbols;
  funds;
  dollarMask = this.mask.dollarMaskSpecs();
  percentMask = this.mask.percentMaskSpecs();
  inputValueChanged: Subject<string> = new Subject<string>();
  entryError = null;
  rows = [];

  columns = [
    {
      DataField: 'FundID',
      Label: 'Symbol',
      ControlType: 'text',
    },
    {
      DataField: 'Name',
      Label: 'Fund Name',
      ControlType: 'text',
    },
    {
      DataField: 'Amount',
      Label: 'Amount',
      ControlType: 'currency',
    },
    {
      DataField: 'ExpenseRatio',
      Label: 'Expense Ratio',
      ControlType: 'perc2',
    },
  ];

  constructor(
    private fs: FundFeeLookupService,
    private mask: MaskService,
    public dialogRef: MatDialogRef<FundFeeDialogComponent>
  ) {}

  ngOnInit(): void {
    this.symbolSearch();
  }

  manualAdd() {
    const blankFund = {
      Name: null,
      FundID: null,
      Amount: null,
      ExpenseRatio: null,
    };

    this.rows.push(blankFund);
  }

  remove(idx, ev) {
    this.rows.splice(idx, 1);
  }

  symbolSearch() {
    this.filteredSymbols = this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(fund => {
        if (fund && fund.length > 2) {
          return this._filterSymbols(fund);
        }
        return [];
      })
    );
  }

  private _filterSymbols(value: string) {
    this.loading = true;
    const list = this.fs.fundSearch(value).pipe(
      map(x => {
        // console.log(x);
        this.loading = false;
        return x.results;
      })
    );
    return list;
  }

  displayFn(option?): string | undefined {
    return option ? `${option.FundID} - ${option.FundName}` : undefined;
  }

  selectFundLookup(select) {
    const fund = {
      Name: select.option.value['FundName'],
      FundID: select.option.value['FundID'],
      Amount: null,
      ExpenseRatio: select.option.value['ExpenseRatio'],
      disable: true,
    };
    // this.filteredSymbols = null;
    this.rows.push(fund);
    this.searchControl.setValue(null);
  }

  setWidth(col) {
    let width;
    switch (col) {
      case 'FundID':
        width = 80;
        break;
      case 'Name':
        width = 190;
        break;
      case 'Amount':
        width = 120;
        break;
      case 'ExpenseRatio':
        width = 70;
        break;
    }

    return width;
  }

  inputChange(ev, rowIdx, source, data, type) {
    this.rows[rowIdx][source] = ev.target.value;
  }

  calculate() {
    this.loading = true;
    this.entryError = null;
    const data = this.rows.map(x => {
      const unmaskedAmount = this.mask.removeMoneyMask(x.Amount);
      if (unmaskedAmount === null || unmaskedAmount === '') {
        this.entryError = 'Please make sure all Amount fields are filled out.';
      }

      if (unmaskedAmount === 0) {
        this.entryError = 'Amount field(s) must be greater than $0.';
      }

      return {
        FundID: x.FundID,
        Amount: unmaskedAmount,
        ExpenseRatio: this.removePercentMask(x.ExpenseRatio),
      };
    });

    if (!this.entryError) {
      const calcData = {
        SelectedFunds: data,
      };

      this.fs.calculate(calcData).subscribe(res => {
        this.loading = false;
        this.dialogRef.close(res.ProposedPortfolioStats);
        this.dialogRef.afterClosed().subscribe(result => {
          this.dialogRef = null;
        });
      });
    } else {
      this.loading = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  removePercentMask(value) {
    return value.replace('%', '');
  }

  borderCheck(rowIdx, datafield) {
    if (
      this.entryError &&
      (this.rows[rowIdx][datafield] === null ||
        this.rows[rowIdx][datafield] === '')
    ) {
      return 'entry-border-error';
    }
    if (
      this.rows[rowIdx][datafield] === null ||
      this.rows[rowIdx][datafield] === ''
    ) {
      return 'entry-border';
    }
    return '';
  }
}
