import { Component, OnInit, Inject, Input} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-consolidated-report-modal',
  templateUrl: './consolidated-report-modal.component.html',
  styleUrls: ['./consolidated-report-modal.component.scss']
})
export class ConsolidatedReportModalComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConsolidatedReportModalComponent>) { }


  ngOnInit(): void {}

  closeModal(ev) {
    // if(this.data.silentPrint) {
      console.log(ev);
    // } else {
    this.dialogRef.close(ev);
    // }
  }

}
