import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { states } from '../../lib/states';
import { MaskService } from '../../services/mask.service';

@Component({
  selector: 'app-calculate-income',
  templateUrl: './calculate-income-dialog.component.html',
  styleUrls: ['./calculate-income-dialog.component.scss'],
})
export class CalculateIncomeDialogComponent implements OnInit {
  @ViewChild('calculateForm', { static: true }) private form: FormGroup;

  dollarMask = this.msk.dollarMaskSpecs();

  modalData = {
    AnnuityWizard: {
      PurchaseType: 1,
      AssessmentType: 1,
      Purpose: 1,
      StateOfIssue: null,
      InitialPremium: null,
      IncomeNeeded: null,
      JointProtection: null,
      AgeNeeded: null,
      RiskTolerance: 5,
      SolveType: 'F',
      QualifiedInitialPremium: 'N',
    },
    ClientEstimates: {
      Age: null,
    },
    SpouseEstimates: {
      Age: null,
      Gender: null,
    },
    ClientPerson: {
      Gender: null,
    },
    Client: {
      ID: 'TestCase',
    },
  };
  states = states;
  invalidAfterSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<CalculateIncomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private msk: MaskService
  ) {}

  ngOnInit(): void {
    if (this.data && typeof this.data != 'undefined') {
      this.modalData = { ...this.data };
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  save() {
    if (!this.form.valid) {
      Object.keys(this.form['form'].value).map(val => {
        if (!this.form['form'].value[val]) {
          this.invalidAfterSubmit = true;
          this.form['form'].controls[val].markAsTouched();
        }
      });
    }

    if (this.form.valid) {
      this.modalData.AnnuityWizard.InitialPremium = this.msk.removeMoneyMask(
        this.modalData.AnnuityWizard.InitialPremium
      );
      this.dialogRef.close(this.modalData);
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
  }
}
