<mat-drawer-container>
  @if (loggedIn) {
    <mat-toolbar
      color="primary"
      class="unified-header"
      [class.proxy-active]="proxyActive"
    >
      @if (rights.includes('ToolsLandingPage')) {
        <button class="home-button" (click)="goToPage('/tools')">
          <img src="{{ './assets/' + logoName }}" class="unified-logo" />
        </button>
      } @else {
        <button class="home-button" (click)="goToPage('/unified')">
          <img src="{{ './assets/' + logoName }}" class="unified-logo" />
        </button>
      }

      <button
        mat-button
        *ngIf="proxyActive"
        class="proxy"
        (click)="endProxy()"
        [matTooltip]="
          user.firstname +
          ' ' +
          user.lastname +
          ' Currently proxying for: ' +
          proxyData.name +
          ' click to end.'
        "
      >
        <mat-icon>co_present</mat-icon>: {{ proxyData.name }}
      </button>
      <button mat-button [matMenuTriggerFor]="userMenu" class="menu-button">
        <span class="username">{{ user?.firstname }} {{ user?.lastname }}</span>
        <mat-icon>account_circle</mat-icon>
      </button>

      <mat-menu
        #userMenu="matMenu"
        direction="down"
        yPosition="below"
        class="unified-menu"
      >
        <button
          mat-menu-item
          id="admin-item"
          *ngIf="isAdmin"
          [matMenuTriggerFor]="adminMenu"
        >
          <mat-icon>admin_panel_settings</mat-icon> Admin
        </button>
        <button
          mat-menu-item
          id="reporting-item"
          *ngIf="reporting"
          routerLink="/reporting"
        >
          <mat-icon>assessment</mat-icon>
          Reporting
        </button>
        <button
          mat-menu-item
          id="proxy-item"
          *ngIf="ProxyinMenu && !proxyActive"
          (click)="proxy()"
        >
          <mat-icon>co_present</mat-icon> Proxy
        </button>
        <button
          mat-menu-item
          id="end-proxy-item"
          *ngIf="proxyActive"
          (click)="endProxy()"
        >
          <mat-icon>dangerous</mat-icon> End Proxy
        </button>
        <button
          mat-menu-item
          id="tools-item"
          routerLink="/tools"
          *hasPermission="'ToolsLandingPage'"
        >
          <mat-icon>handyman</mat-icon> Tools
        </button>
        <button
          mat-menu-item
          *ngIf="displayHelp && displayHelpMenu"
          (click)="getHelp(null, 'rb')"
          [matMenuTriggerFor]="helpMenu"
        >
          <mat-icon>help</mat-icon> Help
        </button>
        <button
          mat-menu-item
          *ngIf="displayHelp && !displayHelpMenu"
          [ngClass]="{ disabled: noLink }"
          (click)="getHelp(null, 'rb')"
        >
          <mat-icon>help</mat-icon> Help
        </button>
        <button mat-menu-item *ngIf="isAdmin" (click)="openTechNotes()">
          <mat-icon>edit_note</mat-icon> Tech Notes
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon> Logout
        </button>
      </mat-menu>

      <mat-menu #adminMenu="matMenu">
        <button
          mat-menu-item
          *ngIf="isAdmin && rights.includes('RolesAndRights')"
          routerLink="/admin/roles-rights"
        >
          <mat-icon>lock_person</mat-icon>Roles/Rights
        </button>
        <button
          mat-menu-item
          *ngIf="isAdmin && rights.includes('UnitProfile')"
          routerLink="/admin/unit-profile"
        >
          <mat-icon>source</mat-icon>Unit Profiles
        </button>
        <button
          mat-menu-item
          *ngIf="isAdmin && rights.includes('RepManagement')"
          routerLink="/admin/rep-management"
        >
          <mat-icon>perm_contact_calendar</mat-icon>Rep Management
        </button>
        <button
          mat-menu-item
          *ngIf="isAdmin && rights.includes('UserManagement')"
          routerLink="/admin/user-management"
        >
          <mat-icon>people_alt</mat-icon>User Management
        </button>
        <button
          mat-menu-item
          *ngIf="isAdmin && rights.includes('Unit')"
          routerLink="/admin/unit-management"
        >
          <mat-icon>home_work</mat-icon>Unit Management
        </button>
        <button
          mat-menu-item
          *ngIf="isAdmin && rights.includes('KnowledgeBase')"
          routerLink="/admin/uploads"
        >
          <mat-icon>upload</mat-icon>Uploads
        </button>

        @if (isAdmin && rights.includes('LegacyReport')) {
          <div>
            <form
              ngNoForm
              method="post"
              action="{{ ceteraAction }}"
              enctype="multipart/form-data"
            >
              <input type="text" hidden name="X_RB_u" value="{{ session }}" />
              <button mat-menu-item type="submit"
                ><mat-icon>collections_bookmark</mat-icon>Legacy Reports</button
              >
            </form>
          </div>
        }

        <button
          mat-menu-item
          *ngIf="isAdmin && rights.includes('Toolbox')"
          routerLink="/admin/toolbox"
        >
          <mat-icon>home_repair_service</mat-icon>Toolbox
        </button>
      </mat-menu>

      <mat-menu #helpMenu="matMenu">
        <button
          mat-menu-item
          *ngIf="displayHelp && rights.includes('PPHelp')"
          (click)="
            getHelp('https://www.help.rightbridge.net/productprofiler', 'pp')
          "
        >
          <mat-icon class="mr-0" svgIcon="pp_icon"></mat-icon> Product Profiler
        </button>
        <button
          mat-menu-item
          *ngIf="displayHelp && rights.includes('AWHelp')"
          (click)="
            getHelp('https://www.help.rightbridge.net/annuitywizard', 'aw')
          "
        >
          <mat-icon class="mr-0" svgIcon="aw_icon"></mat-icon> Annuity Wizard
        </button>
        <button
          mat-menu-item
          *ngIf="displayHelp && rights.includes('IWHelp')"
          (click)="getHelp(null, 'iw')"
        >
          <mat-icon class="mr-0" svgIcon="iw_icon"></mat-icon> Investment Wizard
        </button>
        <button
          mat-menu-item
          *ngIf="displayHelp && rights.includes('LIHelp')"
          (click)="getHelp(null, 'li')"
        >
          <mat-icon class="mr-0" svgIcon="lw_icon"></mat-icon> Life Insurance
          Wizard
        </button>
      </mat-menu>
    </mat-toolbar>
  }
  <router-outlet></router-outlet>
  @if (loggedIn) {
    <app-footer></app-footer>
  }
</mat-drawer-container>
