<div class="page-container asset-container container">
  <breadcrumb [crumb]="'product-results'"></breadcrumb>
  <h1 class="header col-12">
    Results for {{name}}
    <app-print-dropdown class="pull-right" [params]="{profile: id}" [isRollover]="isRolloverCase" (standardReport)="handleReportEvent($event)" *hasPermission="'DropdownReportMenu'"></app-print-dropdown>
  </h1>

  <div class="w-100 toolbar">
    <ng-container *ngIf="!rights?.includes('DropdownReportMenu')" >
      <button mat-raised-button class="report" (click)="report()" *ngIf="!hidePrint">
        <mat-icon class="mat-icon material-icons">insert_drive_file</mat-icon>Download Report
      </button>
    </ng-container>
  </div>
  <!-- {{realData.ShortName}} -->
  <div class="products-container" *ngIf="assessmentTypes.length < 1">
    <ul class="products col-12">
      <li *ngFor="let x of products" class="product col-12 {{x.ScoreClass}}">
        <div class="col-12 row">
          <div class="col-10 content">
            <ng-container *hasPermission="'RelevanceBridgeIcon'">
              <mat-icon *ngIf="x.ScoreClass == 'positive'">check_circle</mat-icon>
              <mat-icon *ngIf="x.ScoreClass == 'neutral'">error</mat-icon>
              <mat-icon *ngIf="x.ScoreClass == 'negative'">remove_circle</mat-icon>
            </ng-container>
            <h4>
              {{x.ShortName}} <span *hasPermission="'!RelevanceHideBridgeScore'"> | {{x.score / 10}}</span>
            </h4>
            <div class="sub">{{x.subhead}}</div>
          </div>
          <div class="col-2 button">
            <button mat-button (click)="expand($event);">
              <span class="collapsed-content">
                <mat-icon>add_circle</mat-icon> Details
              </span>
              <span class="expanded-content">
                <mat-icon>remove_circle</mat-icon> Close
              </span>
            </button>
          </div>
        </div>
        <div class="plan-grid-table col-11" *ngIf="x.PlanGrid">
          <table class="plan-grid">
            <thead>
              <tr>
                <th *ngFor="let h of x.PlanGrid.Header;">{{h}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let t of x.PlanGrid.Data;">
                <td *ngFor="let z of t">{{z}}</td>
              </tr>
          </table>
        </div>
        <div class="details col-12">
          <!-- Need to Fix this left align conditional if we get more tabs than the grid -->
          <mat-tab-group mat-stretch-tabs (selectedTabChange)="infoTabChange($event)" [ngClass]="{'left-align': !x.grids || x.grids.length <= 0}">
            <mat-tab label="Reasons">
              <app-sub-item-chart *ngIf="x.ComplianceChart" [data]="x.ComplianceChart" [chartTitle]="'Compliance Information'">
              </app-sub-item-chart>
              <app-reason-text class='col-6' [data]='x.reasons'></app-reason-text>
            </mat-tab>
            <mat-tab label="Product Summary" *ngIf="x.grids && x.grids.length > 0">
              <div class="grid-table w-100" *ngFor="let gr of x.grids">
                <div class="table-header w-100">{{gr.title}}</div>
                <table [ngClass]="{'w33': gr.rows.length == 3, 'w50': gr.rows.length == 2, 'w100': gr.rows.length == 1}" *ngFor="let tb of gr.rows">
                  <tr *ngFor="let rw of tb.table">
                    <td [innerHTML]="rw.label"></td>
                    <td [innerHTML]="rw.value"></td>
                  </tr>
                </table>
              </div>
            </mat-tab>
            <mat-tab label="{{x.bridgeCharts.Label}}" *ngIf="x.bridgeCharts && x.bridgeCharts.Bridges">
              <!-- {{x.bridgeCharts.Bridges.length}} -->
              <!-- <pre>{{x.bridgeCharts.Bridges | json}}</pre> -->
              <ng-container *ngFor="let chartGroup of x.bridgeCharts.Bridges">
                <div *ngIf="chartGroup" class="chart-container clearfix mb-5">
                  <ng-container *ngFor="let chrt of chartGroup.charts; let idx = index;">
                    <h4 class="chart-header">{{chartGroup.header}}</h4>
                    <div class="chart pull-left w-50 px-4">
                      <!-- <pre>{{chrt | json}}</pre> -->
                      <highcharts-chart [Highcharts]="Highcharts" [options]="chrt.Data" class="w-90 mx-auto"></highcharts-chart>
                      <div *ngIf="chrt.Legend" class="chart-legend" [ngClass]="{'col-12': chrt.LegendPosition == 'bottom', 'col-6 ml-auto': chrt.LegendPosition == 'right'}">
                        <ng-container *ngIf="chrt.Legend.header; else noHeader">
                          <table>
                            <thead>
                              <tr>
                                <ng-container *ngFor="let th of chrt.Legend.header">
                                  <th *ngIf="th.toLowerCase() != 'color'">
                                    {{th}}
                                  </th>
                                </ng-container>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let tr of chrt.Legend.data">
                                <ng-container *ngFor="let td of tr | keyvalue : returnZero; let i = index">
                                  <td *ngIf="chrt.Legend.header[i].toLowerCase() != 'color'">
                                    <span
                                      *ngIf="chrt.Legend.header[i].toLowerCase() != 'color' && chrt.Legend.header[0].toLowerCase() === 'color' && i == 1"
                                      [style.background]="tr['Color']" class="indicator"></span>
                                    {{td.value}}
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>
                        <ng-template #noHeader>
                          <div *ngFor="let c of chrt.Legend">
                            <strong>{{c.label}}</strong> {{c.value}}</div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="explanation-text"
                      [ngClass]="{'pull-left w-50': chartGroup.charts.length == 1, 'w-100': chartGroup.charts.length > 1}"
                      *ngIf="chartGroup.charts.length == 1 || chartGroup.charts.length > 1 && idx == 1">
                      <p class="mx-auto mt-3" *ngIf="chartGroup.ShortDescription">{{chartGroup.ShortDescription}}</p>
                      <ul class="criteria-list mt-3" *ngIf="chartGroup.reasons">
                        <li *ngFor="let r of chartGroup.reasons" class="mt-2">
                          <span [innerHTML]="r.headline"></span>
                          <span *hasPermission="'!RelevanceHideReasonScores'"> | {{r.score / 10}}</span>
                        </li>
                      </ul>
                      <p class="mx-auto mt-3" *ngIf="chartGroup[x.dynamicContent]">{{x[x.dynamicContent]}}</p>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="rating-line {{x.ScoreClass}}" [style.width.%]="x.score/10"></div>
      </li>
    </ul>
  </div>
  <div class="products-container" *ngIf="assessmentTypes.length > 0">
    <ng-container *ngFor="let grp of assessmentTypes">
      <h2 *ngIf="grp == 'validate'">Products Validated</h2>
      <h2 *ngIf="grp == 'rollover'">Rollovers</h2>
      <h2 *ngIf="grp == 'select'">Products Considered</h2>
      <ul class="products col-12 mb-5">
        <ng-container *ngFor="let x of products">
          <li *ngIf="x.assessmenttype == grp" class="product col-12 {{x.ScoreClass}} {{x.expandedClass}}">
            <div class="col-12 row">
              <div class="col-10 content">
                <ng-container *hasPermission="'RelevanceBridgeIcon'">
                  <mat-icon *ngIf="x.ScoreClass == 'positive'">check_circle</mat-icon>
                  <mat-icon *ngIf="x.ScoreClass == 'neutral'">error</mat-icon>
                  <mat-icon *ngIf="x.ScoreClass == 'negative'">remove_circle</mat-icon>
                </ng-container>
                <h4>
                  {{x.ShortName}} <span *hasPermission="'!RelevanceHideBridgeScore'"> | {{x.score / 10}}</span>
                </h4>
                <div class="sub">{{x.subhead}}</div>
              </div>
              <div class="col-2 button">
                <button mat-button (click)="expand($event);">
                  <span class="collapsed-content">
                    <mat-icon>add_circle</mat-icon> Details
                  </span>
                  <span class="expanded-content">
                    <mat-icon>remove_circle</mat-icon> Close
                  </span>
                </button>
              </div>
            </div>
            <div class="plan-grid-table col-11" *ngIf="x.PlanGrid">
              <table class="plan-grid">
                <thead>
                  <tr>
                    <th *ngFor="let h of x.PlanGrid.Header;">{{h}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let t of x.PlanGrid.Data;">
                    <td *ngFor="let z of t">{{z}}</td>
                  </tr>
              </table>
            </div>

            <app-sub-item-chart *ngIf="x.SubItemChart" [data]="x.SubItemChart" [chartTitle]="'Brokerage Subtypes'" [chartStyle]="'minimal'"></app-sub-item-chart>
            <app-sub-item-chart *ngIf="x.BridgeDetailChart" [data]="x.BridgeDetailChart" [chartTitle]="x.BridgeDetailChart.Label" [chartStyle]="'minimal'"></app-sub-item-chart>

            <div class="details col-12">
              <!-- Need to Fix this left align conditional if we get more tabs than the grid -->
              <mat-tab-group mat-stretch-tabs [ngClass]="{'left-align': (!x.grids || x.grids.length <= 0) && (!x.bridgeCharts || x.bridgeCharts && !x.bridgeCharts.Bridges)}">
                <mat-tab label="Reasons" >
                  <app-sub-item-chart *ngIf="x.ComplianceChart" [data]="x.ComplianceChart" [chartTitle]="'Compliance Information'">
                  </app-sub-item-chart>
                  <app-reason-text class='col-6' [data]='x.reasons'></app-reason-text>
                </mat-tab>
                <mat-tab label="Product Summary" *ngIf="x.grids && x.grids.length > 0">
                  <div class="grid-table w-100" *ngFor="let gr of x.grids">
                    <div class="table-header w-100">{{gr.title}}</div>

                    <table [ngClass]="{'w33': gr.rows.length == 3, 'w50': gr.rows.length == 2, 'w100': gr.rows.length == 1}" *ngFor="let tb of gr.rows">
                      <tr *ngFor="let rw of tb.table">
                        <td [innerHTML]="rw.label"></td>
                        <td [innerHTML]="rw.value"></td>
                      </tr>
                    </table>
                  </div>
                </mat-tab>
                <mat-tab label="{{x.bridgeCharts.Label}}" *ngIf="x.bridgeCharts && x.bridgeCharts.Bridges">
                  <!-- {{x.bridgeCharts.Bridges.length}} -->
                  <!-- <pre>{{x.bridgeCharts.Bridges | json}}</pre> -->
                  <ng-container *ngFor="let chartGroup of x.bridgeCharts.Bridges">
                    <div *ngIf="chartGroup" class="chart-container clearfix mb-5">
                      <h4 class="chart-header">{{chartGroup.header}}</h4>
                      <ng-container *ngFor="let chrt of chartGroup.charts; let idx = index;">
                        <div class="chart pull-left w-50 px-4">
                          <!-- <pre>{{chrt | json}}</pre> -->
                          <highcharts-chart [Highcharts]="Highcharts" [options]="chrt.Data" class="w-90 mx-auto"></highcharts-chart>
                          <div *ngIf="chrt.Legend" class="chart-legend" [ngClass]="{'col-12': chrt.LegendPosition == 'bottom', 'col-6 ml-auto': chrt.LegendPosition == 'right'}">
                            <ng-container *ngIf="chrt.Legend.header; else noHeader">
                              <table>
                                <thead>
                                  <tr>
                                    <ng-container *ngFor="let th of chrt.Legend.header">
                                      <th *ngIf="th.toLowerCase() != 'color'">
                                        {{th}}
                                      </th>
                                    </ng-container>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let tr of chrt.Legend.data">
                                    <ng-container *ngFor="let td of tr | keyvalue : returnZero; let i = index">
                                      <td *ngIf="chrt.Legend.header[i].toLowerCase() != 'color'">
                                        <span
                                          *ngIf="chrt.Legend.header[i].toLowerCase() != 'color' && chrt.Legend.header[0].toLowerCase() === 'color' && i == 1"
                                          [style.background]="tr['Color']" class="indicator"></span>
                                        {{td.value}}
                                      </td>
                                    </ng-container>
                                  </tr>
                                </tbody>
                              </table>
                            </ng-container>
                            <ng-template #noHeader>
                              <div *ngFor="let c of chrt.Legend">
                                <strong>{{c.label}}</strong> {{c.value}}</div>
                            </ng-template>
                          </div>
                        </div>
                        <div class="explanation-text" [ngClass]="{'pull-left w-50': chartGroup.charts.length == 1, 'w-100': chartGroup.charts.length > 1}" *ngIf="chartGroup.charts.length == 1 || chartGroup.charts.length > 1 && idx == 1">
                          <p class="mx-auto mt-3" *ngIf="chartGroup.ShortDescription">{{chartGroup.ShortDescription}}</p>
                          <ul class="criteria-list mt-3" *ngIf="chartGroup.reasons">
                            <li *ngFor="let r of chartGroup.reasons" class="mt-2">
                              <span [innerHTML]="r.headline"></span>
                              <span *hasPermission="'!RelevanceHideReasonScores'"> | {{r.score / 10}}</span>
                            </li>
                          </ul>
                          <p class="mx-auto mt-3" *ngIf="chartGroup.dynamicContent">{{chartGroup.dynamicContent}}</p>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="rating-line {{x.ScoreClass}}" [style.width.%]="x.score/10"></div>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
  <div class="buttons row mt-3">
    <ng-container *ngIf="assessmentTypes.length > 0">
      <button mat-raised-button *hasPermission="'ELinRIP'" target="_blank" (click)="externalAction()"
        color="primary" class="external-link"
        [disabled]="validationError">
        <mat-icon>link</mat-icon>{{externalLink?.label}}
      </button>
    </ng-container>
    <ng-container *ngIf="assessmentTypes.length > 0">
      <button mat-raised-button *hasPermission="'ELinRIPDynamic'" (click)="externalAuditAction()" color="primary"
        class="external-link"
        [disabled]="validationError">
        <mat-icon>link</mat-icon>{{externalAudit?.label}}
      </button>
    </ng-container>
    <ng-container *ngIf="awButtonTooltip; else noTooltip;">
      <button mat-raised-button class="crossApp" *hasPermission="'AWinRIP'" (click)="goCrossApp('aw')"
      color="primary"
      matTooltip="{{awButtonTooltip}}"
      [disabled]="validationError"
      >
      <mat-icon>dvr</mat-icon>{{awButtonText}}
    </button>
    </ng-container>
    <ng-template #noTooltip>
      <button mat-raised-button class="crossApp" *hasPermission="'AWinRIP'" (click)="goCrossApp('aw')"
      color="primary"
      [disabled]="validationError">
      <mat-icon>dvr</mat-icon>{{awButtonText}}
      </button>
    </ng-template>
    <button mat-raised-button class="crossApp" *hasPermission="'IWinPP'" (click)="goCrossApp('iw')"
      color="primary"
      [disabled]="validationError">
      <mat-icon>style</mat-icon>{{iwButtonText}}
    </button>
    <button mat-raised-button class="crossApp" *hasPermission="'LIinPP'" (click)="goCrossApp('li')"
      color="primary"
      [disabled]="validationError">
      <mat-icon>loyalty</mat-icon>{{liButtonText}}
    </button>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
