<div class="page-container dashboard-container container unified">
  <app-utility-toolbar
    [current]="app"
    [vertical]="true"
    class="w-15 float-left pt-5 vertical"
  ></app-utility-toolbar>
  <div class="row w-85 pt-5">
    <div
      class="content row"
      [ngClass]="{
        'col-10 no-border': rights?.includes('SummaryWidgets'),
        'col-12 no-border': !rights?.includes('SummaryWidgets')
      }"
    >
      <div
        class="col-12 row toolbar pull-right"
        *hasPermission="'SummaryWidgets'"
      >
        <mat-form-field class="mb-4 pull-right">
          <mat-label>Solution</mat-label>
          <mat-select [(ngModel)]="solution">
            <mat-option *ngFor="let sltn of solutions" [value]="sltn.value">{{
              sltn.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="!rights?.includes('SummaryWidgets')">
        <legacy-app-links
          class="w-100"
          *hasPermission="'Launcher'"
        ></legacy-app-links>
      </ng-container>
      <app-summary-charts
        class="col-md-8"
        [solutionId]="solution"
        *hasPermission="'SummaryWidgets'"
      ></app-summary-charts>
      <app-cases-by-scoreclass
        class="col-md-4"
        [solutionId]="solution"
        *hasPermission="'SummaryWidgets'"
      ></app-cases-by-scoreclass>
      <app-systemUsageSummary
        class="col-12 mt-3"
        *hasPermission="'SummaryWidgets'"
      ></app-systemUsageSummary>
      <app-top5Summary
        class="col-12 mt-5"
        [solutionId]="solution"
        *hasPermission="'SummaryWidgets'"
      ></app-top5Summary>
      <!-- <app-caseStatsActivity class="col-12"></app-caseStatsActivity> -->
      <app-caseStats
        class="col-12"
        *hasPermission="'CaseStatsWidget'"
      ></app-caseStats>
      <app-fund-comparison
        class="col-12"
        *hasPermission="'FundCompareReport'"
      ></app-fund-comparison>
      <app-highActivityStats
        class="col-12"
        *hasPermission="'HighActivity'"
      ></app-highActivityStats>
      <app-unitProductStats
        class="col-12"
        *hasPermission="'UnitProductCaseStats'"
      ></app-unitProductStats>
      <app-lifeStats
        class="col-12"
        *hasPermission="'LWRecentActivityWidget,LWSummaryWidget'"
      ></app-lifeStats>
      <app-systemUsage
        class="col-sm-12"
        *hasPermission="'UsageWidget'"
      ></app-systemUsage>
      <app-top5
        class="col-sm-12 col-md-6"
        *hasPermission="'opportunitiesOfTheDayWidget'"
      ></app-top5>
      <app-topOpps
        class="col-sm-12 col-md-6 col-xl-4"
        *hasPermission="'TopOpportunitiesWidget'"
      ></app-topOpps>
      <app-timeSensitive
        class="col-sm-12 col-md-6"
        *hasPermission="'TimeSensitiveWidget'"
      ></app-timeSensitive>
      <app-followUp
        class="col-sm-12 col-md-6"
        *hasPermission="'FollowupWidget'"
      ></app-followUp>
      <app-scorecard
        class="col-sm-12 col-md-6 col-xl-4"
        *hasPermission="'ClientStatisticsWidget'"
      ></app-scorecard>
      <app-topBridges
        class="col-sm-12 col-md-6"
        *hasPermission="'TopBridgesWidget'"
      ></app-topBridges>
      <app-annuities
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-annuities>
      <app-fixed-annuities-active-rates
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-fixed-annuities-active-rates>
      <app-fia-rates
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-fia-rates>
      <app-fiaactiverates
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-fiaactiverates>
      <app-via-rates
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-via-rates>
      <app-via-active-rates
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-via-active-rates>
      <app-ppfa
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-ppfa>
      <app-activeppfa
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-activeppfa>
      <app-annuities-by-connector
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-annuities-by-connector>
      <app-living-benefits
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-living-benefits>
      <app-death-benefits
        class="col-sm-12 col-md-12 col-xl-12"
        *hasPermission="'AWDB-FixedRates'"
      ></app-death-benefits>
      <app-uploader
        class="col-sm-12 col-md-6 col-xl-4"
        *hasPermission="'Upload1040'"
      ></app-uploader>
      <div class="messages-container">
        <ng-container *hasPermission="'MessagesWidget1'">
          <app-messages
            [message]="msgContent[0]"
            class="col-sm-12 col-md-6 col-xl-4"
            *ngIf="msgContent && msgContent[0] && msgContent[0].title"
          >
          </app-messages>
        </ng-container>
        <ng-container *hasPermission="'MessagesWidget2'">
          <app-messages
            [message]="msgContent[1]"
            class="col-sm-12 col-md-6 col-xl-4"
            *ngIf="msgContent && msgContent[1] && msgContent[1].title"
          >
          </app-messages>
        </ng-container>
        <ng-container *hasPermission="'MessagesWidget3'">
          <app-messages
            [message]="msgContent[2]"
            class="col-sm-12 col-md-6 col-xl-4"
            *ngIf="msgContent && msgContent[2] && msgContent[2].title"
          >
          </app-messages>
        </ng-container>
        <ng-container *hasPermission="'MessagesWidget4'">
          <app-messages
            [message]="msgContent[3]"
            class="col-sm-12 col-md-6 col-xl-4"
            *ngIf="msgContent && msgContent[3] && msgContent[3].title"
          >
          </app-messages>
        </ng-container>
        <ng-container *hasPermission="'MessagesWidget5'">
          <app-messages
            [message]="msgContent[4]"
            class="col-sm-12 col-md-6 col-xl-4"
            *ngIf="msgContent && msgContent[4] && msgContent[4].title"
          >
          </app-messages>
        </ng-container>
      </div>
    </div>
  </div>
</div>
