import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private environment;
  private baseUrl: string;
  private firm: string;
  private app = 'li';
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private router: Router,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }

    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
  }

  getUnitProductCaseStats(unit: string, start, end, solution): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/widget/UnitProductCaseStats?unitID=${unit}&startDate=${start}&endDate=${end}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getUnitProfileCaseStats(unit: string, start, end, user?): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');

    let url = `${this.baseUrl}/widget/UnitProfileCaseStats?unitID=${unit}&startDate=${start}&endDate=${end}`;

    if (user) {
      url += `&userId=${user}`;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getProfileCaseStatsHistory(unit: string, start, end, user?): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/csv')
      .set('Accept', 'application/csv');

    let url = `${this.baseUrl}/widget/UnitProfileCaseStatsHistory?unitID=${unit}&startDate=${start}&endDate=${end}`;

    if (user) {
      url += `&userId=${user}`;
    }

    return this.http
      .get(url, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getDashboardAnnuities(type: string): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/csv');

    const url = `${this.baseUrl}/Annuities/DashboardAnnuities?type=${type}&ct=100`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getDashboardAnnuitiesCsv(
    type: string,
    startDate = '',
    endDate = '',
    paramString = ''
  ): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/csv')
      .set('Accept', 'application/csv');

    let url = '';
    if (startDate != '' && endDate != '') {
      url = `${this.baseUrl}/Annuities/DashboardAnnuities?type=${type}&sd=${startDate}&ed=${endDate}&ct=0`;
    } else {
      url = `${this.baseUrl}/Annuities/DashboardAnnuities?type=${type}&ct=0`;
    }

    if (paramString != '') {
      url = url + '&' + paramString;
    }

    return this.http
      .get(url, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        })
      );
  }

  getFixedAnnuitiesNoUpdatesCsv(
    startDate = '',
    endDate = '',
    numDays = ''
  ): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/csv')
      .set('Accept', 'application/csv');

    const url = `${this.baseUrl}/Annuities/FixedAnnuitiesWithNoUpdates?startDate=${startDate}&endDate=${endDate}&numDays=${numDays}`;

    return this.http
      .get(url, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        })
      );
  }

  getCaseStats(unit, solution, startDate, endDate, type?, level?) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    let url = `${this.rbs.baseUrl}/Widget/CaseSummary?unitID=${unit}&startDate=${startDate}&endDate=${endDate}`;

    if (type) {
      url += `&assessmentType=${type}`;
    }

    if (level) {
      url += `&level=${level}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  getCaseStatsTop(unit, solution, startDate, endDate) {
    const sessId = this.ss.get('session');
    let trueSolution;
    let includeAll = false;

    if (solution == 'ALL') {
      trueSolution = 'pp';
      includeAll = true;
    }

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', trueSolution)
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Widget/CaseStatsSummaryTop/${unit}?startDate=${startDate}&endDate=${endDate}&includeAllSolutions=${includeAll}`;

    return this.rbs.getRequest(url, reqHeaders);
  }

  getCaseSummaryStats(
    unit,
    solution,
    startDate,
    endDate,
    take,
    skip,
    getHeaders = false,
    user?,
    group?,
    allSolutions?,
    historical?,
    filterSolution?
  ) {
    const sessId = this.ss.get('session');
    const org = this.rbs.getOrg();
    const sltn = solution ? solution : 'rb';

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', sltn)
      .set('X-RB-O', org);
    let url = `${this.rbs.baseUrl}/Widget/CaseSummaryStats/${unit}?startDate=${startDate}&endDate=${endDate}&take=${take}&skip=${skip}&headers=${getHeaders}`;

    if (user) {
      url += `&userID=${user}`;
    }

    if (group) {
      url += `&flatOrGrouped=${group}`;
    }

    if (allSolutions) {
      url += `&includeAllSolutions=true`;
    }

    if (historical) {
      url += `&historyOption=${historical}`;
    }

    if (filterSolution) {
      url += `&filterSolution=${filterSolution}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  getCaseSummaryStatsHistory(
    unit,
    solution,
    startDate,
    endDate,
    user?,
    profile?,
    group?,
    expanded?
  ) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    let url = `${this.rbs.baseUrl}/Widget/CaseSummaryStatsHistory/${unit}?startDate=${startDate}&endDate=${endDate}`;

    if (user) {
      url += `&userID=${user}`;
    }

    if (profile) {
      url += `&profileID=${profile}`;
    }

    if (group) {
      url += `&flatOrGrouped=${group}`;
    }

    if (expanded) {
      url += `&elementsToInclude=EXPANDED`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  getUnitProfileCaseStatsHistory(
    unit,
    solution,
    startDate,
    endDate,
    user?,
    profile?
  ) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    let url = `${this.rbs.baseUrl}/Widget/UnitProfileCaseStatsHistory?unitID=${unit}&startDate=${startDate}&endDate=${endDate}`;

    if (user) {
      url += `&userId=${user}`;
    }

    if (profile) {
      url += `&profileID=${profile}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  getProfileHistory(solution, profile) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Profile/ChangeHistory/${profile}`;

    return this.rbs.getRequest(url, reqHeaders);
  }

  getProfileHistoryDetail(solution, profile, detail) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Profile/ChangeHistoryDetail/${profile}/${detail}`;

    return this.rbs.getRequest(url, reqHeaders);
  }

  unitSearch(search?): Observable<any> {
    let url = this.baseUrl + '/Hierarchy/Unit?searchString=';

    if (search) {
      url += search;
    } else {
      url += '%25';
    }

    return this.rbs.getRequest(url);
  }

  getUsageChart(unit, months) {
    const url = `${this.rbs.baseUrl}/Widget/SystemUsage?unitID=${unit}&numberOfMonths=${months}`;

    return this.rbs.getRequest(url);
  }

  getUsageData(unit, start, end, csv = false) {
    if (csv) {
      const solution = this.ss.get('currApp');
      const sessId = this.ss.get('session');
      const reqHeaders = new HttpHeaders()
        .set('X-RB-S', sessId)
        .set('X-RB-I', solution)
        .set('X-RB-O', this.org)
        .set('responseType', 'application/csv');
      const url = `${this.rbs.baseUrl}/Reports/UnitUsage?unitID=${unit}&startDate=${start}&endDate=${end}`;

      return this.rbs.postRequestForCsv(url, null, reqHeaders);
    } else {
      const url = `${this.rbs.baseUrl}/Reports/UnitUsage?unitID=${unit}&startDate=${start}&endDate=${end}`;

      return this.rbs.postRequest(url);
    }
  }

  getActivitySummary(unit: string, start, end): Observable<any> {
    const url = `${this.baseUrl}/widget/ActivitySummary?unitID=${unit}&startDate=${start}&endDate=${end}`;

    return this.rbs.getRequest(url);
  }

  getScoreclassSummary(unit: string, start, end): Observable<any> {
    const url = `${this.baseUrl}/widget/ScoreClassSummary?unitID=${unit}&startDate=${start}&endDate=${end}`;

    return this.rbs.getRequest(url);
  }

  getExplorerExcel(
    unit,
    solution,
    startDate,
    endDate,
    user?,
    allSolutions?,
    iteration?,
    profileID?
  ) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    let url = `${this.rbs.baseUrl}/widget/CaseSummaryStatsXlsx/${unit}?startDate=${startDate}&endDate=${endDate}`;

    if (user) {
      url += `&userID=${user}`;
    }

    if (allSolutions) {
      url += `&includeAllSolutions=true`;
    }

    if (solution) {
      url += `&filterSolution=${solution}`;
    }

    if (iteration) {
      url += `&historyOption=${iteration}`;
    }

    if (profileID) {
      url += `&profileID=${profileID}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  getFileStatus(name) {
    const url = `${this.rbs.baseUrl}/reports/FileExists?fileName=${name}`;
    return this.rbs.getRequest(url, null, 'rb');
  }

  getXlsxFile(name) {
    const sessId = this.ss.get('session');
    const url = `${this.rbs.baseUrl}/reports/File?fileName=${name}`;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/xlsx')
      .set('Accept', 'application/xlsx');
    console.log(name, url, reqHeaders);
    return this.http
      .get(url, { headers: reqHeaders, responseType: 'blob' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          // this.logout();
          return observableThrowError(error.error || 'Server error');
        })
      );
  }
}
