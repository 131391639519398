import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { ReportingService } from '../../reporting.service';
import { distinctUntilChanged, map, switchMap, startWith } from 'rxjs/operators';
import { Application, Unit } from '../../../models/admin-models';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-unit-usage',
  templateUrl: './unit-usage.component.html',
  styleUrls: ['./unit-usage.component.scss']
})
export class UnitUsageComponent implements OnInit {
  usageUnitsControl = new FormControl();
  usage = {unit: null, start: null, end: null};
  usageGridData = {data: null, header: null};
  filteredUnits;
  units: Unit[] = [] as Unit[];

  constructor(private admnSvc: AdminService, private rptSvc: ReportingService) { }

  ngOnInit(): void {
    this.getUsageUnits()
  }

  getUsageUnits() {
    this.filteredUnits = this.usageUnitsControl.valueChanges
      .pipe(
        startWith('%'),
        distinctUntilChanged(),
        switchMap(value => {
          if(value.length > 2) {
            return this._filterUnits(value);
          } return [];
        })
      );
  }

  getUsage() {
    this.rptSvc.getUsage(this.usage.unit, this.usage.start.toISOString(), this.usage.end.toISOString()).subscribe({
      next: (data) => {
        this.usageGridData = {...data};
      },
      error: (error) => {
        console.log(error)
      }
   });
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25'
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

}
