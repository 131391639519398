import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { Application, Unit } from '../../../models/admin-models';
import { ReportingService } from '../../reporting.service';
import { distinctUntilChanged, map, switchMap, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-bridge-action',
  templateUrl: './bridge-action.component.html',
  styleUrls: ['./bridge-action.component.scss']
})
export class BridgeActionComponent implements OnInit {
  actionUnitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  filteredUnits;
  selectedUnit;
  bridgeActionData = {data: null, headers: null};
  bridgeActionDetailData = {};
  action = {unit: null};

  constructor(private admnSvc: AdminService, private rptSvc: ReportingService) { }

  ngOnInit(): void {
    this.getActionUnits();
  }



  getBridgeAction() {
    this.rptSvc.getBridgeAction(this.action.unit).subscribe({
      next: (data) => {
        this.bridgeActionData = {...data.results};
      },
      error: (error) => {
        console.log(error)
      }
   });
  }

  getActionUnits() {
    this.filteredUnits = this.actionUnitsControl.valueChanges
      .pipe(
        startWith('%'),
        distinctUntilChanged(),
        switchMap(value => {
          console.log(value);
          if((value && value.length > 2) || value == '%') {
            return this._filterUnits(value);
          } return [];
        })
      );
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25'
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }

}
