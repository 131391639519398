import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RixtremaDialogComponent } from '../rixtrema-dialog/rixtrema-dialog.component';

@Component({
  selector: 'app-rixtrema-lookup',
  templateUrl: './rixtrema-lookup.component.html',
  styleUrls: ['./rixtrema-lookup.component.scss'],
})
export class RixtremaLookupComponent {
  @Input() fieldData;
  @Input() type;
  @Input() buttonType;

  constructor(private dialog: MatDialog) {}

  startSession() {
    this.openLookUpDialog({});
  }

  openLookUpDialog(data) {
    if (
      this.buttonType == 'rixtrema' ||
      this.buttonType == 'search401k' ||
      this.buttonType == 'search403b' ||
      this.buttonType == 'searchMP' ||
      this.buttonType == 'searchPension'
    ) {
      let planType = 'DC';

      if (this.buttonType === 'searchPension') {
        planType = 'DB';
      }

      data['groupIndex'] = this.fieldData.parent.form.controls.indexOf(
        this.fieldData.form
      );
      data['planType'] = planType;
    }

    data['planType'] = data['planType'] ? data['planType'] : 'DC';
    data['server'] = this.type == 1 ? 'rixtrema' : 'rightbridge';
    data['buttonType'] = this.buttonType;
    const dialogRef = this.dialog.open(RixtremaDialogComponent, {
      panelClass: 'rixtrema-dialog',
      data: data,
      minHeight: '60vh',
      width: '90vw',
    });
    dialogRef.afterClosed().subscribe(() => {
      // console.log('The dialog was closed', result);
    });
  }
}
