import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { LifeWizardService } from '../life-wizard.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { CompareDialogComponent } from '../../compare-dialog/compare-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentService } from '../../services/environment.service';
import { UnifiedFlowService } from '../../unified-flow/unified.service';

export interface FundData {
  permPercent: number;
  tempPercent: number;
  perm: Object;
  temp: Object;
  multiplier: number;
  amount: number;
  cashFlow: number;
  showEdit: boolean;
  cashFlowLabel: string;
}

@Component({
  selector: 'app-scenarios',
  templateUrl: './scenarios.component.html',
  styleUrls: ['./scenarios.component.scss'],
})
export class ScenariosComponent implements OnInit {
  @Output() navigate = new EventEmitter();

  private environment;

  data;

  products = { permLifeProducts: [], tempLifeProducts: [] };

  selectedPerm;
  selectedTemp;

  fundData: FundData = <FundData>{};
  prodMix;
  compareTables;
  compareChecked = {};
  compareList = [];
  tempEditPercent = { perm: null, temp: null };
  ratesLength = 0;

  breadcrumb;
  id;
  path: string;
  selectionModified = false;
  disclaimer;

  loading = false;

  showReasons = !this.ss.get('rights')?.includes('LIHideStrategyReasonText');
  showNotes = this.ss.get('rights')?.includes('BridgeNotes');
  calcNeed = false;

  constructor(
    private params: ActivatedRoute,
    private tes: TagsEvaluationService,
    private lws: LifeWizardService,
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    private dialog: MatDialog,
    private route: Router,
    private envSvc: EnvironmentService,
    private unfFlowSvc: UnifiedFlowService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.path = this.environment.assets ? this.environment.assets : '';

    this.setLoading(true);
    this.params.params.subscribe(params => {
      this.id = params.id;
      this.breadcrumb =
        params.validate && params.validate == 'true'
          ? 'validate-compare'
          : 'product-compare';
    });

    let globals = {};
    this.rbs.getGlobals(null, null, 'li').subscribe(x => {
      globals = x.results;
      this.compareTables = globals['tables']?.filter(
        x => x.id == 'compare1' || x.id == 'compare2'
      );
    });

    this.getData();
  }

  getData() {
    if (this.unfFlowSvc.getSaving()) {
      setTimeout(() => {
        this.getData();
      });
    } else {
      this.lws.getLifeScenarios(this.id).subscribe(data => {
        if (typeof data.result == 'string' && data.result.includes('Invalid')) {
          this.setLoading(false);
        } else {
          this.data = data;
          this.prodMix = this.data;
          const permCount = this.data.LifeProducts.filter(
            x => x.BridgeType == 'perm'
          ).length;
          const tempCount = this.data.LifeProducts.filter(
            x => x.BridgeType == 'term'
          ).length;
          if (permCount && !tempCount) {
            this.tempEditPercent.perm = 1;
            this.tempEditPercent.temp = 0;
            this.saveMixData();
          } else if (!permCount && tempCount) {
            this.tempEditPercent.perm = 0;
            this.tempEditPercent.temp = 1;
            this.saveMixData();
          } else if (
            this.prodMix?.vars['InsuranceMix^EditedPercentage']?.raw[0] === true
          ) {
            this.tempEditPercent.perm =
              this.prodMix?.vars['InsuranceMix^EditedPermPercent']?.raw[0];
            this.tempEditPercent.temp =
              this.prodMix?.vars['InsuranceMix^EditedTermPercent']?.raw[0];
          } else {
            this.tempEditPercent.perm =
              this.prodMix?.vars['InsuranceMix^PermPercent']?.raw[0];
            this.tempEditPercent.temp =
              this.prodMix?.vars['InsuranceMix^TermPercent']?.raw[0];
          }

          this.selectionModified =
            this.data.vars['LIScenario^SelectionModified'].raw[0];
          this.disclaimer =
            this.data.vars['UnitSettings^ScenarioDisclaimer'].display[0];
          this.sortProducts(data.LifeProducts);
          this.calcNeed = this.data.vars['IsCalcNeed^Value']
            ? this.data.vars['IsCalcNeed^Value'].raw[0]
            : this.calcNeed;
          this.setLoading(false);
        }
      });
    }
  }

  sortProducts(prods) {
    let tempCount = 0;
    let permCount = 0;
    const selectedPerm = prods.find(x => {
      return (
        x.vars['IsSelected^SelectedScenario'].raw[0] &&
        x.BridgeType.toLowerCase() == 'perm'
      );
    });
    const selectedTerm = prods.find(x => {
      return (
        x.vars['IsSelected^SelectedScenario'].raw[0] &&
        x.BridgeType.toLowerCase() == 'term'
      );
    });

    this.ratesLength = prods.find(x => {
      return x.BridgeType == 'perm';
    })
      ? prods.find(x => {
          return x.BridgeType == 'perm';
        }).vars.CurrentLifeRates.data.length
      : null;

    prods.forEach(el => {
      if (el.reasons) {
        el.reasons.forEach((reas, i) => {
          el.reasons[i].headline = reas.headline
            ? this.tes.evalTags(reas.headline)
            : reas.headline;
          el.reasons[i].explanation = reas.explanation
            ? this.tes.evalTags(reas.explanation)
            : reas.explanation;
        });
      }

      if (el.ComplianceChart) {
        el.ComplianceChart = JSON.parse(el.ComplianceChart);
      }

      if (el.BridgeType && el.BridgeType.toLowerCase() == 'perm') {
        if (permCount >= el.BridgeLimit) {
          el.hidden = true;
        }
        if (this.tempEditPercent.perm > 0) {
          if (permCount == 0 && !selectedPerm && !this.selectionModified) {
            this.selectProducts(null, el, false, false);
          } else if (!this.selectedPerm && selectedPerm) {
            this.selectProducts(null, selectedPerm, false, false);
          } else if (!selectedPerm && this.selectionModified) {
            this.selectProducts(null, { BridgeType: 'perm' }, true, false);
          }
        } else {
          this.selectProducts(null, { BridgeType: 'perm' }, true, false);
        }
        this.products.permLifeProducts.push(el);
        permCount++;
      } else if (el.BridgeType && el.BridgeType.toLowerCase() == 'term') {
        if (tempCount >= el.BridgeLimit) {
          el.hidden = true;
        }

        if (this.tempEditPercent.temp > 0) {
          if (tempCount == 0 && !selectedTerm && !this.selectionModified) {
            this.selectProducts(null, el, false, false);
          } else if (!this.selectedTemp && selectedTerm) {
            this.selectProducts(null, selectedTerm, false, false);
          } else if (!selectedPerm && this.selectionModified) {
            this.selectProducts(null, { BridgeType: 'term' }, true, false);
          }
        } else {
          this.selectProducts(null, { BridgeType: 'term' }, true, false);
        }
        this.products.tempLifeProducts.push(el);
        tempCount++;
      }

      //
      // TODO: Refactor how the selectproduct calls are handled above
      //
    });
  }

  selectProducts(ev, prod, clear = false, navigate = false) {
    if (prod.BridgeType.toLowerCase() === 'perm') {
      if (
        clear ||
        (this.selectedPerm &&
          this.selectedPerm.vars['CurrentLifeItem^LifeItemID'].raw[0] ===
            prod.vars['CurrentLifeItem^LifeItemID'].raw[0])
      ) {
        if (this.selectedPerm) {
          this.compareChecked[
            this.selectedPerm.vars['CurrentLifeItem^LifeItemID'].raw[0]
          ] = false;
        }
        this.fundData.permPercent = 0;
        this.fundData.tempPercent = 1;
        this.selectedPerm = { vars: [] };
        this.fundData.perm = this.fundData.perm
          ? this.fundData.perm
          : { data: [] };
        this.fundData.perm['data'] = Array.from(
          { length: this.ratesLength },
          () => ({ Premium: 0, DeathBenefit: 0 })
        );
        this.selectedPerm = null;
      } else {
        if (this.selectedPerm) {
          this.compareChecked[
            this.selectedPerm.vars['CurrentLifeItem^LifeItemID'].raw[0]
          ] = false;
        }

        this.fundData.permPercent = this.tempEditPercent.perm;
        this.fundData.tempPercent = this.tempEditPercent.temp;
        this.selectedPerm = prod;
        this.fundData.perm = this.fundData.perm
          ? this.fundData.perm
          : { data: [] };
        this.fundData.perm['data'] = prod.vars.CurrentLifeRates.data.map(a => ({
          ...a,
        }));
        this.compareChecked[
          this.selectedPerm.vars['CurrentLifeItem^LifeItemID'].raw[0]
        ] = true;
      }
    } else if (prod.BridgeType.toLowerCase() === 'term') {
      if (
        clear ||
        (this.selectedTemp &&
          this.selectedTemp.vars['CurrentLifeItem^LifeItemID'].raw[0] ===
            prod.vars['CurrentLifeItem^LifeItemID'].raw[0])
      ) {
        if (this.selectedTemp) {
          this.compareChecked[
            this.selectedTemp.vars['CurrentLifeItem^LifeItemID'].raw[0]
          ] = false;
        }
        this.fundData.tempPercent = 0;
        this.fundData.permPercent = 1;
        this.selectedTemp = { vars: [] };
        this.fundData.temp = this.fundData.temp
          ? this.fundData.temp
          : { data: [] };
        this.fundData.temp['data'] = Array.from(
          { length: this.ratesLength },
          () => ({ Premium: 0, DeathBenefit: 0 })
        );
        this.selectedTemp = null;
      } else {
        if (this.selectedTemp) {
          this.compareChecked[
            this.selectedTemp.vars['CurrentLifeItem^LifeItemID'].raw[0]
          ] = false;
        }
        this.fundData.permPercent = this.tempEditPercent.perm;
        this.fundData.tempPercent = this.tempEditPercent.temp;
        this.selectedTemp = prod;
        this.fundData.temp = this.fundData.temp
          ? this.fundData.temp
          : { data: [] };
        this.fundData.temp['data'] = prod.vars.CurrentLifeRates.data.map(a => ({
          ...a,
        }));
        this.compareChecked[
          this.selectedTemp.vars['CurrentLifeItem^LifeItemID'].raw[0]
        ] = true;
      }
    }

    this.fundData.amount =
      this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0];
    this.fundData.multiplier =
      this.data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0] /
      1000000;
    this.fundData.cashFlow = this.data.vars['ClientFinancial^CashFlow'];
    this.fundData.showEdit =
      this.data.vars['UnitQuestions^CashFlowOnStrategies'].raw[0];
    this.fundData.cashFlowLabel =
      this.data.vars['UnitQuestions^CashFlowLabel'].display[0] ||
      'Monthly income available for premium?';
    this.fundData = Object.assign({}, this.fundData);

    setTimeout(() => {
      this.saveSelectedScenarios(navigate);
    }, 250);

    //
    // Refactor where this is called and kill the timeout
    //
    if (ev) {
      setTimeout(() => {
        this.saveMixData();
      }, 2000);
    }
  }

  setCompare() {
    if (
      this.prodMix.vars['InsuranceMix^EditedPermPercent'].raw[0] !=
        this.prodMix.vars['InsuranceMix^PermPercent'].raw[0] &&
      this.prodMix.vars['InsuranceMix^EditedTermPercent'].raw[0] !=
        this.prodMix.vars['InsuranceMix^TermPercent'].raw[0] &&
      this.prodMix.vars['InsuranceMix^EditedTermPercent']
    ) {
      this.saveMixData();
    }
  }

  compareToggle(e, prod, remove = false, disabled = false) {
    if (!disabled) {
      const inList = this.compareList.filter(x => {
        return x == prod.LifeItemID;
      });
      if (inList && inList.length) {
        this.compareList = this.compareList.filter(x => {
          return x != prod.LifeItemID;
        });
        if (!e) {
          this.compareChecked[prod.LifeItemID] = false;
        } else {
          this.rbs
            .saveProfile(
              this.id,
              {
                'LifeWizardCompare.CompareID': this.compareList,
              },
              true
            )
            .subscribe();
        }
      } else if (remove) {
        this.compareChecked[prod.LifeItemID] = false;
      } else {
        this.compareList.push(prod.LifeItemID);
        if (!e) {
          this.compareChecked[prod.LifeItemID] = true;
        } else {
          this.rbs
            .saveProfile(
              this.id,
              {
                'LifeWizardCompare.CompareID': this.compareList,
              },
              true
            )
            .subscribe();
        }
      }
    }
  }

  compare() {
    this.setCompare();
    const compareList = [];
    let combined = [];
    Object.keys(this.products).forEach(el => {
      combined = combined.concat(this.products[el]);
    });
    Object.keys(this.compareChecked).forEach(el => {
      if (this.compareChecked[el] && el != 'undefined') {
        const prod = combined.find(z => {
          return z.vars['CurrentLifeItem^LifeItemID'].raw[0] == el;
        });
        compareList.push(prod);
      }
    });
    const compareData = {
      tables: this.compareTables,
      funds: compareList,
      header: 'Compare Strategies',
    };

    const dialogRef = this.dialog.open(CompareDialogComponent, {
      panelClass: 'compare-dialog',
      height: '95vh',
      width: '95vw',
      maxHeight: '95vh',
      maxWidth: '95vw',
      data: compareData,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe();
  }

  canCompare() {
    const numTrue = Object.keys(this.compareChecked).filter(
      x => this.compareChecked[x] === true
    );
    return !(numTrue.length > 1);
  }

  saveMixData() {
    const saveData = {
      'InsuranceMix.EditedPermPercent': this.tempEditPercent.perm,
      'InsuranceMix.EditedTermPercent': this.tempEditPercent.temp,
      'InsuranceMix.EditedPercentage': true,
    };
    this.rbs.saveProfile(this.id, saveData).subscribe();
  }

  saveSelectedScenarios(navigate = false) {
    let lifeItem = [],
      prodCode = [],
      carrier = [],
      unique = [],
      type = [];

    if (this.selectedPerm && !this.selectedTemp) {
      lifeItem = [this.selectedPerm.vars['CurrentLifeItem^LifeItemID'].raw[0]];
      prodCode = [this.selectedPerm.vars['CurrentLifeItem^ProductCode'].raw[0]];
      carrier = [this.selectedPerm.vars['CurrentLifeItem^CarrierCode'].raw[0]];
      unique = [
        this.selectedPerm.vars['CurrentLifeProducts^UniqueCode'].raw[0],
      ];
      type = [this.selectedPerm.BridgeType];
    } else if (!this.selectedPerm && this.selectedTemp) {
      lifeItem = [this.selectedTemp.vars['CurrentLifeItem^LifeItemID'].raw[0]];
      prodCode = [this.selectedTemp.vars['CurrentLifeItem^ProductCode'].raw[0]];
      carrier = [this.selectedTemp.vars['CurrentLifeItem^CarrierCode'].raw[0]];
      unique = [
        this.selectedTemp.vars['CurrentLifeProducts^UniqueCode'].raw[0],
      ];
      type = [this.selectedTemp.BridgeType];
    } else if (this.selectedPerm && this.selectedTemp) {
      lifeItem = [
        this.selectedPerm.vars['CurrentLifeItem^LifeItemID'].raw[0],
        this.selectedTemp.vars['CurrentLifeItem^LifeItemID'].raw[0],
      ];
      prodCode = [
        this.selectedPerm.vars['CurrentLifeItem^ProductCode'].raw[0],
        this.selectedTemp.vars['CurrentLifeItem^ProductCode'].raw[0],
      ];
      carrier = [
        this.selectedPerm.vars['CurrentLifeItem^CarrierCode'].raw[0],
        this.selectedTemp.vars['CurrentLifeItem^CarrierCode'].raw[0],
      ];
      unique = [
        this.selectedPerm.vars['CurrentLifeProducts^UniqueCode'].raw[0],
        this.selectedTemp.vars['CurrentLifeProducts^UniqueCode'].raw[0],
      ];
      type = [this.selectedPerm.BridgeType, this.selectedTemp.BridgeType];
    }

    const data = {
      'LISelectedScenario.LifeItemID': lifeItem,
      'LISelectedScenario.ProductCode': prodCode,
      'LISelectedScenario.CarrierCode': carrier,
      'LISelectedScenario.UniqueCode': unique,
      'LISelectedScenario.BridgeType': type,
      'LIScenario.SelectionModified': true,
    };

    if (
      (this.fundData.tempPercent == 1 && this.fundData.permPercent == 0) ||
      (this.fundData.tempPercent == 0 && this.fundData.permPercent == 1)
    ) {
      data['InsuranceMix.EditedPermPercent'] = this.fundData.permPercent;
      data['InsuranceMix.EditedTermPercent'] = this.fundData.tempPercent;
      data['InsuranceMix.EditedPercentage'] = true;
    }

    this.rbs.saveProfile(this.id, data, false).subscribe(() => {
      if (navigate) {
        this.navigate.emit('products');
      }
    });
  }

  handleMixChange(ev) {
    this.tempEditPercent.perm = this.fundData.permPercent = ev.perm;
    this.tempEditPercent.temp = this.fundData.tempPercent = ev.term;
    this.prodMix.vars['InsuranceMix^EditedPercentage'].raw[0] = true;
    this.saveMixData();
  }

  handleCashFlowChange(ev) {
    const saveData = {
      'ClientFinancial.CashFlow': ev,
    };
    this.rbs.saveProfile(this.id, saveData).subscribe();
  }

  returnToNeeds() {
    this.route.navigate(['/li/results/' + this.id]);
  }

  viewProducts() {
    this.saveSelectedScenarios(true);
  }

  scoreFormat(scr) {
    return scr > 100 ? 100 : scr;
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
    this.loading = state;
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'product');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }
}
