import { Injectable } from '@angular/core';
import { PercentPipe, DecimalPipe, CurrencyPipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CustomFiltersService {
  constructor(
    private percPipe: PercentPipe,
    private decPipe: DecimalPipe,
    private currPipe: CurrencyPipe
  ) {}

  buildColumnFilterOptions(gridData, headers) {
    const output = {};
    const keys = gridData && gridData[0] ? Object.keys(gridData[0]) : [];

    keys.forEach(key => {
      let col = gridData.map(row => row[key]);
      col = [...new Set(col)];
      col = col.sort((a, b) => (a < b ? -1 : 1));
      col = col.map(x => {
        if (x || !x && typeof x === 'boolean') {
        let displayValue;
        const header = headers.find(z => z.DataField === key);
        if (header && header.Type) {
          switch (header.Type) {
            case 'perc':
              if (x == -9.99 || x == -999) {
                displayValue = 'N/A';
                x = 'N/A';
              } else if (x == 'N/A' || x == 'Uncapped') {
                displayValue = x;
              } else {
                displayValue = this.percPipe.transform(x);
              }
              break;

            case 'perc2':
              if (x == -9.99 || x == -999) {
                displayValue = 'N/A';
                x = 'N/A';
              } else if (x == 'N/A' || x == 'Uncapped') {
                displayValue = x;
              } else {
                displayValue = this.percPipe.transform(x, '1.2');
              }
              break;

            case 'perc3':
              if (x == -9.99 || x == -999) {
                displayValue = 'N/A';
                x = 'N/A';
              } else if (x == 'N/A' || x == 'Uncapped') {
                displayValue = x;
              } else {
                displayValue = this.percPipe.transform(x, '1.3');
              }
              break;

            case 'integer':
              displayValue = this.decPipe.transform(x, '1.0-0');
              break;

            case 'curr':
              displayValue = this.currPipe.transform(x);
              break;

            case 'rateLock':
              if (x.toLowerCase() == 'f') {
                displayValue = 'Full Term';
              } else if (x.toLowerCase() == 'a') {
                displayValue = 'Annual';
              } else if (x.toLowerCase() == 's') {
                displayValue = 'Semi-annual';
              } else if (x.toLowerCase() == 'q') {
                displayValue = 'Quarterly';
              } else if (x.toLowerCase() == 'm') {
                displayValue = 'Monthly';
              } else if (x.toLowerCase() == 'd') {
                displayValue = 'Daily';
              }
              break;

            case 'bool':
              if (x) {
                displayValue = 'Yes';
              } else if (!x && typeof x == 'boolean') {
                displayValue = 'No';
              }
              break;

            default:
              displayValue = x;
              break;
          }
        } else {
          displayValue = x;
        }

        return { display: displayValue, value: x };
      }
      });
// console.log(col)
      col = col.filter(x => {
        if (x && x.value || x && !x.value && typeof x.value === 'boolean') { return true}
      });

      output[key] = col;
    });
    // console.log(output)
    return output;
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }
}
