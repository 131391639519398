import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-report-modal',
  templateUrl: './custom-report-modal.component.html',
  styleUrls: ['./custom-report-modal.component.scss'],
})
export class CustomReportModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomReportModalComponent>
  ) {}

  ngOnInit(): void {}

  closeModal(ev) {
    // if(this.data.silentPrint) {
    // console.log(ev);
    // } else {
    this.dialogRef.close(ev);
    // }
  }
}
