import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdminService } from '../admin.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'app-unit-profile-history-dialog',
  templateUrl: './unit-profile-history-dialog.component.html',
  styleUrls: ['./unit-profile-history-dialog.component.scss']
})
export class UnitProfileHistoryDialogComponent implements OnInit {
  historyData = [];

  constructor(
    private dialogRef: MatDialogRef<UnitProfileHistoryDialogComponent>,
    private admnSrvc: AdminService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.loadHistory();
  }
  
  loadHistory() {
    this.admnSrvc.getUnitProfileHistory(this.data.unit, this.data.solution).subscribe(x => {
      this.historyData = x.results;
      this.historyData.forEach(el => {
        var date = new Date(el.ChangeDate + ' UTC');
        el.ChangeDate = date.toLocaleString()
      });
    })
  }

  download(ev, id, type) {
    this.admnSrvc.downloadHistoricUnitProfile(id, this.data.solution, type).subscribe(data => {
      let fileData;
      let filename = `${this.data.solution}-unitProfile-${id}.${type}`
      const reader = new FileReader();
      reader.readAsDataURL(data);

      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename);
      };
    });
  }

  rollback(ev, id) {
    let dialogRef = this.dialog.open(WarnDialogComponent, { panelClass: 'warn-dialog', data: { 'headline': 'Warning', 'content': 'Are you sure you want to rollback to this unit profile?', 'confirm': 'Yes', 'hideCancel': false } });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result, id);
      if (result == 'continue') {
        this.admnSrvc.rollbackUnitProfile(id, this.data.solution).subscribe(x => {this.loadHistory()});
      }
      dialogRef = null;
    });
  }

  close() {
    this.dialogRef.close();
  }

}
